import React from 'react';
import { DownOutlined, LoadingOutlined, UpOutlined } from '@ant-design/icons';
import {
  Select,
  Spin,
  Badge,
  Tooltip,
  Progress,
  Dropdown,
  Radio,
  Empty,
  Button,
} from 'antd';
import { isNil, find as lodashFind, isEmpty, filter } from 'lodash';
import {
  numberWithCommas,
  percentage,
  renderTag,
} from '../../../../utils/RenderUtils';
import {
  getCommunicationId,
  getChannel,
} from '../../../../utils/AuthorityProvider';
import moment from 'moment';
import { Line } from '@ant-design/charts';
import CommunicationTypes from '../model/CommunicationTypes';
import TagSelector from '../../../../component/form/TagSelector';
import Card from '@Utils/Card';

const styles = {
  TagBorder: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '14px',
    color: 'rgba(61,64,78,.85)',
  },
  summaryText: {
    color: 'rgba(61,64,78,.55)',
    fontWeight: '600',
    fontSize: 12,
    lineHeight: '20px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  summaryCard: {
    backgroundColor: 'white',
    padding: '20px',
    marginBottom: '30px',
    border: '1px solid #F1F1F3',
    height: 112,
    display: 'flex',
    justifyContent: 'center',
  },
  summaryNumber: {
    fontSize: '18.2px',
    fontWeight: '500',
    lineHeight: '30px',
    textAlign: 'left',
  },
  summaryCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableTitle: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '13px 20px',
    fontSize: '11px',
    fontWeight: '600',
    color: '#94969e',
  },
  tableData: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '13px 20px',
    fontSize: '14px',
    color: 'rgba(61,64,78,.85)',
  },
  headerInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    direction: 'ltr',
    justifyContent: 'space-between',
  },
  headerInfoPlaceholder: {
    display: 'flex',
    flexDirection: 'row',
    direction: 'ltr',
    flex: 2,
  },
  headerInfo: {
    display: 'flex',
    flexDirection: 'row',
    direction: 'ltr',
    flex: 3,
    textTransform: 'uppercase',
    color: 'rgba(61,64,78,.55)',
    fontSize: 12,
    letterSpacing: '.5px',
    fontWeight: '600',
    textAlign: 'left',
  },
  headerOverall: {
    display: 'flex',
    flexDirection: 'row',
    direction: 'ltr',
    fontWeight: '700',
    lineHeight: '1.43',
    color: '#3d404e',
    fontSize: 13,
    textAlign: 'left',
  },
  headerData: {
    display: 'flex',
    flexDirection: 'row',
    direction: 'ltr',
    lineHeight: '1.43',
    color: '#3d404e',
    fontSize: 13,
    textAlign: 'left',
  },
  showMoreButton: {
    direction: 'ltr',
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: '#1890ff',
    border: '1px solid #1890ff',
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    position: 'relative',
    fontSize: 11,
    fontWeight: '400',
    padding: '0 20px',
  },
};

class CommunicationOverviewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReport: 'uniqueConversions',
      showPercentage: true,
      showMore: false,
      showMoreButtonText: 'Show More',
    };
  }

  componentDidMount() {
    this.communicationId = getCommunicationId(this.props.pathname);
    this.channel = getChannel(this.props.pathname);
    this.props.communicationOverviewFetch({
      channel: this.channel,
      fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    });
    this.props.communicationSplitOverviewFetch({
      splitBy: 'CHANNEL',
      channel: this.channel,
      fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      tags: null,
      types: null,
    });
    this.props.communicationListTagsFetch();
    this.props.revenueMappingUpdateFetch();
    this.isEmail =
      this.props?.match?.params?.type?.toLocaleLowerCase() === 'emails';
  }

  handleApplyTags = (tags) => {
    document.getElementById('tag-selector').click();
    if ((Array.isArray(tags) && tags.length === 0) || isNil(tags)) {
      this.props.communicationSplitOverviewFetch({
        ...this.props.pageRequestSplitOverview,
        tags: null,
      });
    } else {
      this.props.communicationSplitOverviewFetch({
        ...this.props.pageRequestSplitOverview,
        tags,
      });
    }
  };
  renderNumber = (number) => {
    if (isNil(number)) {
      return 0;
    }
    return numberWithCommas(number);
  };

  renderChart = (config) => {
    return (
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Line
          {...config}
          style={{ flex: 1, padding: 20, width: window.innerWidth - 300 }}
        />
      </div>
    );
  };

  renderReport = () => {
    const data = [];
    if (this.props.splitOverview && Array.isArray(this.props.splitOverview)) {
      this.props.splitOverview.forEach((item) => {
        const date = moment(item.date, 'YYYY-MM-DD').format('DD MMM');
        let category = this.channel;
        if (this.props.pageRequestSplitOverview.splitBy === 'TAGS') {
          category = item.tags;
        } else if (this.props.pageRequestSplitOverview.splitBy === 'TYPE') {
          category = CommunicationTypes[item.type];
        }
        data.push({
          date,
          value: item[this.state.selectedReport],
          category,
        });
      });
    }
    const config = {
      data,
      height: 400,
      xField: 'date',
      yField: 'value',
      padding: 'auto',
      seriesField: 'category',
      color: ['#fc587d', '#008BFA', '#FFC009', '#58FEA1', '#02eb7f', '#773a80'],
      lineStyle: function lineStyle() {
        return {
          lineDash: [4, 4],
          opacity: 1,
        };
      },
      yAxis: {
        label: {
          formatter: function formatter(v) {
            return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
              return ''.concat(s, ',');
            });
          },
        },
      },
      point: {
        size: 5,
        shape: 'circle',
      },
      legend: {
        position: 'right',
        verticalAlign: 'middle',
      },
      meta: {
        value: {
          formatter: (v) => {
            return this.renderNumber(v);
          },
        },
      },
    };
    return (
      <div>
        <div
          style={{
            padding: '20px',
            flex: 1,
            borderBottom: '1px solid #e7e8e9',
          }}
        >
          <Radio.Group
            onChange={(e) => this.setState({ selectedReport: e.target.value })}
            value={this.state.selectedReport}
          >
            <Radio value={'delivered'}>Delivered</Radio>
            <Radio value={'uniqueClicks'}>Unique Clicks</Radio>
            <Radio value={'uniqueConversions'}>Unique Conversions</Radio>
            <Radio value={'revenue'}>
              Revenue
              {this.props.currentRevenueMapping &&
              isNil(this.props.currentRevenueMapping.revenueCurrency)
                ? ''
                : ' (' + this.props.currentRevenueMapping.revenueCurrency + ')'}
            </Radio>
            <Radio value={'convertedAfterClick'}>OnClick Conversions</Radio>
          </Radio.Group>
        </div>
        {this.renderChart(config)}
      </div>
    );
  };

  renderSummaryNumber = (selector, formatNumber = true) => {
    if (
      this.props.overview &&
      this.props.overview.total &&
      Array.isArray(this.props.overview.total)
    ) {
      const foundItem = lodashFind(
        this.props.overview.total,
        (o) => o.channel === this.channel
      );
      if (foundItem) {
        if (formatNumber) {
          return numberWithCommas(foundItem[selector]);
        } else {
          return foundItem[selector];
        }
      }
    }
    return '-';
  };

  renderConversionSummaryPercentage = () => {
    const delivered = this.getOverall('uniqueDelivered');
    const converted = this.renderSummaryNumber('conversions', false);
    return percentage(converted, delivered);
  };
  renderOnclickConversionsPercentage = () => {
    const totalClicked = this.getOverall('totalClicked');
    const onclickConversions = this.renderSummaryNumber(
      'convertedAfterClick',
      false
    );
    return percentage(onclickConversions, totalClicked);
  };

  renderSummary = () => {
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 30 }} spin />;
    return (
      <div style={{ background: 'none', padding: 0 }}>
        <div style={styles.summaryCardContainer}>
          <div style={{ flex: 1 }}>
            <div style={styles.summaryCard}>
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {false && (
                    <div
                      style={{
                        overflow: 'hidden',
                        backgroundColor: '#fe5196',
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        marginRight: 5,
                      }}
                    ></div>
                  )}
                  <div style={styles.summaryText}>COMMUNICATIONS</div>
                </div>
                {this.props.loadingOverview ? (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingTop: 20,
                    }}
                  >
                    <Spin indicator={inputLoadingSpin} />
                  </div>
                ) : (
                  <React.Fragment>
                    <div style={styles.summaryNumber}>
                      {this.renderSummaryNumber('communications')}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <div style={{ flex: 1, marginLeft: 15 }}>
            <div style={styles.summaryCard}>
              <div style={{ height: 60 }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {false && (
                    <div
                      style={{
                        overflow: 'hidden',
                        backgroundColor: '#58fea1',
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        marginRight: 5,
                      }}
                    ></div>
                  )}
                  <div style={styles.summaryText}>USERS ENGAGED</div>
                </div>
                {this.props.loadingOverview ? (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingTop: 20,
                    }}
                  >
                    <Spin indicator={inputLoadingSpin} />
                  </div>
                ) : (
                  <React.Fragment>
                    <div style={styles.summaryNumber}>
                      {this.renderSummaryNumber('usersEngaged')}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <div style={{ flex: 1, marginLeft: 15 }}>
            <div style={styles.summaryCard}>
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {false && (
                    <div
                      style={{
                        overflow: 'hidden',
                        backgroundColor: '#09d88c',
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        marginRight: 5,
                      }}
                    ></div>
                  )}
                  <div style={styles.summaryText}>UNIQUE CONVERSIONS</div>
                </div>
                {this.props.loadingOverview ? (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingTop: 20,
                    }}
                  >
                    <Spin indicator={inputLoadingSpin} />
                  </div>
                ) : (
                  <React.Fragment>
                    <React.Fragment>
                      <div style={styles.summaryNumber}>
                        {this.renderConversionSummaryPercentage() + '%'}
                      </div>
                      <div style={{ fontSize: 13 }}>
                        {this.renderSummaryNumber('conversions')}
                      </div>
                    </React.Fragment>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <div style={{ flex: 1, marginLeft: 15 }}>
            <div style={styles.summaryCard}>
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {false && (
                    <div
                      style={{
                        overflow: 'hidden',
                        backgroundColor: '#00b875',
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        marginRight: 5,
                      }}
                    ></div>
                  )}
                  <div style={styles.summaryText}>
                    REVENUE
                    {this.props.currentRevenueMapping &&
                    isNil(this.props.currentRevenueMapping.revenueCurrency)
                      ? ''
                      : ' (' +
                        this.props.currentRevenueMapping.revenueCurrency +
                        ')'}
                  </div>
                </div>
                {this.props.loadingOverview ? (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingTop: 20,
                    }}
                  >
                    <Spin indicator={inputLoadingSpin} />
                  </div>
                ) : (
                  <React.Fragment>
                    <div style={styles.summaryNumber}>
                      {this.renderSummaryNumber('revenue')}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <div style={{ flex: 1, marginLeft: 15 }}>
            <div style={styles.summaryCard}>
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {false && (
                    <div
                      style={{
                        overflow: 'hidden',
                        backgroundColor: '#09d88c',
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        marginRight: 5,
                      }}
                    ></div>
                  )}
                  <div
                    style={{
                      ...styles.summaryText,
                      letterSpacing: '0.1px',
                      fontSize: 11,
                    }}
                  >
                    ON CLICK CONVERSIONS
                  </div>
                </div>
                {this.props.loadingOverview ? (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingTop: 20,
                    }}
                  >
                    <Spin indicator={inputLoadingSpin} />
                  </div>
                ) : (
                  <React.Fragment>
                    <div style={styles.summaryNumber}>
                      {this.renderOnclickConversionsPercentage() + '%'}
                    </div>
                    <div style={{ fontSize: 13 }}>
                      {this.renderSummaryNumber('convertedAfterClick')}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderTagItem = (tag) => {
    if (tag) {
      return renderTag(tag, this.props.listTags);
    }
    return <span>(No Tag)</span>;
  };

  getOverall = (selector) => {
    if (
      this.props.overview &&
      this.props.overview.total &&
      Array.isArray(this.props.overview.total)
    ) {
      const foundItem = lodashFind(
        this.props.overview.total,
        (o) => o.channel === this.channel
      );
      if (foundItem) {
        return foundItem[selector];
      }
    }
    return 0;
  };

  renderBarChart = (item, selector) => {
    const colors = {
      delivered: 'rgb(0, 139, 250)',
      communications: 'rgb(253, 87, 141)',
      conversions: 'rgb(0, 235, 127)',
      revenue: 'rgb(0, 184, 117)',
    };
    let percentage = 0;
    if (item && item[selector]) {
      const overall = this.getOverall(selector);
      if (overall) {
        percentage = ((item[selector] * 100) / overall).toFixed(0);
      }
    }
    return (
      <div style={{ width: 180 }}>
        <Progress
          type="line"
          strokeLinecap="square"
          strokeWidth="10px"
          percent={percentage}
          size="small"
          status="active"
          strokeColor={colors[selector]}
        />
      </div>
    );
  };
  renderTagBarChart = (item, selector) => {
    return <div>{numberWithCommas(item[selector])}</div>;
  };
  renderTagsInfo = () => {
    const displayFirstItems = 5;
    return (
      <Card
        styles={{
          paddingBottom: 20,
          marginBottom: 30,
        }}
        loading={this.props.loadingOverview}
      >
        {this.state.showPercentage ? (
          <React.Fragment>
            <div
              style={{
                padding: 10,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Radio.Group
                value={this.state.showPercentage}
                onChange={(e) =>
                  this.setState({ showPercentage: e.target.value })
                }
              >
                <Radio.Button value={true}>%</Radio.Button>
                <Radio.Button value={false}>#</Radio.Button>
              </Radio.Group>
            </div>
            <div style={{ paddingLeft: 20 }}>
              <div style={styles.headerInfoContainer}>
                <div style={styles.headerInfoPlaceholder}></div>
                <div style={styles.headerInfo}>
                  Delivered
                  <span style={{ paddingLeft: 10 }}></span>
                </div>
                <div style={styles.headerInfo}>
                  Communications
                  <span style={{ paddingLeft: 10 }}>
                    <Tooltip
                      placement={'bottom'}
                      title={
                        'Total campaigns sent within the selected time frame for the chosen communication type.'
                      }
                    >
                      <i
                        className="fl-info"
                        style={{
                          color: '#bbbcc1',
                          fontSize: 24,
                          fontWeight: 400,
                        }}
                      ></i>
                    </Tooltip>
                  </span>
                </div>
                <div style={styles.headerInfo}>
                  Conversions
                  <span style={{ paddingLeft: 10 }}>
                    <Tooltip
                      placement={'bottom'}
                      title={
                        "Unique users who performed conversion within the communication's conversion deadline and the selected time frame for the chosen communications type."
                      }
                    >
                      <i
                        className="fl-info"
                        style={{
                          color: '#bbbcc1',
                          fontSize: 24,
                          fontWeight: 400,
                        }}
                      ></i>
                    </Tooltip>
                  </span>
                </div>
                <div style={styles.headerInfo}>
                  Revenue
                  {this.props.currentRevenueMapping &&
                  isNil(this.props.currentRevenueMapping.revenueCurrency)
                    ? ''
                    : ' (' +
                      this.props.currentRevenueMapping.revenueCurrency +
                      ')'}
                  <span style={{ paddingLeft: 10 }}>
                    <Tooltip
                      placement={'bottom'}
                      title={
                        'Total revenue generated through all the conversions that occurred within the selected time frame for the selected communication type.'
                      }
                    >
                      <i
                        className="fl-info"
                        style={{
                          color: '#bbbcc1',
                          fontSize: 24,
                          fontWeight: 400,
                        }}
                      ></i>
                    </Tooltip>
                  </span>
                </div>
                <div style={styles.headerInfo}>
                  On Click Conversions
                  <span style={{ paddingLeft: 10 }}></span>
                </div>
              </div>
              <div style={{ ...styles.headerInfoContainer, margin: '20px 0' }}>
                <div style={{ ...styles.headerOverall, flex: 2 }}>Overall</div>
                <div style={{ ...styles.headerOverall, flex: 3 }}>
                  {numberWithCommas(this.getOverall('delivered'))}
                </div>
                <div style={{ ...styles.headerOverall, flex: 3 }}>
                  {numberWithCommas(this.getOverall('communications'))}
                </div>
                <div style={{ ...styles.headerOverall, flex: 3 }}>
                  {this.renderConversionSummaryPercentage() + '%'}
                </div>
                <div style={{ ...styles.headerOverall, flex: 3 }}>
                  {numberWithCommas(this.getOverall('revenue'))}
                </div>
                <div style={{ ...styles.headerOverall, flex: 3 }}>
                  {this.renderOnclickConversionsPercentage() + '%'}
                </div>
              </div>
              {this.props.overview &&
                Array.isArray(this.props.overview.tagInfos) &&
                this.props.overview.tagInfos.map((report, index) => {
                  if (!this.state.showMore && index <= displayFirstItems - 1) {
                    return (
                      <div
                        key={index}
                        style={{
                          ...styles.headerInfoContainer,
                          margin: '20px 0',
                        }}
                      >
                        <div style={{ ...styles.headerData, flex: 2 }}>
                          {this.renderTagItem(report.tags)}
                        </div>
                        <div style={{ ...styles.headerData, flex: 3 }}>
                          {this.renderBarChart(report, 'delivered')}
                        </div>
                        <div style={{ ...styles.headerData, flex: 3 }}>
                          {this.renderBarChart(report, 'communications')}
                        </div>
                        <div style={{ ...styles.headerData, flex: 3 }}>
                          {this.renderBarChart(report, 'conversions')}
                        </div>
                        <div style={{ ...styles.headerData, flex: 3 }}>
                          {this.renderBarChart(report, 'revenue')}
                        </div>
                        <div style={{ ...styles.headerData, flex: 3 }}>
                          {this.renderBarChart(report, 'convertedAfterClick')}
                        </div>
                      </div>
                    );
                  } else if (this.state.showMore) {
                    return (
                      <div
                        key={index}
                        style={{
                          ...styles.headerInfoContainer,
                          margin: '20px 0',
                        }}
                      >
                        <div style={{ ...styles.headerData, flex: 2 }}>
                          {this.renderTagItem(report.tags)}
                        </div>
                        <div style={{ ...styles.headerData, flex: 3 }}>
                          {this.renderBarChart(report, 'delivered')}
                        </div>
                        <div style={{ ...styles.headerData, flex: 3 }}>
                          {this.renderBarChart(report, 'communications')}
                        </div>
                        <div style={{ ...styles.headerData, flex: 3 }}>
                          {this.renderBarChart(report, 'conversions')}
                        </div>
                        <div style={{ ...styles.headerData, flex: 3 }}>
                          {this.renderBarChart(report, 'revenue')}
                        </div>
                        <div style={{ ...styles.headerData, flex: 3 }}>
                          {this.renderBarChart(report, 'convertedAfterClick')}
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
            <div
              style={{
                marginTop: 20,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {this.props.overview &&
              this.props.overview.tagInfos &&
              this.props.overview.tagInfos.length <= displayFirstItems ? (
                <div></div>
              ) : (
                <Button
                  type="primary"
                  shape="round"
                  size={'large'}
                  style={styles.showMoreButton}
                  onClick={() => {
                    this.setState({ showMore: !this.state.showMore });
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <div>{this.state.showMore ? 'Show Less' : 'Show More'}</div>
                    <div>
                      {this.state.showMore ? (
                        <UpOutlined
                          style={{ paddingLeft: 10 }}
                          color={'white'}
                        />
                      ) : (
                        <DownOutlined
                          style={{ paddingLeft: 10 }}
                          color={'white'}
                        />
                      )}
                    </div>
                  </div>
                </Button>
              )}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {' '}
            <div
              style={{
                padding: 10,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Radio.Group
                value={this.state.showPercentage}
                onChange={(e) =>
                  this.setState({ showPercentage: e.target.value })
                }
              >
                <Radio.Button value={true}>%</Radio.Button>
                <Radio.Button value={false}>#</Radio.Button>
              </Radio.Group>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  borderTop: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                TAGS
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  borderTop: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                Delivered
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  borderTop: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                COMMUNICATIONS
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  borderTop: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                CONVERSIONS
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  borderTop: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                REVENUE
                {this.props.currentRevenueMapping &&
                isNil(this.props.currentRevenueMapping.revenueCurrency)
                  ? ''
                  : ' (' +
                    this.props.currentRevenueMapping.revenueCurrency +
                    ')'}
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  borderTop: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                ON CLICK CONVERSIONS
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                borderTop: '1px solid #e7e8e9',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  ...styles.tableData,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                Overall
              </div>
              <div
                style={{
                  ...styles.tableData,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                {numberWithCommas(this.getOverall('delivered'))}
              </div>
              <div
                style={{
                  ...styles.tableData,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                {numberWithCommas(this.getOverall('communications'))}
              </div>
              <div
                style={{
                  ...styles.tableData,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                {numberWithCommas(this.getOverall('conversions'))}
              </div>
              <div
                style={{
                  ...styles.tableData,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                {numberWithCommas(this.getOverall('revenue'))}
              </div>
              <div
                style={{
                  ...styles.tableData,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                {numberWithCommas(this.getOverall('convertedAfterClick'))}
              </div>
            </div>
            {this.props.overview &&
              Array.isArray(this.props.overview.tagInfos) &&
              this.props.overview.tagInfos.map((report, index) => {
                if (!this.state.showMore && index <= displayFirstItems - 1) {
                  return (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        borderTop: '1px solid #e7e8e9',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        style={{
                          ...styles.tableData,
                          borderRight: '1px solid #e7e8e9',
                          flex: 1,
                        }}
                      >
                        {this.renderTagItem(report.tags)}
                      </div>
                      <div
                        style={{
                          ...styles.tableData,
                          borderRight: '1px solid #e7e8e9',
                          flex: 1,
                        }}
                      >
                        {this.renderTagBarChart(
                          report,
                          'delivered',
                          this.state.showPercentage
                        )}
                      </div>
                      <div
                        style={{
                          ...styles.tableData,
                          borderRight: '1px solid #e7e8e9',
                          flex: 1,
                        }}
                      >
                        {this.renderTagBarChart(
                          report,
                          'communications',
                          this.state.showPercentage
                        )}
                      </div>
                      <div
                        style={{
                          ...styles.tableData,
                          borderRight: '1px solid #e7e8e9',
                          flex: 1,
                        }}
                      >
                        {this.renderTagBarChart(
                          report,
                          'conversions',
                          this.state.showPercentage
                        )}
                      </div>
                      <div
                        style={{
                          ...styles.tableData,
                          borderRight: '1px solid #e7e8e9',
                          flex: 1,
                        }}
                      >
                        {this.renderTagBarChart(
                          report,
                          'revenue',
                          this.state.showPercentage
                        )}
                      </div>
                      <div
                        style={{
                          ...styles.tableData,
                          borderRight: '1px solid #e7e8e9',
                          flex: 1,
                        }}
                      >
                        {this.renderTagBarChart(
                          report,
                          'convertedAfterClick',
                          this.state.showPercentage
                        )}
                      </div>
                    </div>
                  );
                } else if (this.state.showMore) {
                  return (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        borderTop: '1px solid #e7e8e9',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        style={{
                          ...styles.tableData,
                          borderRight: '1px solid #e7e8e9',
                          flex: 1,
                        }}
                      >
                        {this.renderTagItem(report.tags)}
                      </div>
                      <div
                        style={{
                          ...styles.tableData,
                          borderRight: '1px solid #e7e8e9',
                          flex: 1,
                        }}
                      >
                        {this.renderTagBarChart(
                          report,
                          'delivered',
                          this.state.showPercentage
                        )}
                      </div>
                      <div
                        style={{
                          ...styles.tableData,
                          borderRight: '1px solid #e7e8e9',
                          flex: 1,
                        }}
                      >
                        {this.renderTagBarChart(
                          report,
                          'communications',
                          this.state.showPercentage
                        )}
                      </div>
                      <div
                        style={{
                          ...styles.tableData,
                          borderRight: '1px solid #e7e8e9',
                          flex: 1,
                        }}
                      >
                        {this.renderTagBarChart(
                          report,
                          'conversions',
                          this.state.showPercentage
                        )}
                      </div>
                      <div
                        style={{
                          ...styles.tableData,
                          borderRight: '1px solid #e7e8e9',
                          flex: 1,
                        }}
                      >
                        {this.renderTagBarChart(
                          report,
                          'revenue',
                          this.state.showPercentage
                        )}
                      </div>
                      <div
                        style={{
                          ...styles.tableData,
                          borderRight: '1px solid #e7e8e9',
                          flex: 1,
                        }}
                      >
                        {this.renderTagBarChart(
                          report,
                          'convertedAfterClick',
                          this.state.showPercentage
                        )}
                      </div>
                    </div>
                  );
                }
              })}
            <div
              style={{
                ...styles.TagBorder,
                borderBottom: '1px solid #e7e8e9',
                flex: 1,
              }}
            ></div>
            <div
              style={{
                marginTop: 20,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {this.props.overview &&
              this.props.overview.tagInfos &&
              this.props.overview.tagInfos.length <= displayFirstItems ? (
                <div></div>
              ) : (
                <Button
                  type="primary"
                  shape="round"
                  size={'large'}
                  style={styles.showMoreButton}
                  onClick={() => {
                    this.setState({ showMore: !this.state.showMore });
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <div>{this.state.showMore ? 'Show Less' : 'Show More'}</div>
                    <div>
                      {this.state.showMore ? (
                        <UpOutlined
                          style={{ paddingLeft: 10 }}
                          color={'white'}
                        />
                      ) : (
                        <DownOutlined
                          style={{ paddingLeft: 10 }}
                          color={'white'}
                        />
                      )}
                    </div>
                  </div>
                </Button>
              )}
            </div>
          </React.Fragment>
        )}
      </Card>
    );
  };

  renderSelectedTagsCount = () => {
    if (
      this.props.pageRequestSplitOverview &&
      this.props.pageRequestSplitOverview.tags &&
      Array.isArray(this.props.pageRequestSplitOverview.tags) &&
      this.props.pageRequestSplitOverview.tags.length > 0
    ) {
      return this.props.pageRequestSplitOverview.tags.length;
    }
    return null;
  };

  renderTagSelector = () => {
    return (
      <Dropdown
        overlay={
          <TagSelector
            listTags={this.props.listTags}
            selectedTags={
              this.props.pageRequestSplitOverview &&
              this.props.pageRequestSplitOverview.tags &&
              Array.isArray(this.props.pageRequestSplitOverview.tags)
                ? this.props.pageRequestSplitOverview.tags
                : null
            }
            onApply={(tags) => this.handleApplyTags(tags)}
          />
        }
        trigger={['click']}
      >
        <div
          id="tag-selector"
          style={{
            width: 80,
            marginRight: 20,
            display: 'flex',
            flexDirection: 'row',
            color: '#1C1C1C',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#F3F3F3',
            cursor: 'pointer',
          }}
        >
          <Badge
            offset={[10, 0]}
            count={this.renderSelectedTagsCount()}
            size="small"
            showZero={false}
          >
            Tags
          </Badge>{' '}
          <DownOutlined
            style={{ marginLeft: 20, color: '#b6b6b6', fontSize: 12 }}
          />
        </div>
      </Dropdown>
    );
  };

  renderTitle = (item, splitBy) => {
    if (splitBy === 'TYPE') {
      return CommunicationTypes[item.type];
    }
    if (splitBy === 'TAGS') {
      if (!isNil(item.tags)) {
        return renderTag(item.tags, this.props.listTags);
      }
      return <span>(No Tag)</span>;
    }
    return null;
  };

  renderSplitOverviewPercentage = (delivered, number) => {
    if (delivered === 0 || isNil(number) || number === 0) {
      return 0;
    }
    return ((number * 100) / delivered).toFixed(2);
  };

  renderSplitOverviewTable = () => {
    let data = [];
    if (this.props.splitOverview && Array.isArray(this.props.splitOverview)) {
      if (this.props.pageRequestSplitOverview.splitBy === 'CHANNEL') {
        data = [
          {
            communications: 0,
            delivered: 0,
            uniqueDelivered: 0,
            uniqueClicks: 0,
            uniqueConversions: 0,
            revenue: 0,
            convertedAfterClick: 0,
          },
        ];
        this.props.splitOverview.forEach((item) => {
          data = [
            {
              communications: data[0].communications + item.communications,
              delivered: data[0].delivered + item.delivered,
              uniqueDelivered: data[0].uniqueDelivered + item.uniqueDelivered,
              uniqueClicks: data[0].uniqueClicks + item.uniqueClicks,
              uniqueConversions:
                data[0].uniqueConversions + item.uniqueConversions,
              revenue: data[0].revenue + item.revenue,
              convertedAfterClick:
                data[0].convertedAfterClick + item.convertedAfterClick,
            },
          ];
        });
      } else if (this.props.pageRequestSplitOverview.splitBy === 'TYPE') {
        Object.keys(CommunicationTypes).forEach((type) => {
          const sub = filter(this.props.splitOverview, (o) => o.type === type);
          let temp = {
            type,
            communications: 0,
            delivered: 0,
            uniqueDelivered: 0,
            uniqueClicks: 0,
            uniqueConversions: 0,
            revenue: 0,
            convertedAfterClick: 0,
          };
          sub.forEach((item) => {
            temp = {
              type,
              communications: temp.communications + item.communications,
              delivered: temp.delivered + item.delivered,
              uniqueDelivered: temp.uniqueDelivered + item.uniqueDelivered,
              uniqueClicks: temp.uniqueClicks + item.uniqueClicks,
              uniqueConversions:
                temp.uniqueConversions + item.uniqueConversions,
              revenue: temp.revenue + item.revenue,
              convertedAfterClick:
                temp.convertedAfterClick + item.convertedAfterClick,
            };
          });
          if (sub.length > 0) {
            data.push(temp);
          }
        });
      } else if (this.props.pageRequestSplitOverview.splitBy === 'TAGS') {
        this.props.listTags.forEach((tag) => {
          const sub = filter(
            this.props.splitOverview,
            (o) => o.tags === tag.name
          );
          let temp = {
            tags: tag.name,
            communications: 0,
            delivered: 0,
            uniqueDelivered: 0,
            uniqueClicks: 0,
            uniqueConversions: 0,
            revenue: 0,
            convertedAfterClick: 0,
          };
          sub.forEach((item) => {
            temp = {
              tags: tag.name,
              communications: temp.communications + item.communications,
              delivered: temp.delivered + item.delivered,
              uniqueDelivered: temp.uniqueDelivered + item.uniqueDelivered,
              uniqueClicks: temp.uniqueClicks + item.uniqueClicks,
              uniqueConversions:
                temp.uniqueConversions + item.uniqueConversions,
              revenue: temp.revenue + item.revenue,
              convertedAfterClick:
                temp.convertedAfterClick + item.convertedAfterClick,
            };
          });
          if (sub.length > 0) {
            data.push(temp);
          }
        });
        const withoutTagSub = filter(
          this.props.splitOverview,
          (o) => isNil(o.tags) || isEmpty(o.tags) || !o.hasOwnProperty('tags')
        );
        let tempWithoutTag = {
          tags: null,
          communications: 0,
          delivered: 0,
          uniqueDelivered: 0,
          uniqueClicks: 0,
          uniqueConversions: 0,
          revenue: 0,
          convertedAfterClick: 0,
        };
        withoutTagSub.forEach((item) => {
          tempWithoutTag = {
            tags: null,
            communications: tempWithoutTag.communications + item.communications,
            delivered: tempWithoutTag.delivered + item.delivered,
            uniqueDelivered:
              tempWithoutTag.uniqueDelivered + item.uniqueDelivered,
            uniqueClicks: tempWithoutTag.uniqueClicks + item.uniqueClicks,
            uniqueConversions:
              tempWithoutTag.uniqueConversions + item.uniqueConversions,
            revenue: tempWithoutTag.revenue + item.revenue,
            convertedAfterClick:
              tempWithoutTag.convertedAfterClick + item.convertedAfterClick,
          };
        });
        if (withoutTagSub.length > 0) {
          data.unshift(tempWithoutTag);
        }
      }
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          borderRadius: 5,
          paddingBottom: 5,
          boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {this.props.pageRequestSplitOverview.splitBy !== 'CHANNEL' && (
            <div
              style={{
                ...styles.tableTitle,
                borderRight: '1px solid #e7e8e9',
                flex: 1,
              }}
            >
              {this.props.pageRequestSplitOverview.splitBy}
            </div>
          )}
          {false && (
            <div
              style={{
                ...styles.tableTitle,
                borderRight: '1px solid #e7e8e9',
                flex: 1,
              }}
            >
              COMMUNICATION
            </div>
          )}
          <div
            style={{
              ...styles.tableTitle,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
            }}
          >
            Delivered
          </div>
          <div
            style={{
              ...styles.tableTitle,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
            }}
          >
            UNIQUE CLICKS
          </div>
          <div
            style={{
              ...styles.tableTitle,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
            }}
          >
            UNIQUE CONVERSIONS
          </div>

          <div
            style={{
              ...styles.tableTitle,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
            }}
          >
            REVENUE
            {this.props.currentRevenueMapping &&
            isNil(this.props.currentRevenueMapping.revenueCurrency)
              ? ''
              : ' (' + this.props.currentRevenueMapping.revenueCurrency + ')'}
          </div>
          <div
            style={{
              ...styles.tableTitle,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
            }}
          >
            ON CLICK CONVERSIONS
          </div>
        </div>
        {isEmpty(data) ? (
          <div
            style={{
              borderTop: '1px solid #e7e8e9',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        ) : (
          data.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  borderTop: '1px solid #e7e8e9',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                {this.props.pageRequestSplitOverview.splitBy !== 'CHANNEL' && (
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {this.renderTitle(
                      item,
                      this.props.pageRequestSplitOverview.splitBy
                    )}
                  </div>
                )}
                {false && (
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {numberWithCommas(item.communications)}
                  </div>
                )}
                <div
                  style={{
                    ...styles.tableData,
                    borderRight: '1px solid #e7e8e9',
                    flex: 1,
                  }}
                >
                  {numberWithCommas(item.delivered)}
                </div>
                <div
                  style={{
                    ...styles.tableData,
                    borderRight: '1px solid #e7e8e9',
                    flex: 1,
                  }}
                >
                  {this.renderSplitOverviewPercentage(
                    item.uniqueDelivered,
                    item.uniqueClicks
                  )}
                  %
                </div>
                <div
                  style={{
                    ...styles.tableData,
                    borderRight: '1px solid #e7e8e9',
                    flex: 1,
                  }}
                >
                  {this.renderSplitOverviewPercentage(
                    item.uniqueDelivered,
                    item.uniqueConversions
                  )}
                  %
                </div>
                <div
                  style={{
                    ...styles.tableData,
                    borderRight: '1px solid #e7e8e9',
                    flex: 1,
                  }}
                >
                  {numberWithCommas(item.revenue)}
                </div>
                <div
                  style={{
                    ...styles.tableData,
                    borderRight: '1px solid #e7e8e9',
                    flex: 1,
                  }}
                >
                  {item.convertedAfterClick
                    ? numberWithCommas(item.convertedAfterClick)
                    : '-'}
                </div>
              </div>
            );
          })
        )}
      </div>
    );
  };

  renderSplitOverview = () => {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            padding: '12px',
            borderBottom: 'solid 2px #f4f9ff',
          }}
        >
          <Select
            mode="multiple"
            maxTagCount={3}
            placeholder={'All Communication Types'}
            onChange={(types) =>
              this.props.communicationSplitOverviewFetch({
                ...this.props.pageRequestSplitOverview,
                types,
              })
            }
            value={
              this.props.pageRequestSplitOverview &&
              this.props.pageRequestSplitOverview.types &&
              Array.isArray(this.props.pageRequestSplitOverview.types) &&
              this.props.pageRequestSplitOverview.types.length > 0
                ? this.props.pageRequestSplitOverview.types
                : []
            }
            style={{ minWidth: 220, marginRight: 20 }}
            className={'chart-selector'}
            bordered={false}
          >
            <Select.Option value="ONE_TIME">One-Time</Select.Option>
            <Select.Option value="TRIGGERED">Triggered</Select.Option>
            <Select.Option value="RECURRING">Recurring</Select.Option>
            <Select.Option value="TRANSACTIONAL">Transactional</Select.Option>
            <Select.Option value="JOURNEY">Journey</Select.Option>
            <Select.Option value="RELAY">Relay</Select.Option>
          </Select>
          {this.renderTagSelector()}
          <span style={{ width: 60, paddingTop: 5 }}>SPLIT BY</span>
          <Select
            onChange={(splitBy) =>
              this.props.communicationSplitOverviewFetch({
                ...this.props.pageRequestSplitOverview,
                splitBy,
              })
            }
            value={
              this.props.pageRequestSplitOverview &&
              this.props.pageRequestSplitOverview.splitBy
                ? this.props.pageRequestSplitOverview.splitBy
                : 'Channels'
            }
            style={{ width: 120 }}
            className={'chart-selector'}
            bordered={false}
          >
            <Select.Option value="CHANNEL">Channels</Select.Option>
            <Select.Option value="TYPE">Type</Select.Option>
            <Select.Option value="TAGS">Tags</Select.Option>
          </Select>
        </div>
        {this.renderSplitOverviewTable()}
      </div>
    );
  };

  render() {
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 30 }} Spin />;
    return (
      <div>
        {this.renderSummary()}
        {this.renderTagsInfo()}
        <Card>
          {' '}
          {this.props.loadingOverview || this.props.loadingSplitOverview ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                paddingTop: 50,
                paddingBottom: 50,
              }}
            >
              <Spin indicator={inputLoadingSpin} />
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {this.renderSplitOverview()}
              {this.renderReport()}
            </div>
          )}
        </Card>
      </div>
    );
  }
}

export default CommunicationOverviewComponent;
