import React from 'react';
import {
  Form,
  InputNumber,
  Select,
  Switch,
  DatePicker,
  Divider,
  AutoComplete,
  Space,
  Tooltip,
  Alert,
} from 'antd';
import { getDelayMinutes, convertMinute } from '@Utils/DateUtils';
import { find as lodashFind, isNil, isEmpty, cloneDeep, range } from 'lodash';
import moment from 'moment';
import { DeleteOutlined, DoubleLeftOutlined } from '@ant-design/icons';
import RestrictionOperator from '@Utils/model/restrictionOperator';
import NotRestrictionOperator from '@Utils/model/notRestrictionOperator';
import styles from './ProductSegmentStyle';
import { errorHandling } from '@Utils/FormUtils';
import { AttributeTriggerStatusType } from '../../../../component/journey/utils/static';
import _ from 'lodash';
import { numberValidation } from '@Utils/CheckNumber';
import { isNull } from 'lodash';
import { AuthorityProvider, hasAnyAuthority } from '@Utils/AuthorityProvider';

class ProductSegmentPredicateComponent extends React.Component {
  constructor(props) {
    super(props);
    this.predicateType = {
      ATTRIBUTE: 'ATTRIBUTE',
      DEVICE: 'DEVICE',
      BEHAVIOUR: 'BEHAVIOUR',
      USER_ATTRIBUTE: 'USER_ATTRIBUTE',
    };
    this.emptyEvent = {
      event: null,
      eventAggregation: 'EXISTS',
      eventConjunction: 'AND',
      type: this.props.isInSegment ? 'BEHAVIOUR' : this.props.type,
      negate: false,
      eventPredicates: [],
    };
    this.emptyEventPredicate = {
      attribute: null,
      greaterThan: null,
      lessThan: null,
      negate: false,
      operator: null,
      stringValue: null,
    };
    this.emptyDevicePredicate = {
      attribute: null,
      greaterThan: null,
      lessThan: null,
      negate: false,
      operator: null,
      stringValue: null,
    };
    this.emptyAttributePredicate = {
      // attribute: null,
      greaterThan: null,
      lessThan: null,
      negate: false,
      operator: null,
      stringValue: null,
      type: null, // [NEW_VALUE, OLD_VALUE]
    };
    this.state = {
      showAggregationBox: false,
      isDynumic: [],
    };
  }

  componentDidMount() {
    const predicate = this.props.predicate;

    const attribute = predicate.attribute;
    if (predicate.eventAggregation !== 'EXISTS') {
      this.setState({ showAggregationBox: true });
    }
    if (
      !!attribute &&
      predicate.type === this.predicateType.ATTRIBUTE &&
      (hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_SEGMENT_CREATE) ||
        hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_SEGMENT_UPDATE) ||
        hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_SEGMENT_REPORT))
    ) {
      this.props.fetchRecommendUserAttributes(attribute);
    }
    // attributes
    const attributePredicateCopy = cloneDeep(this.props.predicate);
    this.fillDates(predicate, attributePredicateCopy, this.props.attributes);
    // events
    if (
      predicate &&
      !isNil(predicate.event) &&
      predicate.eventPredicates &&
      Array.isArray(predicate.eventPredicates)
    ) {
      const updatedIsDynumic = Array(predicate?.eventPredicates?.length).fill(
        false
      );
      const foundItem = lodashFind(
        this.props.events,
        (o) => o.id === predicate.event
      );

      const list =
        foundItem && foundItem.attributes && Array.isArray(foundItem.attributes)
          ? foundItem.attributes
          : [];
      const predicateCopy = cloneDeep(this.props.predicate);
      predicate.eventPredicates.forEach((eventPredicate, index) => {
        this.fillDates(eventPredicate, predicateCopy, list, index);
        const isDynamic =
          !isNull(eventPredicate.dynamicGreaterThan) ||
          !isNull(eventPredicate.dynamicLessThan) ||
          !isNull(eventPredicate.dynamicValue);

        updatedIsDynumic[index] = isDynamic;
        this.setState({ isDynumic: updatedIsDynumic });
        if (
          eventPredicate?.attribute &&
          _.isFunction(this.props?.fetchRecommendEventAttributes)
        ) {
          console.log('didMount fetchRecommendEventAttributes');
          this.props?.fetchRecommendEventAttributes(eventPredicate.attribute);
        }
      });
      this.AggregationFillDates(predicate, predicateCopy, this.props.events);
    }

    // devices
    if (
      predicate &&
      // !isNil(predicate.attribute) &&
      predicate.devicePredicates &&
      Array.isArray(predicate.devicePredicates)
    ) {
      const predicateCopy = cloneDeep(this.props.predicate);
      predicate.devicePredicates.forEach((devicePredicate, index) => {
        const foundItem = cloneDeep(
          lodashFind(
            this.props.attributes,
            (o) => o.id === devicePredicate?.attribute
          )
        );

        const list = (foundItem && [foundItem]) || [];
        this.fillDatesDevice(devicePredicate, predicateCopy, list, index);
        this.props.fetchRecommendDeviceAttributes(devicePredicate.attribute);
      });
    }
  }

  componentDidUpdate(prevProps) {
    const predicate = this.props.predicate;
    if (
      // prevProps.predicate.attribute !== this.props.predicate.attribute ||
      Array.isArray(this.props.attributes) &&
      Array.isArray(prevProps.attributes) &&
      prevProps.attributes.length !== this.props.attributes.length
    ) {
      const attribute = predicate.attribute;
      if (
        (hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_SEGMENT_CREATE) ||
          hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_SEGMENT_UPDATE) ||
          hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_SEGMENT_REPORT)) &&
        !!attribute &&
        (predicate.type === this.predicateType.ATTRIBUTE ||
          predicate.type === 'CHECK_USER_ATTRIBUTE')
      ) {
        this.props.fetchRecommendUserAttributes(attribute);
      }
    }
    if (
      prevProps.predicate.event !== this.props.predicate.event ||
      (Array.isArray(this.props.attributes) &&
        Array.isArray(prevProps.attributes) &&
        prevProps.attributes.length !== this.props.attributes.length) ||
      (Array.isArray(this.props.events) &&
        Array.isArray(prevProps.events) &&
        prevProps.events.length !== this.props.events.length)
    ) {
      // attributes
      const attributePredicateCopy = cloneDeep(this.props.predicate);
      this.fillDates(predicate, attributePredicateCopy, this.props.attributes);
      // events
      if (predicate.eventAggregation !== 'EXISTS') {
        this.setState({ showAggregationBox: true });
      }
      let list;
      if (
        predicate &&
        !isNil(predicate.event) &&
        predicate.eventPredicates &&
        Array.isArray(predicate.eventPredicates)
      ) {
        const foundItem = lodashFind(
          this.props.events,
          (o) => o.id === predicate.event
        );
        list =
          foundItem &&
          foundItem.attributes &&
          Array.isArray(foundItem.attributes)
            ? foundItem.attributes
            : [];
        const predicateCopy = cloneDeep(this.props.predicate);
        predicate.eventPredicates.forEach((eventPredicate, index) => {
          this.fillDates(eventPredicate, predicateCopy, list, index);
          if (eventPredicate.attribute) {
            this.props.fetchRecommendEventAttributes(eventPredicate.attribute);
          }
        });
        this.AggregationFillDates(predicate, predicateCopy, this.props.events);
      }
    }

    if (
      this.props.type === 'CHANGE_IN_USER_ATTRIBUTE' &&
      prevProps.predicate?.attribute !== this.props.predicate?.attribute &&
      (hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_SEGMENT_CREATE) ||
        hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_SEGMENT_UPDATE) ||
        hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_SEGMENT_REPORT))
    ) {
      this.props.fetchRecommendUserAttributes(this.props.predicate?.attribute);
    }
  }

  AggregationFillDates = (item, predicateCopy, list) => {
    //Aggregation Fill
    if (
      item &&
      !isNil(item.event) &&
      item.operator === 'BETWEEN' &&
      ((!isNil(item.greaterThan) && !isNaN(item.greaterThan)) ||
        (!isNil(item.lessThan) && !isNaN(item.lessThan)))
    ) {
      const found = lodashFind(list, (o) => o.id === item.event);
      const attributes =
        found && found.attributes && Array.isArray(found.attributes)
          ? found.attributes
          : [];
      const currentAttribute = lodashFind(
        attributes,
        (o) => o.id === item.eventAttribute
      );
      const type =
        currentAttribute && currentAttribute.type
          ? currentAttribute.type
          : null;
      if (type === 'DATE') {
        let selector = predicateCopy;
        if (!isNil(item.greaterThan)) {
          const relativeDateFrom = convertMinute(
            Math.abs(Number(item.greaterThan))
          );
          selector.relativeDateDurationFrom = cloneDeep(relativeDateFrom.type);
          selector.relativeDateValueFrom = cloneDeep(relativeDateFrom.value);
        }
        if (!isNil(item.lessThan)) {
          const relativeDateTo = convertMinute(Math.abs(Number(item.lessThan)));
          selector.relativeDateDurationTo = cloneDeep(relativeDateTo.type);
          selector.relativeDateValueTo = cloneDeep(relativeDateTo.value);
        }
        let relativeDateType = 'NEXT-NEXT';
        if (Number(item.greaterThan) < 0) {
          relativeDateType = 'LAST' + relativeDateType.substring(4);
        }
        if (Number(item.lessThan) < 0) {
          relativeDateType = relativeDateType.substring(0, 5) + 'LAST';
        }
        if (Number(item.lessThan) < 0 && isNil(item.greaterThan)) {
          relativeDateType = 'LAST-LAST';
        }
        selector.relativeDateType = relativeDateType;
        this.props.handleChangePredicate(predicateCopy);
      }
    }
    if (
      item &&
      !isNil(item.event) &&
      item.operator === 'BETWEEN' &&
      ((!isNil(item.greaterThan) &&
        isNaN(item.greaterThan) &&
        moment(item.greaterThan).isValid()) ||
        (!isNil(item.lessThan) &&
          isNaN(item.lessThan) &&
          moment(item.lessThan).isValid()))
    ) {
      const found = lodashFind(list, (o) => o.id === item.event);
      const attributes =
        found && found.attributes && Array.isArray(found.attributes)
          ? found.attributes
          : [];
      const currentAttribute = lodashFind(
        attributes,
        (o) => o.id === item.eventAttribute
      );
      const type =
        currentAttribute && currentAttribute.type
          ? currentAttribute.type
          : null;
      if (type === 'DATE') {
        let selector = predicateCopy;
        selector.relativeDateType = 'DATE';
        this.props.handleChangePredicate(predicateCopy);
      }
    }
  };

  fillDates = (item, predicateCopy, list, eventPredicateIndex = -1) => {
    if (
      item &&
      !isNil(item.attribute) &&
      item.operator === 'BETWEEN' &&
      ((!isNil(item.greaterThan) && !isNaN(item.greaterThan)) ||
        (!isNil(item.lessThan) && !isNaN(item.lessThan)))
    ) {
      const found = lodashFind(list, (o) => o.id === item.attribute);
      const type = found && found.type ? found.type : null;
      if (type === 'DATE') {
        let selector = predicateCopy;
        if (eventPredicateIndex > -1) {
          selector = predicateCopy.eventPredicates[eventPredicateIndex];
        }

        if (!isNil(item.greaterThan)) {
          const relativeDateFrom = convertMinute(
            Math.abs(Number(item.greaterThan))
          );
          selector.relativeDateDurationFrom = cloneDeep(relativeDateFrom.type);
          selector.relativeDateValueFrom = cloneDeep(relativeDateFrom.value);
        }

        if (!isNil(item.lessThan)) {
          const relativeDateTo = convertMinute(Math.abs(Number(item.lessThan)));
          selector.relativeDateDurationTo = cloneDeep(relativeDateTo.type);
          selector.relativeDateValueTo = cloneDeep(relativeDateTo.value);
        }

        let relativeDateType = 'NEXT-NEXT';
        if (Number(item.greaterThan) < 0) {
          relativeDateType = 'LAST' + relativeDateType.substring(4);
        }
        if (Number(item.lessThan) < 0) {
          relativeDateType = relativeDateType.substring(0, 5) + 'LAST';
        }
        if (Number(item.lessThan) < 0 && isNil(item.greaterThan)) {
          relativeDateType = 'LAST-LAST';
        }
        selector.relativeDateType = relativeDateType;
        this.props.handleChangePredicate(predicateCopy);
      }
    }
    if (
      item &&
      !isNil(item.attribute) &&
      item.operator === 'BETWEEN' &&
      ((!isNil(item.greaterThan) &&
        isNaN(item.greaterThan) &&
        moment(item.greaterThan).isValid()) ||
        (!isNil(item.lessThan) &&
          isNaN(item.lessThan) &&
          moment(item.lessThan).isValid()))
    ) {
      const found = lodashFind(list, (o) => o.id === item.attribute);
      const type = found && found.type ? found.type : null;
      if (type === 'DATE') {
        let selector = predicateCopy;
        if (eventPredicateIndex > -1) {
          selector = predicateCopy.eventPredicates[eventPredicateIndex];
        }
        selector.relativeDateType = 'DATE';

        this.props.handleChangePredicate(predicateCopy);
      }
    }
  };

  fillDatesDevice = (item, predicateCopy, list, devicePredicateIndex = -1) => {
    if (
      item &&
      !isNil(item.attribute) &&
      item.operator === 'BETWEEN' &&
      ((!isNil(item.greaterThan) && !isNaN(item.greaterThan)) ||
        (!isNil(item.lessThan) && !isNaN(item.lessThan)))
    ) {
      const found = lodashFind(list, (o) => o.id === item.attribute);
      const type = found && found.type ? found.type : null;
      if (type === 'DATE') {
        let selector = cloneDeep(predicateCopy);
        if (devicePredicateIndex > -1) {
          selector = predicateCopy.devicePredicates[devicePredicateIndex];
        }

        if (!isNil(item.greaterThan)) {
          const relativeDateFrom = convertMinute(
            Math.abs(Number(item.greaterThan))
          );
          selector.relativeDateDurationFrom = cloneDeep(relativeDateFrom.type);
          selector.relativeDateValueFrom = cloneDeep(relativeDateFrom.value);
        }

        if (!isNil(item.lessThan)) {
          const relativeDateTo = convertMinute(Math.abs(Number(item.lessThan)));
          selector.relativeDateDurationTo = cloneDeep(relativeDateTo.type);
          selector.relativeDateValueTo = cloneDeep(relativeDateTo.value);
        }

        let relativeDateType = 'NEXT-NEXT';
        if (Number(item.greaterThan) < 0) {
          relativeDateType = 'LAST' + relativeDateType.substring(4);
        }
        if (Number(item.lessThan) < 0) {
          relativeDateType = relativeDateType.substring(0, 5) + 'LAST';
        }
        if (Number(item.lessThan) < 0 && isNil(item.greaterThan)) {
          relativeDateType = 'LAST-LAST';
        }
        selector.relativeDateType = relativeDateType;
        if (devicePredicateIndex > -1) {
          selector = Object.assign(
            {},
            predicateCopy,
            predicateCopy.devicePredicates[devicePredicateIndex]
          );
        }

        this.props.handleChangePredicate(predicateCopy);
      }
    }
    if (
      item &&
      !isNil(item.attribute) &&
      item.operator === 'BETWEEN' &&
      ((!isNil(item.greaterThan) &&
        isNaN(item.greaterThan) &&
        moment(item.greaterThan).isValid()) ||
        (!isNil(item.lessThan) &&
          isNaN(item.lessThan) &&
          moment(item.lessThan).isValid()))
    ) {
      const found = lodashFind(list, (o) => o.id === item.attribute);
      const type = found && found.type ? found.type : null;

      if (type === 'DATE') {
        let selector = predicateCopy;
        if (devicePredicateIndex > -1) {
          selector = predicateCopy.devicePredicates[devicePredicateIndex];
        }
        selector.relativeDateType = 'DATE';
        this.props.handleChangePredicate(predicateCopy);
      }
    }
  };

  getMultipleStringValue = (value) => {
    let result = [];
    if (value && !isEmpty(value)) {
      // result = value.split(/[\s;,]+/);
      result = value.split(/[;,]+/);
    }
    return result;
  };

  handleAddInnerAttribute = (predicateType) => {
    let emptyInnerPredicateCopy = null;
    let innerPredicateTitle = null;
    if (predicateType === this.predicateType.BEHAVIOUR) {
      emptyInnerPredicateCopy = cloneDeep(this.emptyEventPredicate);
      innerPredicateTitle = 'eventPredicates';
    } else if (predicateType === this.predicateType.DEVICE) {
      emptyInnerPredicateCopy = cloneDeep(this.emptyDevicePredicate);
      innerPredicateTitle = 'devicePredicates';
    } else if (predicateType === this.predicateType.ATTRIBUTE) {
      emptyInnerPredicateCopy = cloneDeep(this.emptyAttributePredicate);
      innerPredicateTitle = 'attributePredicates';
    }
    const predicateCopy = cloneDeep(this.props.predicate);
    if (
      predicateCopy[innerPredicateTitle] &&
      Array.isArray(predicateCopy[innerPredicateTitle]) &&
      predicateCopy[innerPredicateTitle].length > 0
    ) {
      predicateCopy[innerPredicateTitle].push(emptyInnerPredicateCopy);
    } else {
      predicateCopy[innerPredicateTitle] = [emptyInnerPredicateCopy];
    }
    this.props.handleChangePredicate(predicateCopy);
  };

  deletePredicate = () => {
    this.props.handleDeletePredicate();
  };

  resetPredicate = () => {
    const predicateCopy = cloneDeep(this.props.predicate);
    predicateCopy.operator = null;
    predicateCopy.stringValue = null;
    predicateCopy.lessThan = null;
    predicateCopy.greaterThan = null;
    predicateCopy.relativeDateDurationFrom = null;
    predicateCopy.relativeDateDurationTo = null;
    predicateCopy.relativeDateType = null;
    predicateCopy.relativeDateValueFrom = null;
    predicateCopy.relativeDateValueTo = null;
    predicateCopy.eventAggregation = 'EXISTS';
    predicateCopy.eventAttribute = null;
    this.props.handleChangePredicate(predicateCopy);
  };

  deleteInnerPredicate = (innerPredicateIndex, predicateType) => {
    const predicateCopy = cloneDeep(this.props.predicate);
    if (predicateType === this.predicateType.DEVICE) {
      predicateCopy.devicePredicates.splice(innerPredicateIndex, 1);
    } else if (predicateType === this.predicateType.BEHAVIOUR) {
      predicateCopy.eventPredicates.splice(innerPredicateIndex, 1);
    }
    this.props.handleChangePredicate(predicateCopy, true);
  };

  selectValue = (key, value) => {
    const predicate = this.props.predicate;
    const predicateCopy = cloneDeep(predicate);
    predicateCopy[key] = value;
    if (key === 'attribute' || key === 'operator' || key === 'event') {
      predicateCopy.stringValue = null;
      predicateCopy.lessThan = null;
      predicateCopy.greaterThan = null;
      predicateCopy.relativeDateDurationFrom = null;
      predicateCopy.relativeDateDurationTo = null;
      predicateCopy.relativeDateType = null;
      predicateCopy.relativeDateValueFrom = null;
      predicateCopy.relativeDateValueTo = null;
    }
    if (key === 'attribute') {
      predicateCopy.operator = 'IS_NOT_EMPTY';
    }
    if (key === 'event') {
      predicateCopy.eventPredicates = [];
      if (predicate.eventAggregation !== 'NOT_EXISTS') {
        predicateCopy.eventAggregation = 'EXISTS';
      }
      predicateCopy.eventAttribute = null;
      predicateCopy.operator = null;
    }
    this.props.handleChangePredicate(predicateCopy);
  };

  changeRelativeDate = (
    relativeDateDuration,
    relativeDateValue,
    relativeDateType,
    isFrom,
    key,
    value
  ) => {
    const predicateCopy = cloneDeep(this.props.predicate);
    const minutes = getDelayMinutes(relativeDateValue, relativeDateDuration);
    if (relativeDateType === 'LAST-LAST') {
      if (isFrom) {
        predicateCopy.greaterThan = minutes * -1;
      } else {
        predicateCopy.lessThan = minutes * -1;
      }
    }
    if (relativeDateType === 'NEXT-NEXT') {
      if (isFrom) {
        predicateCopy.greaterThan = minutes;
      } else {
        predicateCopy.lessThan = minutes;
      }
    }
    if (relativeDateType === 'LAST-NEXT') {
      if (isFrom) {
        predicateCopy.greaterThan = minutes * -1;
      } else {
        predicateCopy.lessThan = minutes;
      }
    }
    predicateCopy[key] = value;
    this.props.handleChangePredicate(predicateCopy);
  };

  changeRelativeDateEvent = (
    relativeDateDuration,
    relativeDateValue,
    relativeDateType,
    isFrom,
    key,
    value,
    innerPredicateIndex,
    predicateType
  ) => {
    const predicateCopy = cloneDeep(this.props.predicate);
    const minutes = getDelayMinutes(relativeDateValue, relativeDateDuration);
    const innerPredicateType =
      predicateType === this.predicateType.BEHAVIOUR
        ? 'eventPredicates'
        : 'devicePredicates';
    if (relativeDateType === 'LAST-LAST') {
      if (isFrom) {
        predicateCopy[innerPredicateType][innerPredicateIndex].greaterThan =
          minutes * -1;
      } else {
        predicateCopy[innerPredicateType][innerPredicateIndex].lessThan =
          minutes * -1;
      }
    }
    if (relativeDateType === 'NEXT-NEXT') {
      if (isFrom) {
        predicateCopy[innerPredicateType][innerPredicateIndex].greaterThan =
          minutes;
      } else {
        predicateCopy[innerPredicateType][innerPredicateIndex].lessThan =
          minutes;
      }
    }
    if (relativeDateType === 'LAST-NEXT') {
      if (isFrom) {
        predicateCopy[innerPredicateType][innerPredicateIndex].greaterThan =
          minutes * -1;
      } else {
        predicateCopy[innerPredicateType][innerPredicateIndex].lessThan =
          minutes;
      }
    }
    predicateCopy[innerPredicateType][innerPredicateIndex][key] = value;
    this.props.handleChangePredicate(predicateCopy);
  };

  selectInnerPredicateValue = (
    key,
    value,
    innerPredicateIndex,
    predicateType = this.predicateType.BEHAVIOUR,
    selectedItemType = 'STRING'
  ) => {
    const predicateCopy = cloneDeep(this.props.predicate);
    let innerPredicateTitle = null;
    if (predicateType === this.predicateType.BEHAVIOUR) {
      innerPredicateTitle = 'eventPredicates';
    } else if (predicateType === this.predicateType.DEVICE) {
      innerPredicateTitle = 'devicePredicates';
    } else if (predicateType === this.predicateType.ATTRIBUTE) {
      innerPredicateTitle = 'attributePredicates';
    }
    predicateCopy[innerPredicateTitle][innerPredicateIndex][key] = value;
    if (key === 'attribute' || key === 'operator') {
      predicateCopy[innerPredicateTitle][innerPredicateIndex].stringValue =
        null;
      predicateCopy[innerPredicateTitle][innerPredicateIndex].lessThan = null;
      predicateCopy[innerPredicateTitle][innerPredicateIndex].greaterThan =
        null;
    }
    if (key === 'dynamicGreaterThan' || key === 'dynamicLessThan') {
      predicateCopy[innerPredicateTitle][innerPredicateIndex].stringValue =
        null;
      predicateCopy[innerPredicateTitle][innerPredicateIndex].dynamicValue =
        null;
      predicateCopy[innerPredicateTitle][innerPredicateIndex].lessThan = null;
      predicateCopy[innerPredicateTitle][innerPredicateIndex].greaterThan =
        null;
    } else if (key === 'dynamicValue') {
      predicateCopy[innerPredicateTitle][innerPredicateIndex].dynamicLessThan =
        null;
      predicateCopy[innerPredicateTitle][
        innerPredicateIndex
      ].dynamicGreaterThan = null;
      predicateCopy[innerPredicateTitle][innerPredicateIndex].stringValue =
        null;
    } else if (
      key === 'stringValue' ||
      key === 'lessThan' ||
      key === 'greaterThan'
    ) {
      predicateCopy[innerPredicateTitle][innerPredicateIndex].dynamicValue =
        null;
      predicateCopy[innerPredicateTitle][innerPredicateIndex].dynamicLessThan =
        null;
      predicateCopy[innerPredicateTitle][
        innerPredicateIndex
      ].dynamicGreaterThan = null;
    }
    if (key === 'attribute') {
      predicateCopy[innerPredicateTitle][innerPredicateIndex].operator =
        selectedItemType === 'DATE' ? 'IS_NOT_EMPTY' : 'EQUAL_TO';
    }
    this.props.handleChangePredicate(predicateCopy);
  };

  handleResetDatePicker = (predicateType, eventPredicateIndex) => {
    const predicateCopy = cloneDeep(this.props.predicate);
    let innerPredicateTitle = null;
    if (predicateType === this.predicateType.BEHAVIOUR) {
      innerPredicateTitle = 'eventPredicates';
    } else if (predicateType === this.predicateType.DEVICE) {
      innerPredicateTitle = 'devicePredicates';
    }
    if (
      predicateType === this.predicateType.BEHAVIOUR ||
      predicateType === this.predicateType.DEVICE
    ) {
      predicateCopy[innerPredicateTitle][eventPredicateIndex].relativeDateType =
        null;
      predicateCopy[innerPredicateTitle][
        eventPredicateIndex
      ].relativeDateDurationTo = null;
      predicateCopy[innerPredicateTitle][
        eventPredicateIndex
      ].relativeDateDurationFrom = null;
      predicateCopy[innerPredicateTitle][
        eventPredicateIndex
      ].relativeDateValueTo = null;
      predicateCopy[innerPredicateTitle][
        eventPredicateIndex
      ].relativeDateValueFrom = null;
      predicateCopy[innerPredicateTitle][eventPredicateIndex].lessThan = null;
      predicateCopy[innerPredicateTitle][eventPredicateIndex].greaterThan =
        null;
    } else {
      predicateCopy.relativeDateType = null;
      predicateCopy.relativeDateDurationTo = null;
      predicateCopy.relativeDateDurationFrom = null;
      predicateCopy.relativeDateValueTo = null;
      predicateCopy.relativeDateValueFrom = null;
      predicateCopy.lessThan = null;
      predicateCopy.greaterThan = null;
    }
    this.props.handleChangePredicate(predicateCopy);
    if (predicateType !== this.predicateType.ATTRIBUTE) {
      this.props.clearSegmentError(
        this.props.errorsSegmentSubmit.errors,
        this.selectedItemBehaviourDeviceProperties(
          this.props.predicateIndex,
          eventPredicateIndex,
          predicateType,
          'greaterThan'
        )
      );
    } else {
      this.props.clearSegmentError(
        this.props.errorsSegmentSubmit.errors,
        this.selectedItemProperties(this.props.predicateIndex, 'greaterThan')
      );
    }
  };

  handleClickPredicateConjunction = (
    isLast,
    isInnerPredicate,
    predicateType
  ) => {
    if (isLast) {
      if (isInnerPredicate) {
        this.handleAddInnerAttribute(predicateType);
      } else {
        this.props.handleAddNewFilter();
      }
    } else {
      if (isInnerPredicate) {
        if (predicateType === this.predicateType.BEHAVIOUR) {
          const alternateConjunction =
            this.props.predicate.eventConjunction === 'AND' ? 'OR' : 'AND';
          this.selectValue('eventConjunction', alternateConjunction);
        } else if (predicateType === this.predicateType.DEVICE) {
          const alternateConjunction =
            this.props.predicate.deviceConjunction === 'AND' ? 'OR' : 'AND';
          this.selectValue('deviceConjunction', alternateConjunction);
        }
      } else {
        this.props.handleChangeTermConjunction();
      }
    }
  };

  selectedItemProperties = (innerPredicateIndex, errorName) => {
    if (
      this.props.predicate.type === 'ATTRIBUTE' ||
      this.props.predicate.type === 'CHECK_USER_ATTRIBUTE'
    ) {
      return (
        'terms[' +
        [this.props.termsIndex] +
        '].predicates[' +
        [innerPredicateIndex] +
        '].' +
        errorName
      );
    } else {
      return null;
    }
  };

  selectedItemPropertiesAggregation = (innerPredicateIndex, errorName) => {
    return (
      'terms[' +
      [this.props.termsIndex] +
      '].predicates[' +
      [innerPredicateIndex] +
      '].' +
      errorName
    );
  };

  renderOperatorSectorOptionsDynamic = (type, negate = false) => {
    const operatorText = negate ? NotRestrictionOperator : RestrictionOperator;
    return (
      <React.Fragment>
        {type !== 'DATE' ? (
          <Select.Option value={'EQUAL_TO'}>
            {operatorText['EQUAL_TO']}
          </Select.Option>
        ) : null}

        {type === 'STRING' && (
          <Select.Option value={'STARTS_WITH'}>
            {operatorText['STARTS_WITH']}
          </Select.Option>
        )}
        {type === 'STRING' && (
          <Select.Option value={'ENDS_WITH'}>
            {operatorText['ENDS_WITH']}
          </Select.Option>
        )}
        {type === 'STRING' && (
          <Select.Option value={'CONTAINS'}>
            {operatorText['CONTAINS']}
          </Select.Option>
        )}
        {(type === 'DATE' || type === 'NUMERIC') && (
          <Select.Option value={'BETWEEN'}>
            {operatorText['BETWEEN']}
          </Select.Option>
        )}
      </React.Fragment>
    );
  };

  renderOperatorSectorOptions = (type, negate = false, aggregation = false) => {
    const operatorText = negate ? NotRestrictionOperator : RestrictionOperator;
    return (
      <React.Fragment>
        {type !== 'DATE' ? (
          <Select.Option value={'EQUAL_TO'}>
            {operatorText['EQUAL_TO']}
          </Select.Option>
        ) : null}
        {!aggregation ? (
          <Select.Option value={'IS_NOT_EMPTY'}>
            {operatorText['IS_NOT_EMPTY']}
          </Select.Option>
        ) : (
          ''
        )}
        {(type === 'STRING' || type === 'NUMERIC') &&
          this.props?.predicate?.eventAggregation !== 'OCCURRENCE' && (
            <Select.Option value={'ONE_OF'}>
              {operatorText['ONE_OF']}
            </Select.Option>
          )}
        {type === 'STRING' && (
          <Select.Option value={'STARTS_WITH'}>
            {operatorText['STARTS_WITH']}
          </Select.Option>
        )}
        {type === 'STRING' && (
          <Select.Option value={'ENDS_WITH'}>
            {operatorText['ENDS_WITH']}
          </Select.Option>
        )}
        {type === 'STRING' && !this.props.deepInIntegration && (
          <Select.Option value={'MATCHES_REGEX'}>
            {operatorText['MATCHES_REGEX']}
          </Select.Option>
        )}
        {type === 'STRING' && (
          <Select.Option value={'CONTAINS'}>
            {operatorText['CONTAINS']}
          </Select.Option>
        )}
        {(type === 'DATE' || type === 'NUMERIC') && (
          <Select.Option value={'BETWEEN'}>
            {operatorText['BETWEEN']}
          </Select.Option>
        )}
        {type === 'DATE' && (
          <Select.Option value={'DATE_EQUALS'}>
            {operatorText['DATE_EQUALS']}
          </Select.Option>
        )}
      </React.Fragment>
    );
  };

  renderOperatorSelector = (
    attribute,
    operator,
    list,
    predicateType,
    negate,
    innerPredicateIndex = null
  ) => {
    const foundItem = lodashFind(list, (o) => o.id === attribute);
    const type = foundItem && foundItem.type ? foundItem.type : null;

    return (
      <Form.Item
        validateStatus={
          errorHandling(
            this.props.errorsSegmentSubmit,
            predicateType === this.predicateType.DEVICE
              ? this.selectedItemBehaviourDeviceProperties(
                  this.props.predicateIndex,
                  innerPredicateIndex,
                  predicateType,
                  'operator'
                )
              : this.selectedItemProperties(innerPredicateIndex, 'operator')
          )
            ? 'error'
            : undefined
        }
        help={errorHandling(
          this.props.errorsSegmentSubmit,
          predicateType === this.predicateType.DEVICE
            ? this.selectedItemBehaviourDeviceProperties(
                this.props.predicateIndex,
                innerPredicateIndex,
                predicateType,
                'operator'
              )
            : this.selectedItemProperties(innerPredicateIndex, 'operator')
        )}
        id={
          errorHandling(
            this.props.errorsSegmentSubmit,
            predicateType === this.predicateType.DEVICE
              ? this.selectedItemBehaviourDeviceProperties(
                  this.props.predicateIndex,
                  innerPredicateIndex,
                  predicateType,
                  'operator'
                )
              : this.selectedItemProperties(innerPredicateIndex, 'operator')
          )
            ? 'error'
            : null
        }
        // rules={[{ required: true, message: '' }]}
        // name={'operatorSelector_' + innerPredicateIndex}
      >
        <Select
          disabled={this.props.readOnly}
          placeholder="Select an Operator"
          style={{ width: 160 }}
          value={operator}
          onChange={(value) => {
            if (
              predicateType === this.predicateType.BEHAVIOUR ||
              predicateType === this.predicateType.DEVICE
            ) {
              if (predicateType === this.predicateType.DEVICE) {
                this.props.clearSegmentError(
                  this.props.errorsSegmentSubmit.errors,
                  [
                    this.selectedItemBehaviourDeviceProperties(
                      this.props.predicateIndex,
                      innerPredicateIndex,
                      predicateType,
                      'stringValue'
                    ),
                    this.selectedItemBehaviourDeviceProperties(
                      this.props.predicateIndex,
                      innerPredicateIndex,
                      predicateType,
                      'greaterThan'
                    ),
                    this.selectedItemBehaviourDeviceProperties(
                      this.props.predicateIndex,
                      innerPredicateIndex,
                      predicateType,
                      'operator'
                    ),
                  ]
                );
              }
              this.selectInnerPredicateValue(
                'operator',
                value,
                innerPredicateIndex,
                predicateType
              );
            } else if (predicateType === this.predicateType.ATTRIBUTE) {
              this.props.clearSegmentError(
                this.props.errorsSegmentSubmit?.errors,
                [
                  this.selectedItemProperties(
                    innerPredicateIndex,
                    'stringValue'
                  ),
                  this.selectedItemProperties(
                    innerPredicateIndex,
                    'greaterThan'
                  ),
                  this.selectedItemProperties(innerPredicateIndex, 'operator'),
                ]
              );
              this.selectValue('operator', value);
            }
          }}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    marginRight: 10,
                    fontSize: 14,
                    fontWeight: 600,
                    color: '#1c1c1c',
                  }}
                >
                  Negate Mode:
                </div>
                <div>
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(value) => {
                      if (
                        predicateType === this.predicateType.BEHAVIOUR ||
                        predicateType === this.predicateType.DEVICE
                      ) {
                        this.selectInnerPredicateValue(
                          'negate',
                          value,
                          innerPredicateIndex,
                          predicateType
                        );
                      } else if (
                        predicateType === this.predicateType.ATTRIBUTE
                      ) {
                        this.selectValue('negate', value);
                      }
                      if (this.props.clearSegmentError) {
                        this.props.clearSegmentError(
                          this.props.errorsSegmentSubmit?.errors,
                          this.selectedItemProperties(
                            innerPredicateIndex,
                            'stringValue'
                          )
                        );
                        this.props.clearSegmentError(
                          this.props.errorsSegmentSubmit?.errors,
                          this.selectedItemProperties(
                            innerPredicateIndex,
                            'greaterThan'
                          )
                        );
                        this.props.clearSegmentError(
                          this.props.errorsSegmentSubmit?.errors,
                          this.selectedItemProperties(
                            innerPredicateIndex,
                            'operator'
                          )
                        );
                      }
                    }}
                    checked={negate}
                  />
                </div>
              </div>
            </div>
          )}
        >
          {this.props.dynamicConversion
            ? this.state.isDynumic[innerPredicateIndex]
              ? this.renderOperatorSectorOptionsDynamic(type, negate)
              : this.renderOperatorSectorOptions(type, negate)
            : this.renderOperatorSectorOptions(type, negate)}
        </Select>
      </Form.Item>
    );
  };

  renderDatePickerBetween = (
    list,
    attribute,
    operator,
    relativeDateType,
    relativeDateValueTo,
    relativeDateDurationTo,
    relativeDateValueFrom,
    relativeDateDurationFrom,
    greaterThan,
    lessThan,
    predicateType,
    innerPredicateIndex
  ) => {
    const foundItem = lodashFind(list, (o) => o.id === attribute);
    const type = foundItem && foundItem.type ? foundItem.type : null;
    // todo relativeDateType === 'NEXT-LAST'
    return (
      <React.Fragment>
        {type === 'DATE' &&
          operator === 'BETWEEN' &&
          (relativeDateType === 'LAST-LAST' ||
            relativeDateType === 'LAST-NEXT' ||
            relativeDateType === 'NEXT-NEXT') && (
            <Form.Item
              style={{ marginLeft: predicateType ? 25 : 0 }}
              validateStatus={
                errorHandling(
                  this.props.errorsSegmentSubmit,
                  predicateType !== this.predicateType.ATTRIBUTE
                    ? this.selectedItemBehaviourDeviceProperties(
                        this.props.predicateIndex,
                        innerPredicateIndex,
                        predicateType,
                        'greaterThan'
                      )
                    : this.selectedItemProperties(
                        this.props.predicateIndex,
                        'greaterThan'
                      )
                )
                  ? 'error'
                  : null
              }
              help={errorHandling(
                this.props.errorsSegmentSubmit,
                predicateType !== this.predicateType.ATTRIBUTE
                  ? this.selectedItemBehaviourDeviceProperties(
                      this.props.predicateIndex,
                      innerPredicateIndex,
                      predicateType,
                      'greaterThan'
                    )
                  : this.selectedItemProperties(
                      this.props.predicateIndex,
                      'greaterThan'
                    )
              )}
            >
              <div
                style={{
                  ...styles.flexRow,
                  minWidth: 500,
                  paddingTop: !predicateType ? 20 : 0,
                  paddingBottom: predicateType ? 0 : 0,
                }}
              >
                <div
                  style={{
                    ...styles.toContent,
                    marginLeft: 0,
                  }}
                >
                  {relativeDateType === 'NEXT-NEXT' ? 'next' : 'last'}
                </div>
                <InputNumber
                  min={0}
                  readOnly={this.props.readOnly}
                  value={
                    !isNil(relativeDateValueFrom)
                      ? Number(relativeDateValueFrom)
                      : null
                  }
                  onChange={(value) => {
                    if (
                      predicateType === this.predicateType.BEHAVIOUR ||
                      predicateType === this.predicateType.DEVICE
                    ) {
                      if (
                        this.props.clearSegmentError &&
                        this.props.errorsSegmentSubmit
                      ) {
                        this.props.clearSegmentError(
                          this.props.errorsSegmentSubmit.errors,
                          this.selectedItemBehaviourDeviceProperties(
                            this.props.predicateIndex,
                            innerPredicateIndex,
                            predicateType,
                            'greaterThan'
                          )
                        );
                      }
                      this.changeRelativeDateEvent(
                        relativeDateDurationFrom,
                        value,
                        relativeDateType,
                        true,
                        'relativeDateValueFrom',
                        value,
                        innerPredicateIndex,
                        predicateType
                      );
                    } else {
                      if (
                        this.props.clearSegmentError &&
                        this.props.errorsSegmentSubmit
                      ) {
                        this.props.clearSegmentError(
                          this.props.errorsSegmentSubmit.errors,
                          this.selectedItemProperties(
                            this.props.predicateIndex,
                            'greaterThan'
                          )
                        );
                      }
                      this.changeRelativeDate(
                        relativeDateDurationFrom,
                        value,
                        relativeDateType,
                        true,
                        'relativeDateValueFrom',
                        value
                      );
                    }
                  }}
                  style={{
                    width: 80,
                    height: 32,
                    marginRight: 10,
                  }}
                />
                <Select
                  disabled={this.props.readOnly}
                  style={{ width: 100 }}
                  value={relativeDateDurationFrom}
                  placeholder={'Select...'}
                  onChange={(value) => {
                    if (
                      predicateType === this.predicateType.BEHAVIOUR ||
                      predicateType === this.predicateType.DEVICE
                    ) {
                      this.changeRelativeDateEvent(
                        value,
                        relativeDateValueFrom,
                        relativeDateType,
                        true,
                        'relativeDateDurationFrom',
                        String(value),
                        innerPredicateIndex,
                        predicateType
                      );
                      if (
                        this.props.clearSegmentError &&
                        this.props.errorsSegmentSubmit
                      ) {
                        this.props.clearSegmentError(
                          this.props.errorsSegmentSubmit.errors,
                          this.selectedItemBehaviourDeviceProperties(
                            this.props.predicateIndex,
                            innerPredicateIndex,
                            predicateType,
                            'greaterThan'
                          )
                        );
                      }
                    } else {
                      this.changeRelativeDate(
                        value,
                        relativeDateValueFrom,
                        relativeDateType,
                        true,
                        'relativeDateDurationFrom',
                        String(value)
                      );
                      if (
                        this.props.clearSegmentError &&
                        this.props.errorsSegmentSubmit
                      ) {
                        this.props.clearSegmentError(
                          this.props.errorsSegmentSubmit.errors,
                          this.selectedItemProperties(
                            this.props.predicateIndex,
                            'greaterThan'
                          )
                        );
                      }
                    }
                  }}
                >
                  <Select.Option value={'MINUTE'}>Minutes</Select.Option>
                  <Select.Option value={'HOUR'}>Hours</Select.Option>
                  <Select.Option value={'DAY'}>Days</Select.Option>
                  <Select.Option value={'WEEK'}>Weeks</Select.Option>
                  {/*<Select.Option value={'MONTH'}>Months</Select.Option>*/}
                  <Select.Option value={'YEAR'}>Year</Select.Option>
                </Select>
                <div style={styles.toContent}>to</div>
                <div
                  style={{
                    ...styles.toContent,
                    marginLeft: 0,
                  }}
                >
                  {relativeDateType === 'LAST-LAST' ? 'last' : 'next'}
                </div>
                <InputNumber
                  min={0}
                  readOnly={this.props.readOnly}
                  value={
                    !isNil(relativeDateValueTo)
                      ? Number(relativeDateValueTo)
                      : null
                  }
                  onChange={(value) => {
                    if (
                      predicateType === this.predicateType.BEHAVIOUR ||
                      predicateType === this.predicateType.DEVICE
                    ) {
                      if (
                        this.props.clearSegmentError &&
                        this.props.errorsSegmentSubmit
                      ) {
                        this.props.clearSegmentError(
                          this.props.errorsSegmentSubmit.errors,
                          this.selectedItemBehaviourDeviceProperties(
                            this.props.predicateIndex,
                            innerPredicateIndex,
                            predicateType,
                            'greaterThan'
                          )
                        );
                      }
                      this.changeRelativeDateEvent(
                        relativeDateDurationTo,
                        value,
                        relativeDateType,
                        false,
                        'relativeDateValueTo',
                        value,
                        innerPredicateIndex,
                        predicateType
                      );
                    } else {
                      if (
                        this.props.clearSegmentError &&
                        this.props.errorsSegmentSubmit
                      ) {
                        this.props.clearSegmentError(
                          this.props.errorsSegmentSubmit.errors,
                          this.selectedItemProperties(
                            this.props.predicateIndex,
                            'greaterThan'
                          )
                        );
                      }
                      this.changeRelativeDate(
                        relativeDateDurationTo,
                        value,
                        relativeDateType,
                        false,
                        'relativeDateValueTo',
                        value
                      );
                    }
                  }}
                  style={{
                    width: 80,
                    height: 32,
                    marginRight: 10,
                  }}
                />
                <Select
                  disabled={this.props.readOnly}
                  style={{ width: 100 }}
                  value={relativeDateDurationTo}
                  placeholder={'Select...'}
                  onChange={(value) => {
                    if (
                      predicateType === this.predicateType.BEHAVIOUR ||
                      predicateType === this.predicateType.DEVICE
                    ) {
                      this.changeRelativeDateEvent(
                        value,
                        relativeDateValueTo,
                        relativeDateType,
                        false,
                        'relativeDateDurationTo',
                        String(value),
                        innerPredicateIndex,
                        predicateType
                      );
                      if (
                        this.props.clearSegmentError &&
                        this.props.errorsSegmentSubmit
                      ) {
                        this.props.clearSegmentError(
                          this.props.errorsSegmentSubmit.errors,
                          this.selectedItemBehaviourDeviceProperties(
                            this.props.predicateIndex,
                            innerPredicateIndex,
                            predicateType,
                            'greaterThan'
                          )
                        );
                      }
                    } else {
                      this.changeRelativeDate(
                        value,
                        relativeDateValueTo,
                        relativeDateType,
                        false,
                        'relativeDateDurationTo',
                        String(value)
                      );
                      if (
                        this.props.clearSegmentError &&
                        this.props.errorsSegmentSubmit
                      ) {
                        this.props.clearSegmentError(
                          this.props.errorsSegmentSubmit.errors,
                          this.selectedItemProperties(
                            this.props.predicateIndex,
                            'greaterThan'
                          )
                        );
                      }
                    }
                  }}
                >
                  <Select.Option value={'MINUTE'}>Minutes</Select.Option>
                  <Select.Option value={'HOUR'}>Hours</Select.Option>
                  <Select.Option value={'DAY'}>Days</Select.Option>
                  <Select.Option value={'WEEK'}>Weeks</Select.Option>
                  {/*<Select.Option value={'MONTH'}>Months</Select.Option>*/}
                  <Select.Option value={'YEAR'}>Year</Select.Option>
                </Select>
                {!this.props.readOnly && (
                  <div
                    style={{
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                    onClick={() =>
                      this.handleResetDatePicker(
                        predicateType,
                        innerPredicateIndex
                      )
                    }
                  >
                    <i className="fl-sync fl-dark" style={{ fontSize: 34 }}></i>
                  </div>
                )}
              </div>
            </Form.Item>
          )}
      </React.Fragment>
    );
  };

  renderDatePicker = (
    type,
    operator,
    relativeDateType,
    relativeDateValueTo,
    relativeDateDurationTo,
    relativeDateValueFrom,
    relativeDateDurationFrom,
    greaterThan,
    lessThan,
    predicateType,
    innerPredicateIndex
  ) => {
    return (
      <React.Fragment>
        {type === 'DATE' &&
          operator === 'BETWEEN' &&
          isNil(relativeDateType) && (
            <div style={styles.flexRow}>
              <Select
                disabled={this.props.readOnly}
                style={{ width: 200 }}
                value={relativeDateType}
                placeholder={'Select...'}
                onChange={(value) => {
                  if (
                    predicateType === this.predicateType.BEHAVIOUR ||
                    predicateType === this.predicateType.DEVICE
                  ) {
                    if (
                      this.props.errorsSegmentSubmit &&
                      this.props.clearSegmentError
                    ) {
                      this.props.clearSegmentError(
                        this.props.errorsSegmentSubmit.errors,
                        this.selectedItemBehaviourDeviceProperties(
                          this.props.predicateIndex,
                          innerPredicateIndex,
                          predicateType,
                          'greaterThan'
                        )
                      );
                    }
                    this.selectInnerPredicateValue(
                      'relativeDateType',
                      value,
                      innerPredicateIndex,
                      predicateType
                    );
                  } else {
                    if (
                      this.props.clearSegmentError &&
                      this.props.errorsSegmentSubmit
                    ) {
                      this.props.clearSegmentError(
                        this.props.errorsSegmentSubmit.errors,
                        this.selectedItemProperties(
                          this.props.predicateIndex,
                          'greaterThan'
                        )
                      );
                    }
                    this.selectValue('relativeDateType', value);
                  }
                }}
              >
                <Select.Option value={'DATE'}>{'date'}</Select.Option>
                <Select.Option value={'LAST-LAST'}>
                  {'LAST X time to Y time'}
                </Select.Option>
                <Select.Option value={'NEXT-NEXT'}>
                  {'NEXT X time to Y time'}
                </Select.Option>
                <Select.Option value={'LAST-NEXT'}>
                  {'LAST X time to NEXT Y time'}
                </Select.Option>
              </Select>
            </div>
          )}
        {type === 'DATE' &&
          operator === 'BETWEEN' &&
          relativeDateType === 'DATE' && (
            <div style={styles.flexRow}>
              <DatePicker
                placeholder={'Start Date (optional)'}
                allowClear={true}
                disabled={this.props.readOnly}
                disabledDate={(value) => {
                  if (!isNil(lessThan)) {
                    return value >= moment(lessThan);
                  }
                }}
                disabledTime={(currentDate) => {
                  if (!isNil(lessThan)) {
                    return {
                      disabledHours: () =>
                        (currentDate && !currentDate.isSame(lessThan, 'day')) ||
                        (moment(lessThan).hour() === 0 &&
                          moment(lessThan).minute() === 0)
                          ? range(24, 24)
                          : range(moment(lessThan).hour() + 1, 24),
                      disabledMinutes: () =>
                        (currentDate && !currentDate.isSame(lessThan, 'day')) ||
                        (moment(lessThan).hour() === 0 &&
                          moment(lessThan).minute() === 0)
                          ? range(60, 60)
                          : range(moment(lessThan).minute(), 60),
                    };
                  }
                }}
                value={!isNil(greaterThan) ? moment(greaterThan) : null}
                showTime={{ format: 'HH:mm' }}
                style={{ width: 180 }}
                onChange={(value) => {
                  if (
                    predicateType === this.predicateType.BEHAVIOUR ||
                    predicateType === this.predicateType.DEVICE
                  ) {
                    if (
                      this.props.errorsSegmentSubmit &&
                      this.props.clearSegmentError
                    ) {
                      this.props.clearSegmentError(
                        this.props.errorsSegmentSubmit.errors,
                        this.selectedItemBehaviourDeviceProperties(
                          this.props.predicateIndex,
                          innerPredicateIndex,
                          predicateType,
                          'greaterThan'
                        )
                      );
                    }
                    this.selectInnerPredicateValue(
                      'greaterThan',
                      value.startOf('minute').toISOString(),
                      innerPredicateIndex,
                      predicateType
                    );
                  } else {
                    if (
                      this.props.errorsSegmentSubmit &&
                      this.props.clearSegmentError
                    ) {
                      this.props.clearSegmentError(
                        this.props.errorsSegmentSubmit.errors,
                        this.selectedItemProperties(
                          this.props.predicateIndex,
                          'greaterThan'
                        )
                      );
                    }
                    this.selectValue(
                      'greaterThan',
                      value ? value.startOf('minute').toISOString() : null
                    );
                  }
                }}
              />
              <div style={styles.toContent}>to</div>
              <DatePicker
                placeholder={'End Date (optional)'}
                allowClear={true}
                disabled={this.props.readOnly}
                disabledDate={(value) => {
                  if (!isNil(greaterThan)) {
                    return value <= moment(greaterThan);
                  }
                }}
                disabledTime={(currentDate) => {
                  if (!isNil(greaterThan)) {
                    return {
                      disabledHours: () =>
                        (currentDate &&
                          !currentDate.isSame(greaterThan, 'day')) ||
                        (moment(greaterThan).hour() === 23 &&
                          moment(greaterThan).minute() === 59)
                          ? null
                          : range(0, moment(greaterThan).hour()),
                      disabledMinutes: () =>
                        (currentDate &&
                          !currentDate.isSame(greaterThan, 'day')) ||
                        (moment(greaterThan).hour() === 23 &&
                          moment(greaterThan).minute() === 59)
                          ? null
                          : range(0, moment(greaterThan).minute() + 1),
                    };
                  }
                }}
                value={!isNil(lessThan) ? moment(lessThan) : null}
                showTime={{ format: 'HH:mm' }}
                style={{ width: 180 }}
                onChange={(value) => {
                  if (
                    predicateType === this.predicateType.BEHAVIOUR ||
                    predicateType === this.predicateType.DEVICE
                  ) {
                    if (
                      this.props.errorsSegmentSubmit &&
                      this.props.clearSegmentError
                    ) {
                      this.props.clearSegmentError(
                        this.props.errorsSegmentSubmit.errors,
                        this.selectedItemBehaviourDeviceProperties(
                          this.props.predicateIndex,
                          innerPredicateIndex,
                          predicateType,
                          'greaterThan'
                        )
                      );
                    }
                    this.selectInnerPredicateValue(
                      'lessThan',
                      value.startOf('minute').toISOString(),
                      innerPredicateIndex,
                      predicateType
                    );
                  } else {
                    if (
                      this.props.errorsSegmentSubmit &&
                      this.props.clearSegmentError
                    ) {
                      this.props.clearSegmentError(
                        this.props.errorsSegmentSubmit.errors,
                        this.selectedItemProperties(
                          this.props.predicateIndex,
                          'greaterThan'
                        )
                      );
                    }
                    this.selectValue(
                      'lessThan',
                      value ? value.startOf('minute').toISOString() : null
                    );
                  }
                }}
              />
              <div
                style={{ cursor: 'pointer', marginLeft: 5 }}
                onClick={() =>
                  this.handleResetDatePicker(predicateType, innerPredicateIndex)
                }
              >
                <i className="fl-sync fl-dark" style={{ fontSize: 34 }}></i>
              </div>
            </div>
          )}
      </React.Fragment>
    );
  };

  renderPredicateValue = (
    attribute,
    operator,
    list,
    greaterThan,
    lessThan,
    stringValue,
    predicateType,
    innerPredicateIndex = null,
    relativeDateType = null,
    relativeDateValueTo,
    relativeDateDurationTo,
    relativeDateValueFrom,
    relativeDateDurationFrom,
    predicateIndex
  ) => {
    let recommendedAttributes;
    if (predicateType === 'ATTRIBUTE') {
      recommendedAttributes = this.props.recommendUserAttributes;
    } else if (predicateType === 'BEHAVIOUR') {
      recommendedAttributes = this.props.recommendEventAttributes;
    } else if (predicateType === 'DEVICE') {
      recommendedAttributes = this.props.recommendDeviceAttributes;
    }

    const foundItem = lodashFind(list, (o) => o.id === attribute);
    const type = foundItem && foundItem.type ? foundItem.type : null;
    const readOnly = this.props.readOnly;
    const recommendedOptionAttributes = [];
    if (
      recommendedAttributes &&
      recommendedAttributes[attribute] &&
      recommendedAttributes[attribute].length > 0
    ) {
      recommendedAttributes[attribute]
        .filter((el) => !isEmpty(el))
        .map((x, index) =>
          recommendedOptionAttributes.push(
            <Select.Option key={index} value={x}>
              {x}
            </Select.Option>
          )
        );
    }
    const regex = /^-?([0-9]*\.?[0-9]*|\.[0-9]+)$/;
    return (
      <React.Fragment>
        {type === 'STRING' &&
          (operator === 'EQUAL_TO' ||
            operator === 'STARTS_WITH' ||
            operator === 'ENDS_WITH' ||
            operator === 'MATCHES_REGEX') && (
            // <Form.Item
            //   rules={[{ required: true }]}
            //   name={`predicateValue_${predicateIndex}`}
            // >
            <AutoComplete
              value={stringValue || ''}
              searchValue={stringValue || ''}
              onChange={(value) => {
                if (
                  predicateType === this.predicateType.BEHAVIOUR ||
                  predicateType === this.predicateType.DEVICE
                ) {
                  if (
                    this.props.clearSegmentError &&
                    this.props.errorsSegmentSubmit
                  ) {
                    this.props.clearSegmentError(
                      this.props.errorsSegmentSubmit.errors,
                      this.selectedItemBehaviourDeviceProperties(
                        predicateIndex,
                        innerPredicateIndex,
                        predicateType,
                        'stringValue'
                      )
                    );
                  }
                  this.selectInnerPredicateValue(
                    'stringValue',
                    value,
                    innerPredicateIndex,
                    predicateType
                  );
                } else {
                  if (
                    this.props.clearSegmentError &&
                    this.props.errorsSegmentSubmit
                  ) {
                    this.props.clearSegmentError(
                      this.props.errorsSegmentSubmit.errors,
                      this.selectedItemProperties(predicateIndex, 'stringValue')
                    );
                  }
                  this.selectValue('stringValue', value);
                }
              }}
              placeholder="Value"
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
              style={{ width: 180 }}
            >
              {recommendedOptionAttributes}
            </AutoComplete>
            // </Form.Item>
          )}
        {(type === 'STRING' || type === 'NUMERIC') &&
          (operator === 'ONE_OF' || operator === 'CONTAINS') && (
            <Form.Item
              validateStatus={
                errorHandling(
                  this.props.errorsSegmentSubmit,
                  predicateType !== this.predicateType.ATTRIBUTE
                    ? this.selectedItemBehaviourDeviceProperties(
                        predicateIndex,
                        innerPredicateIndex,
                        predicateType,
                        'stringValue'
                      )
                    : this.selectedItemProperties(predicateIndex, 'stringValue')
                )
                  ? 'error'
                  : null
              }
              help={errorHandling(
                this.props.errorsSegmentSubmit,
                predicateType !== this.predicateType.ATTRIBUTE
                  ? this.selectedItemBehaviourDeviceProperties(
                      predicateIndex,
                      innerPredicateIndex,
                      predicateType,
                      'stringValue'
                    )
                  : this.selectedItemProperties(predicateIndex, 'stringValue')
              )}
              id={
                errorHandling(
                  this.props.errorsSegmentSubmit,
                  predicateType !== this.predicateType.ATTRIBUTE
                    ? this.selectedItemBehaviourDeviceProperties(
                        predicateIndex,
                        innerPredicateIndex,
                        predicateType,
                        'stringValue'
                      )
                    : this.selectedItemProperties(predicateIndex, 'stringValue')
                )
                  ? 'stringValue'
                  : null
              }
            >
              <Select
                disabled={readOnly}
                value={this.getMultipleStringValue(stringValue)}
                onChange={(value) => {
                  let filteredValue = value.filter((item) =>
                    numberValidation(item)
                  );
                  if (
                    predicateType === this.predicateType.BEHAVIOUR ||
                    predicateType === this.predicateType.DEVICE
                  ) {
                    if (type === 'NUMERIC') {
                      this.selectInnerPredicateValue(
                        'stringValue',
                        filteredValue.join(),
                        innerPredicateIndex,
                        predicateType
                      );
                    } else {
                      this.selectInnerPredicateValue(
                        'stringValue',
                        value.join(),
                        innerPredicateIndex,
                        predicateType
                      );
                    }
                    if (
                      this.props.clearSegmentError &&
                      this.props.errorsSegmentSubmit
                    ) {
                      this.props.clearSegmentError(
                        this.props.errorsSegmentSubmit.errors,
                        this.selectedItemBehaviourDeviceProperties(
                          predicateIndex,
                          innerPredicateIndex,
                          predicateType,
                          'stringValue'
                        )
                      );
                    }
                  } else {
                    if (type === 'NUMERIC') {
                      this.selectValue('stringValue', filteredValue.join());
                    } else {
                      this.selectValue('stringValue', value.join());
                    }
                    if (
                      this.props.clearSegmentError &&
                      this.props.errorsSegmentSubmit
                    ) {
                      this.props.clearSegmentError(
                        this.props.errorsSegmentSubmit.errors,
                        this.selectedItemProperties(
                          predicateIndex,
                          'stringValue'
                        )
                      );
                    }
                  }
                }}
                mode="tags"
                style={{ width: 180 }}
              >
                {recommendedOptionAttributes}
              </Select>
            </Form.Item>
          )}
        {type === 'NUMERIC' && operator === 'EQUAL_TO' && (
          <Form.Item
            validateStatus={
              errorHandling(
                this.props.errorsSegmentSubmit,
                predicateType !== this.predicateType.ATTRIBUTE
                  ? this.selectedItemBehaviourDeviceProperties(
                      predicateIndex,
                      innerPredicateIndex,
                      predicateType,
                      'stringValue'
                    )
                  : this.selectedItemProperties(predicateIndex, 'stringValue')
              )
                ? 'error'
                : null
            }
            help={errorHandling(
              this.props.errorsSegmentSubmit,
              predicateType !== this.predicateType.ATTRIBUTE
                ? this.selectedItemBehaviourDeviceProperties(
                    predicateIndex,
                    innerPredicateIndex,
                    predicateType,
                    'stringValue'
                  )
                : this.selectedItemProperties(predicateIndex, 'stringValue')
            )}
            id={
              errorHandling(
                this.props.errorsSegmentSubmit,
                predicateType !== this.predicateType.ATTRIBUTE
                  ? this.selectedItemBehaviourDeviceProperties(
                      predicateIndex,
                      innerPredicateIndex,
                      predicateType,
                      'stringValue'
                    )
                  : this.selectedItemProperties(predicateIndex, 'stringValue')
              )
                ? 'error'
                : null
            }
          >
            <Tooltip title="Enter Number">
              <AutoComplete
                showSearch
                id={
                  errorHandling(
                    this.props.errorsSegmentSubmit,
                    this.selectedItemProperties(predicateIndex, 'stringValue')
                  )
                    ? 'stringValue'
                    : null
                }
                readOnly={readOnly}
                value={stringValue}
                onChange={(value) => {
                  if (
                    predicateType === this.predicateType.BEHAVIOUR ||
                    predicateType === this.predicateType.DEVICE
                  ) {
                    if (
                      this.props.clearSegmentError &&
                      this.props.errorsSegmentSubmit
                    ) {
                      this.props.clearSegmentError(
                        this.props.errorsSegmentSubmit.errors,
                        this.selectedItemBehaviourDeviceProperties(
                          predicateIndex,
                          innerPredicateIndex,
                          predicateType,
                          'stringValue'
                        )
                      );
                    }
                    if (value === '' || regex.test(value)) {
                      this.selectInnerPredicateValue(
                        'stringValue',
                        value,
                        innerPredicateIndex,
                        predicateType
                      );
                    }
                  } else {
                    if (
                      this.props.clearSegmentError &&
                      this.props.errorsSegmentSubmit
                    ) {
                      this.props.clearSegmentError(
                        this.props.errorsSegmentSubmit.errors,
                        this.selectedItemProperties(
                          predicateIndex,
                          'stringValue'
                        )
                      );
                    }
                    if (value === '' || regex.test(value)) {
                      this.selectValue('stringValue', value);
                    }
                  }
                }}
                style={{ width: 180 }}
              >
                {recommendedOptionAttributes}
              </AutoComplete>
            </Tooltip>
          </Form.Item>
        )}
        {type === 'NUMERIC' && operator === 'BETWEEN' && (
          <Form.Item
            validateStatus={
              errorHandling(
                this.props.errorsSegmentSubmit,
                predicateType !== this.predicateType.ATTRIBUTE
                  ? this.selectedItemBehaviourDeviceProperties(
                      predicateIndex,
                      innerPredicateIndex,
                      predicateType,
                      'greaterThan'
                    )
                  : this.selectedItemProperties(predicateIndex, 'greaterThan')
              )
                ? 'error'
                : null
            }
            help={errorHandling(
              this.props.errorsSegmentSubmit,
              predicateType !== this.predicateType.ATTRIBUTE
                ? this.selectedItemBehaviourDeviceProperties(
                    predicateIndex,
                    innerPredicateIndex,
                    predicateType,
                    'greaterThan'
                  )
                : this.selectedItemProperties(predicateIndex, 'greaterThan')
            )}
            id={
              errorHandling(
                this.props.errorsSegmentSubmit,
                predicateType !== this.predicateType.ATTRIBUTE
                  ? this.selectedItemBehaviourDeviceProperties(
                      predicateIndex,
                      innerPredicateIndex,
                      predicateType,
                      'greaterThan'
                    )
                  : this.selectedItemProperties(predicateIndex, 'greaterThan')
              )
                ? 'error'
                : null
            }
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Tooltip title="Enter Number">
                <AutoComplete
                  id={
                    errorHandling(
                      this.props.errorsSegmentSubmit,
                      this.selectedItemProperties(predicateIndex, 'greaterThan')
                    )
                      ? 'greaterThan'
                      : null
                  }
                  readOnly={readOnly}
                  value={greaterThan}
                  onChange={(value) => {
                    if (
                      predicateType === this.predicateType.BEHAVIOUR ||
                      predicateType === this.predicateType.DEVICE
                    ) {
                      if (value === '' || regex.test(value)) {
                        this.selectInnerPredicateValue(
                          'greaterThan',
                          value,
                          innerPredicateIndex,
                          predicateType
                        );
                      }

                      if (
                        this.props.clearSegmentError &&
                        this.props.errorsSegmentSubmit
                      ) {
                        this.props.clearSegmentError(
                          this.props.errorsSegmentSubmit.errors,
                          this.selectedItemBehaviourDeviceProperties(
                            predicateIndex,
                            innerPredicateIndex,
                            predicateType,
                            'greaterThan'
                          )
                        );
                      }
                    } else {
                      if (value === '' || regex.test(value)) {
                        this.selectValue('greaterThan', value);
                      }
                      if (
                        this.props.clearSegmentError &&
                        this.props.errorsSegmentSubmit
                      ) {
                        this.props.clearSegmentError(
                          this.props.errorsSegmentSubmit.errors,
                          this.selectedItemProperties(
                            predicateIndex,
                            'stringValue'
                          )
                        );
                        this.props.clearSegmentError(
                          this.props.errorsSegmentSubmit.errors,
                          this.selectedItemProperties(
                            predicateIndex,
                            'greaterThan'
                          )
                        );
                      }
                    }
                  }}
                  style={{ width: 180 }}
                >
                  {recommendedOptionAttributes}
                </AutoComplete>
              </Tooltip>

              <div style={styles.toContent}>to</div>
              <Tooltip title="Enter Number">
                <AutoComplete
                  id={
                    errorHandling(
                      this.props.errorsSegmentSubmit,
                      this.selectedItemProperties(predicateIndex, 'stringValue')
                    )
                      ? 'stringValue'
                      : null
                  }
                  readOnly={readOnly}
                  value={lessThan}
                  onChange={(value) => {
                    if (
                      predicateType === this.predicateType.BEHAVIOUR ||
                      predicateType === this.predicateType.DEVICE
                    ) {
                      if (
                        this.props.clearSegmentError &&
                        this.props.errorsSegmentSubmit
                      ) {
                        this.props.clearSegmentError(
                          this.props.errorsSegmentSubmit.errors,
                          this.selectedItemBehaviourDeviceProperties(
                            predicateIndex,
                            innerPredicateIndex,
                            predicateType,
                            'greaterThan'
                          )
                        );
                      }
                      if (value === '' || regex.test(value)) {
                        this.selectInnerPredicateValue(
                          'lessThan',
                          value,
                          innerPredicateIndex,
                          predicateType
                        );
                      }
                    } else {
                      if (value === '' || regex.test(value)) {
                        this.selectValue('lessThan', value);
                      }
                      if (
                        this.props.clearSegmentError &&
                        this.props.errorsSegmentSubmit
                      ) {
                        this.props.clearSegmentError(
                          this.props.errorsSegmentSubmit.errors,
                          this.selectedItemProperties(
                            predicateIndex,
                            'stringValue'
                          )
                        );
                        this.props.clearSegmentError(
                          this.props.errorsSegmentSubmit.errors,
                          this.selectedItemProperties(
                            predicateIndex,
                            'greaterThan'
                          )
                        );
                      }
                    }
                  }}
                  style={{ width: 180 }}
                >
                  {recommendedOptionAttributes}
                </AutoComplete>
              </Tooltip>
            </div>
          </Form.Item>
        )}
        {type === 'DATE' && operator === 'DATE_EQUALS' && (
          <Form.Item
            validateStatus={
              errorHandling(
                this.props.errorsSegmentSubmit,
                predicateType !== this.predicateType.ATTRIBUTE
                  ? this.selectedItemBehaviourDeviceProperties(
                      predicateIndex,
                      innerPredicateIndex,
                      predicateType,
                      'stringValue'
                    )
                  : this.selectedItemProperties(predicateIndex, 'stringValue')
              )
                ? 'error'
                : null
            }
            help={errorHandling(
              this.props.errorsSegmentSubmit,
              predicateType !== this.predicateType.ATTRIBUTE
                ? this.selectedItemBehaviourDeviceProperties(
                    predicateIndex,
                    innerPredicateIndex,
                    predicateType,
                    'stringValue'
                  )
                : this.selectedItemProperties(predicateIndex, 'stringValue')
            )}
            id={
              errorHandling(
                this.props.errorsSegmentSubmit,
                predicateType !== this.predicateType.ATTRIBUTE
                  ? this.selectedItemBehaviourDeviceProperties(
                      predicateIndex,
                      innerPredicateIndex,
                      predicateType,
                      'stringValue'
                    )
                  : this.selectedItemProperties(predicateIndex, 'stringValue')
              )
                ? 'stringValue'
                : null
            }
          >
            <DatePicker
              id={
                errorHandling(
                  this.props.errorsSegmentSubmit,
                  this.selectedItemProperties(predicateIndex, 'stringValue')
                )
                  ? 'stringValue'
                  : null
              }
              disabled={readOnly}
              allowClear={false}
              value={!isNil(stringValue) ? moment(stringValue) : null}
              showTime={false}
              style={{ width: 150 }}
              onChange={(value) => {
                if (
                  predicateType === this.predicateType.BEHAVIOUR ||
                  predicateType === this.predicateType.DEVICE
                ) {
                  if (
                    this.props.clearSegmentError &&
                    this.props.errorsSegmentSubmit
                  ) {
                    this.props.clearSegmentError(
                      this.props.errorsSegmentSubmit.errors,
                      this.selectedItemBehaviourDeviceProperties(
                        predicateIndex,
                        innerPredicateIndex,
                        predicateType,
                        'stringValue'
                      )
                    );
                  }
                  this.selectInnerPredicateValue(
                    'stringValue',
                    moment(value, 'YYYY-MM-DD'),
                    innerPredicateIndex,
                    predicateType
                  );
                } else {
                  if (
                    this.props.clearSegmentError &&
                    this.props.errorsSegmentSubmit
                  ) {
                    this.props.clearSegmentError(
                      this.props.errorsSegmentSubmit.errors,
                      this.selectedItemProperties(predicateIndex, 'stringValue')
                    );
                  }
                  this.selectValue(
                    'stringValue',
                    value ? moment(value).format('YYYY-MM-DD') : null
                  );
                }
              }}
            />
          </Form.Item>
        )}
        {type === 'DATE' &&
          operator === 'BETWEEN' &&
          (isNil(relativeDateType) || relativeDateType === 'DATE') && (
            <Form.Item
              validateStatus={
                errorHandling(
                  this.props.errorsSegmentSubmit,
                  predicateType !== this.predicateType.ATTRIBUTE
                    ? this.selectedItemBehaviourDeviceProperties(
                        predicateIndex,
                        innerPredicateIndex,
                        predicateType,
                        'greaterThan'
                      )
                    : this.selectedItemProperties(predicateIndex, 'greaterThan')
                )
                  ? 'error'
                  : null
              }
              help={errorHandling(
                this.props.errorsSegmentSubmit,
                predicateType !== this.predicateType.ATTRIBUTE
                  ? this.selectedItemBehaviourDeviceProperties(
                      predicateIndex,
                      innerPredicateIndex,
                      predicateType,
                      'greaterThan'
                    )
                  : this.selectedItemProperties(predicateIndex, 'greaterThan')
              )}
              id={
                errorHandling(
                  this.props.errorsSegmentSubmit,
                  predicateType !== this.predicateType.ATTRIBUTE
                    ? this.selectedItemBehaviourDeviceProperties(
                        predicateIndex,
                        innerPredicateIndex,
                        predicateType,
                        'greaterThan'
                      )
                    : this.selectedItemProperties(predicateIndex, 'greaterThan')
                )
                  ? 'error'
                  : null
              }
            >
              {this.renderDatePicker(
                type,
                operator,
                relativeDateType,
                relativeDateValueTo,
                relativeDateDurationTo,
                relativeDateValueFrom,
                relativeDateDurationFrom,
                greaterThan,
                lessThan,
                predicateType,
                innerPredicateIndex
              )}
            </Form.Item>
          )}
        {type === 'BOOLEAN' && operator === 'EQUAL_TO' && (
          <Form.Item
            validateStatus={
              errorHandling(
                this.props.errorsSegmentSubmit,
                predicateType !== this.predicateType.ATTRIBUTE
                  ? this.selectedItemBehaviourDeviceProperties(
                      predicateIndex,
                      innerPredicateIndex,
                      predicateType,
                      'stringValue'
                    )
                  : this.selectedItemProperties(predicateIndex, 'stringValue')
              )
                ? 'error'
                : null
            }
            help={errorHandling(
              this.props.errorsSegmentSubmit,
              predicateType !== this.predicateType.ATTRIBUTE
                ? this.selectedItemBehaviourDeviceProperties(
                    predicateIndex,
                    innerPredicateIndex,
                    predicateType,
                    'stringValue'
                  )
                : this.selectedItemProperties(predicateIndex, 'stringValue')
            )}
            id={
              errorHandling(
                this.props.errorsSegmentSubmit,
                predicateType !== this.predicateType.ATTRIBUTE
                  ? this.selectedItemBehaviourDeviceProperties(
                      predicateIndex,
                      innerPredicateIndex,
                      predicateType,
                      'stringValue'
                    )
                  : this.selectedItemProperties(predicateIndex, 'stringValue')
              )
                ? 'error'
                : null
            }
          >
            <Select
              id={
                errorHandling(
                  this.props.errorsSegmentSubmit,
                  this.selectedItemProperties(predicateIndex, 'stringValue')
                )
                  ? 'stringValue'
                  : null
              }
              disabled={readOnly}
              value={
                stringValue === 'false' || stringValue === false
                  ? false
                  : stringValue === 'true' || stringValue === true
                  ? true
                  : null
              }
              placeholder={'Select a Value'}
              style={{ width: 180 }}
              onChange={(value) => {
                if (
                  predicateType === this.predicateType.BEHAVIOUR ||
                  predicateType === this.predicateType.DEVICE
                ) {
                  if (
                    this.props.clearSegmentError &&
                    this.props.errorsSegmentSubmit
                  ) {
                    this.props.clearSegmentError(
                      this.props.errorsSegmentSubmit.errors,
                      this.selectedItemBehaviourDeviceProperties(
                        predicateIndex,
                        innerPredicateIndex,
                        predicateType,
                        'stringValue'
                      )
                    );
                  }
                  this.selectInnerPredicateValue(
                    'stringValue',
                    String(value),
                    innerPredicateIndex,
                    predicateType
                  );
                } else {
                  if (
                    this.props.clearSegmentError &&
                    this.props.errorsSegmentSubmit
                  ) {
                    this.props.clearSegmentError(
                      this.props.errorsSegmentSubmit.errors,
                      this.selectedItemProperties(predicateIndex, 'stringValue')
                    );
                  }
                  this.selectValue('stringValue', String(value));
                }
              }}
            >
              <Select.Option value={true}>True</Select.Option>
              <Select.Option value={false}>False</Select.Option>
            </Select>
          </Form.Item>
        )}
      </React.Fragment>
    );
  };

  renderDynamicConversionValue = (
    attribute,
    operator,
    list,
    dynamicGreaterThan,
    dynamicLessThan,
    dynamicValue,
    predicateType,
    innerPredicateIndex = null
  ) => {
    const readOnly = this.props.readOnly;
    const foundItem = lodashFind(list, (o) => o.id === attribute);
    const getItem = lodashFind(
      this.props.events,
      (o) => o.id === this.props?.dynamicEvent?.event
    );

    const type = foundItem && foundItem.type ? foundItem.type : null;

    const dynamicValueOptions = getItem?.attributes.filter(
      (item) => item.type === type
    );

    return (
      <>
        {operator !== 'IS_NOT_EMPTY' &&
          (operator === 'BETWEEN' ? (
            <>
              <Select
                disabled={readOnly}
                value={dynamicGreaterThan || ''}
                allowClear
                onChange={(value) => {
                  this.selectInnerPredicateValue(
                    'dynamicGreaterThan',
                    value,
                    innerPredicateIndex,
                    predicateType
                  );
                }}
                style={{ width: 180, float: 'left' }}
              >
                {dynamicValueOptions.map((x) => (
                  <Select.Option key={x.ids} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
              <div style={styles.toContent}>to</div>
              <Select
                disabled={readOnly}
                allowClear
                value={dynamicLessThan || ''}
                onChange={(value) => {
                  this.selectInnerPredicateValue(
                    'dynamicLessThan',
                    value,
                    innerPredicateIndex,
                    predicateType
                  );
                }}
                style={{ width: 180 }}
              >
                {dynamicValueOptions.map((x) => (
                  <Select.Option key={x.ids} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </>
          ) : (
            <Select
              disabled={readOnly}
              value={dynamicValue || ''}
              onChange={(value) => {
                this.selectInnerPredicateValue(
                  'dynamicValue',
                  value,
                  innerPredicateIndex,
                  predicateType
                );
              }}
              style={{ width: 180 }}
            >
              {dynamicValueOptions.map((x) => (
                <Select.Option key={x.ids} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          ))}
      </>
    );
  };

  selectedItemBehaviourDeviceProperties = (
    predicatesIndex,
    innerPredicatesIndex,
    type,
    errorName
  ) => {
    if (
      type === 'BEHAVIOUR' ||
      type === 'HAS_DONE_EVENT' ||
      type === 'EVENT_OCCURRENCE' ||
      type === 'WAIT_FOR_EVENT'
    ) {
      return (
        'terms[' +
        [this.props.termsIndex] +
        '].predicates[' +
        [predicatesIndex] +
        '].' +
        'eventPredicates[' +
        [innerPredicatesIndex] +
        '].' +
        errorName
      );
    } else if (type === this.predicateType.DEVICE) {
      return (
        'terms[' +
        [this.props.termsIndex] +
        '].predicates[' +
        [predicatesIndex] +
        '].' +
        'devicePredicates[' +
        [innerPredicatesIndex] +
        '].' +
        errorName
      );
    } else {
      return null;
    }
  };

  renderUserAttributeSelector = (
    attribute,
    list,
    predicateType,
    innerPredicateIndex = null
  ) => {
    const isChangeInUser = this.props.type === 'CHANGE_IN_USER_ATTRIBUTE';
    return (
      <Form.Item
        validateStatus={
          errorHandling(
            this.props.errorsSegmentSubmit,
            this.selectedItemBehaviourDeviceProperties(
              this.props.predicateIndex,
              innerPredicateIndex,
              predicateType,
              'attribute'
            )
          )
            ? 'error'
            : null
        }
        help={errorHandling(
          this.props.errorsSegmentSubmit,
          this.selectedItemBehaviourDeviceProperties(
            this.props.predicateIndex,
            innerPredicateIndex,
            predicateType,
            'attribute'
          )
        )}
        id={
          errorHandling(
            this.props.errorsSegmentSubmit,
            this.selectedItemBehaviourDeviceProperties(
              this.props.predicateIndex,
              innerPredicateIndex,
              predicateType,
              'attribute'
            )
          )
            ? 'error'
            : null
        }
      >
        <Space>
          {isChangeInUser ? <Space>of</Space> : ''}
          <Select
            filterOption={(input, option) =>
              option.props.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            showSearch
            style={{ width: 180 }}
            value={attribute}
            placeholder={'Select an Attribute'}
            onChange={(value) => {
              const selectedItemType = list.find(
                (item) => item.id === value
              )?.type;
              if (
                predicateType === this.predicateType.ATTRIBUTE &&
                !(value in (this.props?.recommendUserAttributes || []))
              ) {
                if (
                  _.isFunction(this.props?.fetchRecommendUserAttributes) &&
                  (hasAnyAuthority(
                    AuthorityProvider.ROLE_PRODUCT_SEGMENT_CREATE
                  ) ||
                    hasAnyAuthority(
                      AuthorityProvider.ROLE_PRODUCT_SEGMENT_UPDATE
                    ) ||
                    hasAnyAuthority(
                      AuthorityProvider.ROLE_PRODUCT_SEGMENT_REPORT
                    ))
                ) {
                  this.props?.fetchRecommendUserAttributes(value);
                }
              } else if (
                predicateType === this.predicateType.DEVICE &&
                !(value in (this.props?.recommendDeviceAttributes || []))
              ) {
                if (_.isFunction(this.props?.fetchRecommendDeviceAttributes)) {
                  this.props?.fetchRecommendDeviceAttributes(value);
                }
              } else if (
                predicateType === this.predicateType.BEHAVIOUR &&
                !(value in (this.props?.recommendEventAttributes || []))
              ) {
                const updatedIsDynumic = [...this.state.isDynumic];
                updatedIsDynumic[innerPredicateIndex] = false;
                this.setState({ isDynumic: updatedIsDynumic });
                if (_.isFunction(this.props?.fetchRecommendEventAttributes)) {
                  this.props?.fetchRecommendEventAttributes(value);
                }
              }
              if (
                predicateType === this.predicateType.BEHAVIOUR ||
                predicateType === this.predicateType.DEVICE
              ) {
                if (
                  this.props.errorsSegmentSubmit &&
                  this.props.clearSegmentError
                ) {
                  this.props.clearSegmentError(
                    this.props.errorsSegmentSubmit.errors,
                    [
                      this.selectedItemBehaviourDeviceProperties(
                        this.props.predicateIndex,
                        innerPredicateIndex,
                        predicateType,
                        'attribute'
                      ),
                      this.selectedItemBehaviourDeviceProperties(
                        this.props.predicateIndex,
                        innerPredicateIndex,
                        predicateType,
                        'stringValue'
                      ),
                      this.selectedItemBehaviourDeviceProperties(
                        this.props.predicateIndex,
                        innerPredicateIndex,
                        predicateType,
                        'greaterThan'
                      ),
                      this.selectedItemBehaviourDeviceProperties(
                        this.props.predicateIndex,
                        innerPredicateIndex,
                        predicateType,
                        'operator'
                      ),
                    ]
                  );
                }

                this.selectInnerPredicateValue(
                  'attribute',
                  value,
                  innerPredicateIndex,
                  predicateType,
                  selectedItemType
                );
              } else if (predicateType === this.predicateType.ATTRIBUTE) {
                if (
                  this.props.errorsSegmentSubmit &&
                  this.props.clearSegmentError
                ) {
                  this.props.clearSegmentError(
                    this.props.errorsSegmentSubmit.errors,
                    [
                      this.selectedItemProperties(
                        this.props.predicateIndex,
                        'stringValue'
                      ),
                      this.selectedItemProperties(
                        this.props.predicateIndex,
                        'greaterThan'
                      ),
                      this.selectedItemProperties(
                        this.props.predicateIndex,
                        'operator'
                      ),
                    ]
                  );
                }
                this.selectValue('attribute', value);
              }
            }}
            disabled={this.props.readOnly || isChangeInUser}
          >
            {list.map((x) => (
              <Select.Option key={x.id} value={x.id}>
                {x.title}
              </Select.Option>
            ))}
          </Select>
          {isChangeInUser ? <Space>is</Space> : ''}
        </Space>
      </Form.Item>
    );
  };

  renderEventSelector = (event) => {
    return (
      <Select
        filterOption={(input, option) =>
          option.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        showSearch
        value={event}
        style={{ width: 180 }}
        placeholder={'Select an Event'}
        onChange={(value) => {
          this.selectValue('event', value);
        }}
        disabled={this.props.readOnly || this.props.selectEventDisabled}
      >
        {this.props.events.map((x) => (
          <Select.Option key={x.id} value={x.id}>
            {x.title}
          </Select.Option>
        ))}
      </Select>
    );
  };

  renderPredicateConjunctionDivider = (
    isLast,
    conjunction,
    isInnerPredicate,
    predicateType = null
  ) => {
    const isAnd = conjunction === 'AND';
    if (this.props.readOnly && isLast) {
      return null;
    }
    {
      return (
        <div style={styles.predicateConjunctionDividerContent}>
          <div style={styles.predicateConjunctionDividerBorderLeft}></div>
          {isAnd ? (
            <div
              onClick={() => {
                if (!isLast && this.props.deepInIntegration) return;
                this.handleClickPredicateConjunction(
                  isLast,
                  isInnerPredicate,
                  predicateType
                );
              }}
              style={{
                ...styles.conjunctionDividerText,
                border: isLast ? 'solid 1px #11b563' : 'none',
                boxShadow: isLast
                  ? 'none'
                  : '0 1px 6px 0 rgba(17, 181, 99, 0.5)',
                color: isLast ? '#53b16b' : 'white',
                backgroundColor: isLast ? '#fcfcfc' : '#53b16b',
              }}
            >
              {isLast ? '+ AND FILTER' : 'AND'}
            </div>
          ) : (
            <div
              onClick={() => {
                this.handleClickPredicateConjunction(
                  isLast,
                  isInnerPredicate,
                  predicateType
                );
              }}
              style={{
                ...styles.conjunctionDividerText,
                border: isLast ? 'solid 1px #3ec6ff' : 'none',
                boxShadow: isLast
                  ? 'none'
                  : '0 1px 6px 0 rgba(62, 198, 255, 0.5)',
                color: isLast ? '#3ec6ff' : 'white',
                backgroundColor: isLast ? '#fcfcfc' : '#3ec6ff',
              }}
            >
              {isLast ? '+ OR FILTER' : 'OR'}
            </div>
          )}
          <div style={styles.predicateConjunctionDividerBorderRight}></div>
        </div>
      );
    }
  };

  renderAggregationDatePickerBetween = (
    list,
    attribute,
    operator,
    relativeDateType,
    relativeDateValueTo,
    relativeDateDurationTo,
    relativeDateValueFrom,
    relativeDateDurationFrom,
    greaterThan,
    lessThan,
    predicateType,
    innerPredicateIndex
  ) => {
    const foundItem = lodashFind(list, (o) => o.id === attribute);
    const type = foundItem && foundItem.type ? foundItem.type : null;
    // todo relativeDateType === 'NEXT-LAST'
    const greaterThanMessage = errorHandling(
      this.props.errorsSegmentSubmit,
      this.selectedItemPropertiesAggregation(innerPredicateIndex, 'greaterThan')
    );
    const greaterThanTerm = this.selectedItemPropertiesAggregation(
      innerPredicateIndex,
      'greaterThan'
    );
    return (
      <React.Fragment>
        {type === 'DATE' &&
          operator === 'BETWEEN' &&
          (relativeDateType === 'LAST-LAST' ||
            relativeDateType === 'LAST-NEXT' ||
            relativeDateType === 'NEXT-NEXT') && (
            <Form.Item
              style={{ minWidth: 500 }}
              validateStatus={greaterThanMessage ? 'error' : null}
              help={greaterThanMessage}
            >
              <div
                style={{
                  ...styles.flexRow,
                  minWidth: 500,
                }}
              >
                <div
                  style={{
                    ...styles.toContent,
                    marginLeft: 0,
                  }}
                >
                  {relativeDateType === 'NEXT-NEXT' ? 'next' : 'last'}
                </div>
                <InputNumber
                  readOnly={this.props.readOnly}
                  value={
                    !isNil(relativeDateValueFrom)
                      ? Number(relativeDateValueFrom)
                      : null
                  }
                  onChange={(value) => {
                    this.changeRelativeDate(
                      relativeDateDurationFrom,
                      value,
                      relativeDateType,
                      true,
                      'relativeDateValueFrom',
                      value
                    );
                    if (this.props.errorsSegmentSubmit?.errors) {
                      this.props.clearSegmentError(
                        this.props.errorsSegmentSubmit.errors,
                        greaterThanTerm
                      );
                    }
                  }}
                  style={{
                    width: 80,
                    height: 32,
                    marginRight: 10,
                  }}
                />
                <Select
                  disabled={this.props.readOnly}
                  style={{ width: 100 }}
                  value={relativeDateDurationFrom}
                  placeholder={'Select...'}
                  onChange={(value) => {
                    this.changeRelativeDate(
                      value,
                      relativeDateValueFrom,
                      relativeDateType,
                      true,
                      'relativeDateDurationFrom',
                      String(value)
                    );
                    if (this.props.errorsSegmentSubmit?.errors) {
                      this.props.clearSegmentError(
                        this.props.errorsSegmentSubmit.errors,
                        greaterThanTerm
                      );
                    }
                  }}
                >
                  <Select.Option value={'MINUTE'}>Minutes</Select.Option>
                  <Select.Option value={'HOUR'}>Hours</Select.Option>
                  <Select.Option value={'DAY'}>Days</Select.Option>
                  <Select.Option value={'WEEK'}>Weeks</Select.Option>
                  {/*<Select.Option value={'MONTH'}>Months</Select.Option>*/}
                  <Select.Option value={'YEAR'}>Year</Select.Option>
                </Select>
                <div style={styles.toContent}>to</div>
                <div
                  style={{
                    ...styles.toContent,
                    marginLeft: 0,
                  }}
                >
                  {relativeDateType === 'LAST-LAST' ? 'last' : 'next'}
                </div>
                <InputNumber
                  readOnly={this.props.readOnly}
                  value={
                    !isNil(relativeDateValueTo)
                      ? Number(relativeDateValueTo)
                      : null
                  }
                  onChange={(value) => {
                    this.changeRelativeDate(
                      relativeDateDurationTo,
                      value,
                      relativeDateType,
                      false,
                      'relativeDateValueTo',
                      value
                    );
                    if (this.props.errorsSegmentSubmit?.errors) {
                      this.props.clearSegmentError(
                        this.props.errorsSegmentSubmit.errors,
                        greaterThanTerm
                      );
                    }
                  }}
                  style={{
                    width: 80,
                    height: 32,
                    marginRight: 10,
                  }}
                />
                <Select
                  disabled={this.props.readOnly}
                  style={{ width: 100 }}
                  value={relativeDateDurationTo}
                  placeholder={'Select...'}
                  onChange={(value) => {
                    this.changeRelativeDate(
                      value,
                      relativeDateValueTo,
                      relativeDateType,
                      false,
                      'relativeDateDurationTo',
                      String(value)
                    );
                    if (this.props.errorsSegmentSubmit?.errors) {
                      this.props.clearSegmentError(
                        this.props.errorsSegmentSubmit.errors,
                        greaterThanTerm
                      );
                    }
                  }}
                >
                  <Select.Option value={'MINUTE'}>Minutes</Select.Option>
                  <Select.Option value={'HOUR'}>Hours</Select.Option>
                  <Select.Option value={'DAY'}>Days</Select.Option>
                  <Select.Option value={'WEEK'}>Weeks</Select.Option>
                  {/*<Select.Option value={'MONTH'}>Months</Select.Option>*/}
                  <Select.Option value={'YEAR'}>Year</Select.Option>
                </Select>
                {!this.props.readOnly && (
                  <div
                    style={{
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                    onClick={() =>
                      this.handleResetDatePicker(
                        predicateType,
                        innerPredicateIndex
                      )
                    }
                  >
                    <i className="fl-sync fl-dark" style={{ fontSize: 34 }}></i>
                  </div>
                )}
              </div>
            </Form.Item>
          )}
      </React.Fragment>
    );
  };

  renderAggregationDatePicker = (
    type,
    operator,
    relativeDateType,
    relativeDateValueTo,
    relativeDateDurationTo,
    relativeDateValueFrom,
    relativeDateDurationFrom,
    greaterThan,
    lessThan,
    stringValue,
    predicateType,
    innerPredicateIndex
  ) => {
    const greaterThanTerm = this.selectedItemPropertiesAggregation(
      innerPredicateIndex,
      'greaterThan'
    );

    const stringValueTerm = this.selectedItemPropertiesAggregation(
      innerPredicateIndex,
      'stringValue'
    );

    return (
      <React.Fragment>
        {type === 'DATE' &&
          operator === 'BETWEEN' &&
          isNil(relativeDateType) && (
            <div style={styles.flexRow}>
              <Select
                disabled={this.props.readOnly}
                style={{ width: 200 }}
                value={relativeDateType}
                placeholder={'Select...'}
                onChange={(value) => {
                  this.selectValue('relativeDateType', value);
                  if (this.props.errorsSegmentSubmit?.errors) {
                    this.props.clearSegmentError(
                      this.props.errorsSegmentSubmit.errors,
                      greaterThanTerm
                    );
                  }
                }}
              >
                <Select.Option value={'DATE'}>{'date'}</Select.Option>
                <Select.Option value={'LAST-LAST'}>
                  {'LAST X time to Y time'}
                </Select.Option>
                <Select.Option value={'NEXT-NEXT'}>
                  {'NEXT X time to Y time'}
                </Select.Option>
                <Select.Option value={'LAST-NEXT'}>
                  {'LAST X time to NEXT Y time'}
                </Select.Option>
              </Select>
            </div>
          )}
        {operator === 'DATE_EQUALS' && (
          <DatePicker
            placeholder={'Date'}
            allowClear={true}
            showTime={false}
            disabledDate={(value) => {
              if (!isNil(stringValue)) {
                return value >= moment(stringValue);
              }
            }}
            disabledTime={() => {
              if (!isNil(stringValue)) {
                return {
                  disabledHours: () =>
                    moment(stringValue).hour() === 0 &&
                    moment(stringValue).minute() === 0
                      ? range(24, 24)
                      : range(moment(stringValue).hour() + 1, 24),
                  disabledMinutes: () =>
                    moment(stringValue).hour() === 0 &&
                    moment(stringValue).minute() === 0
                      ? range(60, 60)
                      : range(moment(stringValue).minute(), 60),
                };
              }
            }}
            value={!isNil(stringValue) ? moment(stringValue) : null}
            style={{ width: 180 }}
            onChange={(value) => {
              this.selectValue(
                'stringValue',
                value ? value.startOf('minute').toISOString() : null
              );
              if (this.props.errorsSegmentSubmit?.errors) {
                this.props.clearSegmentError(
                  this.props.errorsSegmentSubmit.errors,
                  stringValueTerm
                );
              }
            }}
          />
        )}
        {type === 'DATE' &&
          operator === 'BETWEEN' &&
          relativeDateType === 'DATE' && (
            <div style={styles.flexRow}>
              <DatePicker
                placeholder={'Start Date (optional)'}
                allowClear={true}
                disabled={this.props.readOnly}
                disabledDate={(value) => {
                  if (!isNil(lessThan)) {
                    return value >= moment(lessThan);
                  }
                }}
                disabledTime={() => {
                  if (!isNil(lessThan)) {
                    return {
                      disabledHours: () =>
                        moment(lessThan).hour() === 0 &&
                        moment(lessThan).minute() === 0
                          ? range(24, 24)
                          : range(moment(lessThan).hour() + 1, 24),
                      disabledMinutes: () =>
                        moment(lessThan).hour() === 0 &&
                        moment(lessThan).minute() === 0
                          ? range(60, 60)
                          : range(moment(lessThan).minute(), 60),
                    };
                  }
                }}
                value={!isNil(greaterThan) ? moment(greaterThan) : null}
                showTime={{ format: 'HH:mm' }}
                style={{ width: 180 }}
                onChange={(value) => {
                  this.selectValue(
                    'greaterThan',
                    value ? value.startOf('minute').toISOString() : null
                  );
                  if (this.props.errorsSegmentSubmit?.errors) {
                    this.props.clearSegmentError(
                      this.props.errorsSegmentSubmit.errors,
                      greaterThanTerm
                    );
                  }
                }}
              />
              <div style={styles.toContent}>to</div>
              <DatePicker
                placeholder={'End Date (optional)'}
                allowClear={true}
                disabled={this.props.readOnly}
                disabledDate={(value) => {
                  if (!isNil(greaterThan)) {
                    return value <= moment(greaterThan);
                  }
                }}
                disabledTime={() => {
                  if (!isNil(greaterThan)) {
                    return {
                      disabledHours: () =>
                        moment(greaterThan).hour() === 23 &&
                        moment(greaterThan).minute() === 59
                          ? null
                          : range(0, moment(greaterThan).hour()),
                      disabledMinutes: () =>
                        moment(greaterThan).hour() === 23 &&
                        moment(greaterThan).minute() === 59
                          ? null
                          : range(0, moment(greaterThan).minute() + 1),
                    };
                  }
                }}
                value={!isNil(lessThan) ? moment(lessThan) : null}
                showTime={{ format: 'HH:mm' }}
                style={{ width: 180 }}
                onChange={(value) => {
                  this.selectValue(
                    'lessThan',
                    value ? value.startOf('minute').toISOString() : null
                  );
                  if (this.props.errorsSegmentSubmit?.errors) {
                    this.props.clearSegmentError(
                      this.props.errorsSegmentSubmit.errors,
                      greaterThanTerm
                    );
                  }
                }}
              />
              <div
                style={{ cursor: 'pointer', marginLeft: 5 }}
                onClick={() =>
                  this.handleResetDatePicker(predicateType, innerPredicateIndex)
                }
              >
                <i className="fl-sync fl-dark" style={{ fontSize: 34 }}></i>
              </div>
            </div>
          )}
      </React.Fragment>
    );
  };

  renderAggregationPredicateValue = (
    attribute,
    operator,
    list,
    greaterThan,
    lessThan,
    stringValue,
    predicateType,
    relativeDateType = null,
    relativeDateValueTo,
    relativeDateDurationTo,
    relativeDateValueFrom,
    relativeDateDurationFrom,
    predicateIndex
  ) => {
    const foundItem = lodashFind(list, (o) => o.id === attribute);
    let type =
      foundItem && foundItem.type
        ? foundItem.type
        : this.props.predicate.eventAggregation === 'OCCURRENCE'
        ? 'NUMERIC'
        : null;
    const readOnly = this.props.readOnly;
    const stringValueMessage = errorHandling(
      this.props.errorsSegmentSubmit,
      this.selectedItemPropertiesAggregation(predicateIndex, 'stringValue')
    );
    const greaterThanMessage = errorHandling(
      this.props.errorsSegmentSubmit,
      this.selectedItemPropertiesAggregation(predicateIndex, 'greaterThan')
    );
    const stringValueTerm = this.selectedItemPropertiesAggregation(
      predicateIndex,
      'stringValue'
    );
    const greaterThanTerm = this.selectedItemPropertiesAggregation(
      predicateIndex,
      'greaterThan'
    );
    return (
      <React.Fragment>
        {type === 'NUMERIC' && operator === 'ONE_OF' && (
          <Form.Item
            style={{ float: 'right' }}
            validateStatus={stringValueMessage ? 'error' : null}
            help={stringValueMessage}
          >
            <Select
              disabled={readOnly}
              value={this.getMultipleStringValue(stringValue)}
              onChange={(value) => {
                this.selectValue('stringValue', value.join());
                if (this.props.errorsSegmentSubmit?.errors) {
                  this.props.clearSegmentError(
                    this.props.errorsSegmentSubmit.errors,
                    stringValueTerm
                  );
                }
              }}
              mode="tags"
              style={{ width: 180 }}
            />
          </Form.Item>
        )}
        {type === 'NUMERIC' && operator === 'EQUAL_TO' && (
          <Form.Item
            style={{ float: 'left' }}
            validateStatus={stringValueMessage ? 'error' : null}
            help={stringValueMessage}
          >
            <InputNumber
              readOnly={readOnly}
              value={stringValue}
              onChange={(value) => {
                this.selectValue('stringValue', value);
                if (this.props.errorsSegmentSubmit?.errors) {
                  this.props.clearSegmentError(
                    this.props.errorsSegmentSubmit.errors,
                    stringValueTerm
                  );
                }
              }}
              style={{ width: 180 }}
            />
          </Form.Item>
        )}
        {type === 'NUMERIC' && operator === 'BETWEEN' && (
          <Form.Item
            style={{ float: 'left' }}
            validateStatus={greaterThanMessage ? 'error' : null}
            help={greaterThanMessage}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputNumber
                readOnly={readOnly}
                value={greaterThan}
                onChange={(value) => {
                  this.selectValue('greaterThan', value);
                  if (this.props.errorsSegmentSubmit?.errors) {
                    this.props.clearSegmentError(
                      this.props.errorsSegmentSubmit.errors,
                      greaterThanTerm
                    );
                  }
                }}
                style={{ width: 140 }}
              />
              <div style={styles.toContent}>to</div>
              <InputNumber
                readOnly={readOnly}
                value={lessThan}
                onChange={(value) => {
                  this.selectValue('lessThan', value);
                  if (this.props.errorsSegmentSubmit?.errors) {
                    this.props.clearSegmentError(
                      this.props.errorsSegmentSubmit.errors,
                      greaterThanTerm
                    );
                  }
                }}
                style={{ width: 140 }}
              />
            </div>
          </Form.Item>
        )}

        {type === 'DATE' &&
          ['DATE_EQUALS', 'BETWEEN'].includes(operator) &&
          (isNil(relativeDateType) || relativeDateType === 'DATE') && (
            <Form.Item
              validateStatus={greaterThanMessage ? 'error' : null}
              help={greaterThanMessage}
            >
              {this.renderAggregationDatePicker(
                type,
                operator,
                relativeDateType,
                relativeDateValueTo,
                relativeDateDurationTo,
                relativeDateValueFrom,
                relativeDateDurationFrom,
                greaterThan,
                lessThan,
                stringValue,
                predicateType,
                this.props.predicateIndex
              )}
            </Form.Item>
          )}
      </React.Fragment>
    );
  };

  renderAggregationOperatorSelector = (
    attributeId,
    list,
    operator,
    negate,
    predicateIndex = null
  ) => {
    const foundItem = lodashFind(list, (o) => o.id === attributeId);
    const type = foundItem && foundItem.type ? foundItem.type : 'NUMERIC';
    const operatorMessage = errorHandling(
      this.props.errorsSegmentSubmit,
      this.selectedItemPropertiesAggregation(predicateIndex, 'operator')
    );
    const operatorTerm = this.selectedItemPropertiesAggregation(
      predicateIndex,
      'operator'
    );
    return (
      <Form.Item
        style={{ float: 'left', marginRight: '10px' }}
        validateStatus={operatorMessage ? 'error' : null}
        help={operatorMessage}
      >
        <Select
          disabled={this.props.readOnly}
          placeholder="Select an Operator"
          style={{ width: 160 }}
          value={operator}
          onChange={(value) => {
            this.selectValue('operator', value);
            if (this.props.errorsSegmentSubmit?.errors) {
              this.props.clearSegmentError(
                this.props.errorsSegmentSubmit.errors,
                operatorTerm
              );
            }
          }}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    marginRight: 10,
                    fontSize: 14,
                    fontWeight: 600,
                    color: '#1c1c1c',
                  }}
                >
                  Negate Mode:
                </div>
                <div>
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(value) => {
                      this.selectValue('negate', value);
                    }}
                    checked={negate}
                  />
                </div>
              </div>
            </div>
          )}
        >
          {this.renderOperatorSectorOptions(type, negate, true)}
        </Select>
      </Form.Item>
    );
  };

  renderAggregatyionOptions = (eventAggregationText) => {
    return Object.keys(eventAggregationText).map((x) => ({
      value: x,
      label: eventAggregationText[x],
    }));
  };

  aggregationAttributeHandler = (value) => {
    const predicateCopy = cloneDeep(this.props.predicate);

    const fields = value.split('-');
    const eventAggregation = fields[0];
    const eventAttributeId = fields[1] ? +fields[1] : null;

    predicateCopy.lessThan = null;
    predicateCopy.greaterThan = null;
    predicateCopy.relativeDateDurationFrom = null;
    predicateCopy.relativeDateDurationTo = null;
    predicateCopy.relativeDateType = null;
    predicateCopy.relativeDateValueFrom = null;
    predicateCopy.relativeDateValueTo = null;
    predicateCopy.eventAggregation = eventAggregation;
    predicateCopy.eventAttribute = eventAttributeId;
    predicateCopy.operator = null;
    predicateCopy.stringValue = null;
    predicateCopy.negate = false;
    this.props.handleChangePredicate(predicateCopy);
  };

  aggregationValue = (list) => {
    let eventAttributeCombineAggregation = {
      EXISTS: 'at least once',
      OCCURRENCE: 'no. of occurrences',
    };
    list
      .filter((item) => item.aggregationField)
      .forEach((item) => {
        if (item.type === 'NUMERIC') {
          eventAttributeCombineAggregation[
            `MAXIMUM-${item.id}`
          ] = `maximun of ${item.title}`;
          eventAttributeCombineAggregation[
            `MINIMUM-${item.id}`
          ] = `minimum of ${item.title}`;
          // eventAttributeCombineAggregation[
          //   `SUM-${item.id}`
          // ] = `sum of ${item.title}`;
          // eventAttributeCombineAggregation[
          //   `AVERAGE-${item.id}`
          // ] = `average of ${item.title}`;
        } else if (item.type === 'DATE') {
          eventAttributeCombineAggregation[
            `MAXIMUM-${item.id}`
          ] = `maximun of ${item.title}`;
          eventAttributeCombineAggregation[
            `MINIMUM-${item.id}`
          ] = `minimum of ${item.title}`;
        }
      });
    return eventAttributeCombineAggregation;
  };

  setAggregationValue = (obj, aggregationType, atrributeId) => {
    let setAtrributeId = `-${atrributeId}`;
    let aggregationEventPredicate = `${aggregationType}${
      atrributeId ? setAtrributeId : null
    }`;
    return obj[aggregationEventPredicate];
  };

  renderAggregationEventPredicate = (eventId, aggregationType, atrributeId) => {
    const predicate = this.props.predicate;
    let attributeId = `-${atrributeId}`;
    let aggregationEventPredicate = `${aggregationType}${
      atrributeId ? attributeId : ''
    }`;
    const foundItem = lodashFind(this.props.events, (o) => o.id === eventId);
    const list =
      foundItem && foundItem.attributes && Array.isArray(foundItem.attributes)
        ? foundItem.attributes
        : [];
    let eventAttributeCombineAggregation = this.aggregationValue(list);
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {list && list.length > 0 ? (
          <Select
            defaultValue={eventAttributeCombineAggregation['EXISTS']}
            style={{ float: 'left', width: 280, marginRight: '10px' }}
            value={eventAttributeCombineAggregation[aggregationEventPredicate]}
            onChange={(e) => {
              this.aggregationAttributeHandler(
                e,
                list,
                aggregationEventPredicate
              );
              if (this.props.errorsSegmentSubmit?.errors) {
                this.props.clearSegmentError(
                  this.props.errorsSegmentSubmit.errors,
                  [
                    this.selectedItemPropertiesAggregation(
                      this.props.predicateIndex,
                      'operator'
                    ),
                    this.selectedItemPropertiesAggregation(
                      this.props.predicateIndex,
                      'stringValue'
                    ),
                    this.selectedItemPropertiesAggregation(
                      this.props.predicateIndex,
                      'greaterThan'
                    ),
                  ]
                );
              }
            }}
            options={this.renderAggregatyionOptions(
              eventAttributeCombineAggregation
            )}
          />
        ) : (
          <div style={{ fontSize: '12px', color: '#333' }}>
            Please select an event
          </div>
        )}
        {predicate.eventAggregation !== 'EXISTS' &&
          this.renderAggregationOperatorSelector(
            predicate.eventAttribute,
            list,
            predicate.operator,
            predicate.negate,
            this.props.predicateIndex
          )}
        {this.renderAggregationPredicateValue(
          predicate.eventAttribute,
          predicate.operator,
          list,
          predicate.greaterThan,
          predicate.lessThan,
          predicate.stringValue,
          null,
          predicate.relativeDateType,
          predicate.relativeDateValueTo,
          predicate.relativeDateDurationTo,
          predicate.relativeDateValueFrom,
          predicate.relativeDateDurationFrom,
          this.props.predicateIndex
        )}
        {this.renderAggregationDatePickerBetween(
          list,
          predicate.eventAttribute,
          predicate.operator,
          predicate.relativeDateType,
          predicate.relativeDateValueTo,
          predicate.relativeDateDurationTo,
          predicate.relativeDateValueFrom,
          predicate.relativeDateDurationFrom,
          predicate.greaterThan,
          predicate.lessThan,
          null,
          this.props.predicateIndex
        )}
      </div>
    );
  };

  renderEventPredicate = (event, eventConjunction, eventPredicates) => {
    const foundItem = lodashFind(this.props.events, (o) => o.id === event);
    const list =
      foundItem && foundItem.attributes && Array.isArray(foundItem.attributes)
        ? foundItem.attributes
        : [];
    if (this.props.readOnly && (!eventPredicates || !eventPredicates.length)) {
      return null;
    }

    const handleSwitchChange = (checked, index) => {
      const updatedIsDynumic = [...this.state.isDynumic];
      updatedIsDynumic[index] = checked;
      this.setState({ isDynumic: updatedIsDynumic });
      //Empty oprator and values
      const predicate = this.props.predicate;
      const predicateCopy = cloneDeep(predicate);
      predicateCopy.eventPredicates[index].operator = '';
      predicateCopy.eventPredicates[index].stringValue = null;
      predicateCopy.eventPredicates[index].lessThan = null;
      predicateCopy.eventPredicates[index].greaterThan = null;
      predicateCopy.eventPredicates[index].dynamicGreaterThan = null;
      predicateCopy.eventPredicates[index].dynamicLessThan = null;
      predicateCopy.eventPredicates[index].dynamicValue = null;

      this.props.handleChangePredicate(predicateCopy);
    };

    const hasMutateAuthority =
      hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_SEGMENT_CREATE) ||
      hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_SEGMENT_UPDATE) ||
      hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_SEGMENT_REPORT);

    return (
      <div
        style={{
          ...styles.eventPredicateContent,
          ...(hasMutateAuthority ? {} : styles.disabledEventPredicateContent),
        }}
      >
        <div style={{ margin: '16px 0' }}>
          {eventPredicates && eventPredicates.length > 0 ? (
            eventPredicates.map((item, index) => (
              <React.Fragment key={item.id}>
                <div style={styles.eventPredicateAttributeHeader}>
                  <div style={styles.eventPredicateAttributeHeaderTitle}>
                    Attribute Existence
                  </div>
                  {!this.props.readOnly && (
                    <div
                      onClick={() =>
                        this.deleteInnerPredicate(
                          index,
                          this.predicateType.BEHAVIOUR
                        )
                      }
                      style={styles.eventPredicateAttributeDelete}
                    >
                      <DeleteOutlined
                        style={{ fontSize: '16px', color: '#94969e' }}
                      />
                    </div>
                  )}
                </div>
                <div style={styles.eventPredicateAttribute}>
                  <div>
                    {this.renderUserAttributeSelector(
                      item.attribute,
                      list,
                      this.predicateType.BEHAVIOUR,
                      index
                    )}
                  </div>
                  {this.props.dynamicConversion &&
                  item.operator !== 'IS_NOT_EMPTY' ? (
                    <Space
                      direction="vertical"
                      style={{
                        paddingLeft: 10,
                        paddingTop: 5,
                        display: 'flex',
                        flexFlow: 'row',
                        fontSize: 12,
                        fontWeight: 'normal',
                        float: 'right',
                      }}
                    >
                      <span>Dynamic matching</span>
                      <Switch
                        checked={this.state.isDynumic[index]}
                        onChange={(checked) =>
                          handleSwitchChange(checked, index)
                        }
                      />
                    </Space>
                  ) : null}
                  <div style={styles.eventPredicateAttributeOperatorContent}>
                    {this.renderOperatorSelector(
                      item.attribute,
                      item.operator,
                      list,
                      this.predicateType.BEHAVIOUR,
                      item.negate,
                      index
                    )}
                  </div>

                  {!this.props.dynamicConversion && (
                    <div>
                      {this.renderPredicateValue(
                        item.attribute,
                        item.operator,
                        list,
                        item.greaterThan,
                        item.lessThan,
                        item.stringValue,
                        this.predicateType.BEHAVIOUR,
                        index,
                        item.relativeDateType,
                        item.relativeDateValueTo,
                        item.relativeDateDurationTo,
                        item.relativeDateValueFrom,
                        item.relativeDateDurationFrom,
                        this.props.predicateIndex
                      )}
                    </div>
                  )}
                  {this.props.dynamicConversion &&
                    (this.state.isDynumic[index] ? (
                      <div>
                        {this.renderDynamicConversionValue(
                          item.attribute,
                          item.operator,
                          list,
                          item.dynamicGreaterThan,
                          item.dynamicLessThan,
                          item.dynamicValue,
                          this.predicateType.BEHAVIOUR,
                          index
                        )}
                      </div>
                    ) : (
                      <div>
                        {this.renderPredicateValue(
                          item.attribute,
                          item.operator,
                          list,
                          item.greaterThan,
                          item.lessThan,
                          item.stringValue,
                          this.predicateType.BEHAVIOUR,
                          index,
                          item.relativeDateType,
                          item.relativeDateValueTo,
                          item.relativeDateDurationTo,
                          item.relativeDateValueFrom,
                          item.relativeDateDurationFrom,
                          this.props.predicateIndex
                        )}
                      </div>
                    ))}
                </div>
                {this.renderDatePickerBetween(
                  list,
                  item.attribute,
                  item.operator,
                  item.relativeDateType,
                  item.relativeDateValueTo,
                  item.relativeDateDurationTo,
                  item.relativeDateValueFrom,
                  item.relativeDateDurationFrom,
                  item.greaterThan,
                  item.lessThan,
                  this.predicateType.BEHAVIOUR,
                  index
                )}
                {this.renderPredicateConjunctionDivider(
                  index === eventPredicates.length - 1,
                  eventConjunction,
                  true,
                  this.predicateType.BEHAVIOUR
                )}
              </React.Fragment>
            ))
          ) : (
            <React.Fragment>
              <div
                onClick={() =>
                  this.handleAddInnerAttribute(this.predicateType.BEHAVIOUR)
                }
                style={styles.eventPredicateAttributeAdd}
              >
                Click Here to Add Existence of Event Attribute +{' '}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };

  renderDevicePredicate = (deviceConjunction, devicePredicates) => {
    const list =
      this.props.deviceAttributes && Array.isArray(this.props.deviceAttributes)
        ? this.props.deviceAttributes
        : [];
    if (this.props.readOnly) {
      return null;
    }

    return (
      <div style={styles.eventPredicateContent}>
        <div style={{ margin: '16px 0' }}>
          {devicePredicates && devicePredicates.length > 0 ? (
            devicePredicates.map((item, index) => (
              <React.Fragment key={item.id}>
                <div style={styles.eventPredicateAttributeHeader}>
                  <div style={styles.eventPredicateAttributeHeaderTitle}>
                    Attribute Existence
                  </div>
                  <div
                    onClick={() =>
                      this.deleteInnerPredicate(
                        index,
                        this.predicateType.DEVICE
                      )
                    }
                    style={styles.eventPredicateAttributeDelete}
                  >
                    <DeleteOutlined
                      style={{ fontSize: '16px', color: '#94969e' }}
                    />
                  </div>
                </div>
                <div style={styles.eventPredicateAttribute}>
                  <div>
                    {this.renderUserAttributeSelector(
                      item.attribute,
                      list,
                      this.predicateType.DEVICE,
                      index
                    )}
                  </div>
                  <div style={styles.eventPredicateAttributeOperatorContent}>
                    {this.renderOperatorSelector(
                      item.attribute,
                      item.operator,
                      list,
                      this.predicateType.DEVICE,
                      item.negate,
                      index
                    )}
                  </div>
                  <div>
                    {this.renderPredicateValue(
                      item.attribute,
                      item.operator,
                      list,
                      item.greaterThan,
                      item.lessThan,
                      item.stringValue,
                      this.predicateType.DEVICE,
                      index,
                      item.relativeDateType,
                      item.relativeDateValueTo,
                      item.relativeDateDurationTo,
                      item.relativeDateValueFrom,
                      item.relativeDateDurationFrom,
                      this.props.predicateIndex
                    )}
                  </div>
                </div>
                {this.renderDatePickerBetween(
                  list,
                  item.attribute,
                  item.operator,
                  item.relativeDateType,
                  item.relativeDateValueTo,
                  item.relativeDateDurationTo,
                  item.relativeDateValueFrom,
                  item.relativeDateDurationFrom,
                  item.greaterThan,
                  item.lessThan,
                  this.predicateType.DEVICE,
                  index
                )}
                {this.renderPredicateConjunctionDivider(
                  index === devicePredicates.length - 1,
                  deviceConjunction,
                  true,
                  this.predicateType.DEVICE
                )}
              </React.Fragment>
            ))
          ) : (
            <React.Fragment>
              <div
                onClick={() =>
                  this.handleAddInnerAttribute(this.predicateType.DEVICE)
                }
                style={styles.eventPredicateAttributeAdd}
              >
                Click Here to Add Existence of Device Attribute +{' '}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };

  setAttributeType = (v) => {
    this.setState({ attributeType: v });
  };

  setAttribute = (v) => {
    this.setState({ attribute: v });
  };

  onChangeAttributeType = (type) => {
    this.setAttributeType(type);
    this.props.handleChangePredicate({
      attributeType: type,
    });
    // setAttributeTrigger(old => Object.assign({}, old, { attributeType: type }));
  };

  onChangeAttribute = (val) => {
    this.setAttribute(val);
    this.props.handleChangePredicate({
      attribute: val,
    });
  };

  renderSelectAttribute = () => {
    return (
      <div>
        <div className="exit_box__space__span">
          <span>End journey when there is</span>
          <span>
            <Select
              style={{ width: 100 }}
              placeholder="Select type"
              onChange={this.onChangeAttributeType}
              value={this.state?.attributeType}
            >
              {AttributeTriggerStatusType.map((item) => (
                <Select.Option key={item.key} value={item.value}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </span>
          <span>change in user attribute</span>
          <span>
            <Select
              style={{ width: 150 }}
              placeholder="Select Attribute"
              onChange={this.onChangeAttribute}
              value={this.state?.attribute}
              showSearch
              filterOption={(input, option) => {
                if (option.value) {
                  return (
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }
                return '';
              }}
            >
              {this.props.attributes.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </span>
        </div>
      </div>
    );
  };

  setTypeAttrPredicate = (index, attribute, event) => {
    const predicateCopy = cloneDeep(this.props.predicate);
    if (this.predicateType === this.predicateType.BEHAVIOUR) {
      // predicateCopy.eventPredicates.splice(innerPredicateIndex, 1);
      predicateCopy[index].type = event;
    }
    this.props.handleChangePredicate(predicateCopy, true);
  };

  negateAggregationHandler = (value) => {
    const predicateCopy = cloneDeep(this.props.predicate);
    if (value === false) {
      predicateCopy.eventAggregation = 'NOT_EXISTS';
      predicateCopy.operator = null;
      predicateCopy.stringValue = null;
      predicateCopy.lessThan = null;
      predicateCopy.greaterThan = null;
      predicateCopy.relativeDateDurationFrom = null;
      predicateCopy.relativeDateDurationTo = null;
      predicateCopy.relativeDateType = null;
      predicateCopy.relativeDateValueFrom = null;
      predicateCopy.relativeDateValueTo = null;
      predicateCopy.eventAttribute = null;
      this.props.handleChangePredicate(predicateCopy);
    } else if (value === true) {
      predicateCopy.eventAggregation = 'EXISTS';
      this.setState({ showAggregationBox: false });
    }
    this.props.handleChangePredicate(predicateCopy);
  };

  renderBox = () => {
    const item = this.props.predicate;
    const termConjunction = this.props.termConjunction;
    const isLast = this.props.isLast;

    const isChangeInUser = this.props.type === 'CHANGE_IN_USER_ATTRIBUTE';

    return (
      <div>
        <React.Fragment>
          {(!isNil(item.attribute) || this.props.journey) &&
            (item.type === 'ATTRIBUTE' ||
              isChangeInUser ||
              item.type === 'CHECK_USER_ATTRIBUTE') && (
              <React.Fragment>
                <div style={isChangeInUser ? null : { margin: '16px 24px' }}>
                  {this.props.isInSegment && (
                    <div style={styles.boxAttributeHeader}>
                      <div style={styles.boxAttributeHeaderTitle}>
                        User Attribute
                      </div>
                      <div
                        onClick={() => this.deletePredicate()}
                        style={styles.boxAttributeHeaderDelete}
                      >
                        <DeleteOutlined
                          style={{ fontSize: '16px', color: '#94969e' }}
                        />
                      </div>
                    </div>
                  )}
                  <div
                    style={
                      isChangeInUser
                        ? styles.boxAttributeFlex
                        : styles.boxAttribute
                    }
                  >
                    <div>
                      {this.renderUserAttributeSelector(
                        item.attribute,
                        this.props.attributes,
                        this.predicateType.ATTRIBUTE,
                        false
                      )}
                    </div>
                    <div style={styles.boxAttributeOperatorContent}>
                      {this.renderOperatorSelector(
                        item.attribute,
                        item.operator,
                        this.props.attributes,
                        this.predicateType.ATTRIBUTE,
                        item.negate,
                        this.props.predicateIndex
                      )}
                    </div>
                    <div style={styles.boxAttributeOperatorValue}>
                      {this.renderPredicateValue(
                        item.attribute,
                        item.operator,
                        this.props.attributes,
                        item.greaterThan,
                        item.lessThan,
                        item.stringValue,
                        this.predicateType.ATTRIBUTE,
                        null,
                        item.relativeDateType,
                        item.relativeDateValueTo,
                        item.relativeDateDurationTo,
                        item.relativeDateValueFrom,
                        item.relativeDateDurationFrom,
                        this.props.predicateIndex
                      )}
                    </div>
                  </div>
                  {this.renderDatePickerBetween(
                    this.props.attributes,
                    item.attribute,
                    item.operator,
                    item.relativeDateType,
                    item.relativeDateValueTo,
                    item.relativeDateDurationTo,
                    item.relativeDateValueFrom,
                    item.relativeDateDurationFrom,
                    item.greaterThan,
                    item.lessThan,
                    this.predicateType.ATTRIBUTE,
                    null
                  )}
                </div>
                {this.props.isInSegment &&
                  this.renderPredicateConjunctionDivider(
                    isLast,
                    termConjunction,
                    false,
                    null
                  )}
              </React.Fragment>
            )}
        </React.Fragment>
        <React.Fragment>
          {(!isNil(item.event) || this.props.journey) &&
            (item.type === 'BEHAVIOUR' ||
              item.type === 'HAS_DONE_EVENT' ||
              item.type === 'EVENT_OCCURRENCE' ||
              item.type === 'WAIT_FOR_EVENT') && (
              <React.Fragment>
                <div style={{ margin: '16px 24px' }}>
                  {this.props.isInSegment && (
                    <div style={styles.boxBehaviourHeader}>
                      <div style={styles.boxBehaviourHeaderTitle}>Event</div>
                      <div
                        onClick={() => this.deletePredicate()}
                        style={styles.boxBehaviourHeaderDelete}
                      >
                        <DeleteOutlined
                          style={{ fontSize: '16px', color: '#94969e' }}
                        />
                      </div>
                    </div>
                  )}
                  <div style={styles.boxBehaviour}>
                    {this.props.isInSegment ? (
                      <div>
                        <span style={{ marginRight: 5 }}>
                          Users who{' '}
                          <Select
                            value={
                              item.eventAggregation === 'NOT_EXISTS'
                                ? false
                                : true
                            }
                            style={{
                              width: 120,
                              marginLeft: 5,
                              marginRight: 5,
                            }}
                            onChange={(value) =>
                              this.negateAggregationHandler(value)
                            }
                            disabled={this.props.readOnly}
                          >
                            <Select.Option value={true}>{'did'}</Select.Option>
                            <Select.Option value={false}>
                              {'did not do'}
                            </Select.Option>
                          </Select>{' '}
                          this event:{' '}
                        </span>
                        {this.renderEventSelector(item.event)}
                      </div>
                    ) : (
                      <div>{this.renderEventSelector(item.event)}</div>
                    )}
                    {this.props.showEventAggregation && (
                      <div
                        onClick={() =>
                          this.setState({
                            showAggregationBox: !this.state.showAggregationBox,
                          })
                        }
                        style={
                          item.eventAggregation === 'NOT_EXISTS'
                            ? { display: 'none' }
                            : {
                                fontSize: '12px',
                                padding: '7px',
                                fontWeight: 'bold',
                                color: 'rgb(29, 136, 246)',
                                cursor: 'pointer',
                              }
                        }
                      >
                        Set Aggregation
                        <DoubleLeftOutlined
                          style={{ paddingLeft: '5px', fontSize: '10px' }}
                          theme="outlined"
                          rotate={270}
                        />
                      </div>
                    )}
                  </div>
                  {this.props.showEventAggregation &&
                    this.state.showAggregationBox && (
                      <div
                        style={
                          item.eventAggregation === 'NOT_EXISTS'
                            ? { display: 'none' }
                            : {
                                display: 'flex',
                                marginBottom: '16px',
                                justifyContent: 'space-between',
                              }
                        }
                      >
                        <div style={{ marginRight: '10px' }}>
                          {['SUM', 'AVERAGE'].includes(item.eventAggregation)
                            ? `currently ${item.eventAggregation} type is not supported. please reset the aggregation and select another type`
                            : this.renderAggregationEventPredicate(
                                item.event,
                                item.eventAggregation,
                                item.eventAttribute
                              )}
                        </div>
                        <div
                          onClick={() => this.resetPredicate()}
                          style={{
                            cursor: 'pointer',
                            color: '#94969e',
                            fontSize: '12px',
                          }}
                        >
                          Reset
                        </div>
                      </div>
                    )}
                  {!(
                    hasAnyAuthority(
                      AuthorityProvider.ROLE_PRODUCT_SEGMENT_CREATE
                    ) ||
                    hasAnyAuthority(
                      AuthorityProvider.ROLE_PRODUCT_SEGMENT_UPDATE
                    ) ||
                    hasAnyAuthority(
                      AuthorityProvider.ROLE_PRODUCT_SEGMENT_REPORT
                    )
                  ) && (
                    <Alert
                      showIcon
                      style={{ marginBottom: 8 }}
                      message="You have no permission to add existence of event attributes."
                      type="warning"
                    />
                  )}
                  {this.renderEventPredicate(
                    item.event,
                    item.eventConjunction,
                    item.eventPredicates,
                    item.type
                  )}
                </div>
                {this.props.isInSegment &&
                  this.renderPredicateConjunctionDivider(
                    isLast,
                    termConjunction,
                    false,
                    null
                  )}
              </React.Fragment>
            )}
        </React.Fragment>
        <React.Fragment>
          {item.type === 'DEVICE' && (
            <React.Fragment>
              <div style={{ margin: '16px 24px' }}>
                {this.props.isInSegment && (
                  <div
                    style={{
                      ...styles.boxBehaviourHeader,
                      paddingBottom: 15,
                    }}
                  >
                    <div style={styles.boxBehaviourHeaderTitle}>
                      Device Attribute
                    </div>
                    <div
                      onClick={() => this.deletePredicate()}
                      style={styles.boxBehaviourHeaderDelete}
                    >
                      <DeleteOutlined
                        style={{ fontSize: '16px', color: '#94969e' }}
                      />
                    </div>
                  </div>
                )}
                {this.renderDevicePredicate(
                  item.deviceConjunction,
                  item.devicePredicates
                )}
              </div>
              {this.props.isInSegment &&
                this.renderPredicateConjunctionDivider(
                  isLast,
                  termConjunction,
                  false,
                  null
                )}
            </React.Fragment>
          )}
        </React.Fragment>
      </div>
    );
  };

  render() {
    return <React.Fragment>{this.renderBox()}</React.Fragment>;
  }
}

export default ProductSegmentPredicateComponent;
