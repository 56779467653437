import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Segmented,
  Select,
  Space,
  Spin,
  Switch,
} from 'antd';
import { LoadingOutlined, DeleteOutlined } from '@ant-design/icons';
import FormBase from '../../../../component/form/FormBase';
import { isEmpty, findIndex, isNil, isArray } from 'lodash';
import Card from '@Utils/Card';
import { getProductId } from '@Utils/AuthorityProvider';
import { thirdPartyEnums } from '@Utils/ThirdPartyOptions';

const { Option } = Select;
const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  title: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: '#9a9ca3',
    fontSize: 14,
    // width: 190,
  },
  description: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: 10,
    color: '#9a9ca3',
    width: 400,
  },
  rowItem: {
    display: 'flex',
    flexDirection: 'row',
  },
};

const EVENT_DATES = ['SECOND', 'MINUTE', 'HOUR', 'DAY', 'WEEK', 'MONTH'];
const CHANNELS = [
  'ALL_CHANNELS',
  'PUSH',
  'WEB_PUSH',
  'SMS',
  'EMAIL',
  'CUSTOM',
  'IN_APP',
];

class AdminProductUpdateComponent extends React.Component {
  constructor(props) {
    super(props);
    this.createMode = this.props.pathname.includes('create');
    this.state = {
      eventRateLimits: [
        {
          interval: 'HOUR',
          threshold: null,
        },
      ],
      audienceGrowths: [
        {
          channelType: 'ALL_CHANNELS',
          growthValue: null,
        },
      ],
      dedicatedTopics: [
        {
          partitionCount: null,
          replicaCount: null,
          topicName: 'EVENT_API',
        },
      ],
      selectedItems: [],
      isEventLimitsValid: true,
      eventLimitError: false,
      activeWebPushKeyType: false,
      thirdPartyOption:
        this.props.currentAdminProduct &&
        this.props.currentAdminProduct.thirdPartyOption
          ? this.props.currentAdminProduct.thirdPartyOption
          : thirdPartyEnums.NORMAL,
      parentProduct: this.props.currentAdminProduct
        ? this.props.currentAdminProduct.parentProduct
        : null,
    };
  }

  eventIsField = () => {
    this.state.eventRateLimits.forEach((item) => {
      if (isNil(item.interval) || isNil(item.threshold)) {
        this.setState({ eventLimitError: true });
      } else {
        this.setState({ eventLimitError: false });
      }
    });
    return this.state.eventLimitError;
  };

  componentDidMount() {
    const productId = this.props.match.params.id;
    if (!this.createMode) {
      this.props.adminProductUpdateFetch(productId);
    } else {
      this.props.adminProductChangeCurrent({
        ...this.props.currentAdminProduct,
        enabled: false,
        legacyPushEnabled: false,
        dedicatedTopics: [],
        webPushManagedByCustomer: false,
      });
    }
    this.props.getParentProducts();
    this.eventIsField();
  }

  componentWillUnmount() {
    this.props.adminProductChangeCurrent({});
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.loadingAdminProduct &&
      !this.props.loadingAdminProduct &&
      this.props.currentAdminProduct
    ) {
      if (
        this.props.currentAdminProduct.eventRateLimits &&
        Array.isArray(this.props.currentAdminProduct.eventRateLimits)
      ) {
        if (this.props.currentAdminProduct.eventRateLimits.length === 0) {
          this.setState({
            eventRateLimits: [{ interval: 'HOUR', threshold: null }],
          });
        } else {
          this.setState({
            eventRateLimits: this.props.currentAdminProduct.eventRateLimits,
          });
        }
      }
      if (
        this.props.currentAdminProduct.dedicatedTopics &&
        Array.isArray(this.props.currentAdminProduct.dedicatedTopics)
      ) {
        if (this.props.currentAdminProduct.dedicatedTopics.length === 0) {
          this.setState({
            dedicatedTopics: [
              {
                partitionCount: null,
                replicaCount: null,
                topicName: 'EVENT_API',
              },
            ],
          });
        } else {
          this.setState({
            dedicatedTopics: this.props.currentAdminProduct.dedicatedTopics,
          });
        }
      }
      if (
        this.props.currentAdminProduct.audienceGrowths &&
        Array.isArray(this.props.currentAdminProduct.audienceGrowths)
      ) {
        if (this.props.currentAdminProduct.audienceGrowths.length === 0) {
          this.setState({
            audienceGrowths: [
              {
                channelType: 'ALL_CHANNELS',
                growthValue: null,
              },
            ],
          });
        } else {
          this.setState({
            audienceGrowths: this.props.currentAdminProduct?.audienceGrowths,
          });
        }
      }
    }
    if (this.state.eventRateLimits !== prevState.eventRateLimits) {
      this.eventIsField();
    }
  }

  onFinish() {
    const productId = getProductId(this.props.pathname);
    const audienceGrowthsArray = this.state.audienceGrowths.map((i) =>
      i.channelType === 'ALL_CHANNELS'
        ? {
            ...i,
            channelType: null,
          }
        : {
            ...i,
            channelType: i.channelType,
          }
    );
    this.props.currentAdminProduct.thirdPartyOption =
      this.state.thirdPartyOption;
    if (
      this.props.currentAdminProduct.thirdPartyOption === thirdPartyEnums.CHILD
    ) {
      this.props.currentAdminProduct.parentProduct = this.state.parentProduct;
    } else if (
      this.props.currentAdminProduct.thirdPartyOption === thirdPartyEnums.PARENT
    ) {
      this.props.currentAdminProduct.parentProduct = null;
    } else if (
      this.props.currentAdminProduct.thirdPartyOption === thirdPartyEnums.NORMAL
    ) {
      this.props.currentAdminProduct.parentProduct = null;
    }
    if (productId) {
      this.props.adminProductUpdateSubmitFetch(productId, {
        ...this.props.currentAdminProduct,
        eventRateLimits: this.state.eventRateLimits.filter(
          (item) => item.threshold
        ),
        dedicatedTopics: this.state.dedicatedTopics.filter(
          (item) => item.partitionCount || item.replicaCount
        ),
        audienceGrowths: audienceGrowthsArray.filter(
          (item) => item.growthValue
        ),
        apiCallEndpoints: isArray(
          this.props.currentAdminProduct?.apiCallEndpoints
        )
          ? this.props.currentAdminProduct?.apiCallEndpoints.join(',')
          : this.props.currentAdminProduct?.apiCallEndpoints,
      });
    } else {
      this.props.adminProductCreateFetch({
        ...this.props.currentAdminProduct,
        eventRateLimits: isEmpty(this.state.eventRateLimits)
          ? null
          : this.state.eventRateLimits.filter((item) => item.threshold),
        dedicatedTopics: isEmpty(this.state.dedicatedTopics)
          ? null
          : this.state.dedicatedTopics.filter(
              (item) => item.replicaCount || item.partitionCount
            ),
        audienceGrowths: isEmpty(audienceGrowthsArray)
          ? []
          : audienceGrowthsArray.filter((item) => item.growthValue),
        webPushKeyType: this.props.currentAdminProduct?.webPushManagedByCustomer
          ? this.props.currentAdminProduct?.webPushKeyType
          : 'BROWSER',
        apiCallEndpoints: isArray(
          this.props.currentAdminProduct?.apiCallEndpoints
        )
          ? this.props.currentAdminProduct?.apiCallEndpoints.join(',')
          : this.props.currentAdminProduct?.apiCallEndpoints,
      });
    }
  }

  handleThirdPartyOptionChange = (val) => {
    this.setState({ thirdPartyOption: val });
    if (val !== thirdPartyEnums.CHILD) {
      this.setState({ parentProduct: null });
    }
  };

  eventCheck = () => {
    const newMyArr = this.state.selectedItems.map((ele) => {
      const itemsCountArray = this.state.selectedItems.filter(
        (obj) => obj === ele
      ).length;
      return itemsCountArray;
    });
    const found = findIndex(newMyArr, (o) => o >= 2);
    if (found >= 0) {
      this.setState({ isEventLimitsValid: false });
    } else {
      this.setState({ isEventLimitsValid: true });
    }
  };
  renderEventRateLimits = () => {
    const usedIntervals = this.state.eventRateLimits.map(
      (item) => item.interval
    );
    const EventDateArray = EVENT_DATES.filter(
      (item) => !usedIntervals.includes(item)
    );
    let array = [];
    if (
      this.state.eventRateLimits &&
      Array.isArray(this.state.eventRateLimits)
    ) {
      this.state.eventRateLimits.forEach((item, index) => {
        array.push(
          <div style={styles.rowItem} id={item.id}>
            <div
              style={{
                ...styles.rowItem,
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                marginLeft: 5,
              }}
            >
              <Select
                style={{ width: 190, marginRight: 10 }}
                value={item.interval}
                onChange={(val) => {
                  let eventRateLimits = [...this.state.eventRateLimits];
                  let selectedItems = [];
                  eventRateLimits[index] = {
                    ...eventRateLimits[index],
                    interval: val,
                  };
                  this.setState({ eventRateLimits, selectedItems }, () => {
                    this.state.eventRateLimits.forEach((item) => {
                      selectedItems.push(item.interval);
                      this.setState({ selectedItems }, () => {
                        this.eventCheck();
                      });
                    });
                  });
                }}
              >
                {EventDateArray.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
              <Form.Item>
                <Input
                  style={{ width: 170, marginRight: 30 }}
                  value={item.threshold}
                  type="number"
                  step="1"
                  onChange={(e) => {
                    let eventRateLimits = [...this.state.eventRateLimits];
                    eventRateLimits[index] = {
                      ...eventRateLimits[index],
                      threshold: isEmpty(e.target.value)
                        ? null
                        : parseInt(e.target.value),
                    };
                    this.setState({ eventRateLimits });
                  }}
                />
              </Form.Item>
              <DeleteOutlined
                onClick={() => {
                  let selectedItems = [];
                  this.state.eventRateLimits.splice(index, 1);
                  this.setState(
                    {
                      eventRateLimits: this.state.eventRateLimits,
                    },
                    () => {
                      if (this.state.eventRateLimits.length === 0) {
                        this.setState({ eventLimitError: false });
                      } else {
                        this.eventIsField();
                      }
                      this.state.eventRateLimits.forEach((item) => {
                        selectedItems.push(item.interval);
                        this.setState({ selectedItems }, () => {
                          this.eventCheck();
                        });
                      });
                    }
                  );
                }}
                style={{
                  fontSize: 20,
                  marginTop: 5,
                  cursor: 'pointer',
                  color: '#9a9ca3',
                }}
              ></DeleteOutlined>
              {EventDateArray.length ? (
                <i
                  onClick={() => {
                    this.state.eventRateLimits.push({
                      interval: EventDateArray[0],
                      threshold: null,
                    });
                    this.setState(
                      {
                        eventRateLimits: this.state.eventRateLimits,
                      },
                      () => {
                        this.eventIsField();
                      }
                    );
                  }}
                  style={{
                    marginTop: 4,
                    cursor: 'pointer',
                    border: '2px solid',
                    borderRadius: '50%',
                    fontSize: '20px',
                    marginLeft: 10,
                  }}
                  className="fl-dark fl-add"
                ></i>
              ) : null}
            </div>
          </div>
        );
      });
    }
    return array;
  };
  renderChannelsAudienceGrowth = () => {
    const usedChannels = this.state.audienceGrowths.map((item) =>
      item?.hasOwnProperty('channelType') ? item.channelType : 'ALL_CHANNELS'
    );
    const channelsUniqueArray = CHANNELS.filter(
      (item) => !usedChannels.includes(item)
    );
    let initialValue = [
      {
        channelType: 'ALL_CHANNELS',
        growthValue: null,
      },
    ];
    if (
      this.props.currentAdminProduct?.hasOwnProperty('audienceGrowths') &&
      Array.isArray(this.props.currentAdminProduct?.audienceGrowths) &&
      this.props.currentAdminProduct?.audienceGrowths.length > 0
    ) {
      initialValue = this.props.currentAdminProduct?.audienceGrowths.map((i) =>
        i?.hasOwnProperty('channelType')
          ? {
              ...i,
              channelType: i.channelType,
            }
          : {
              ...i,
              channelType: 'ALL_CHANNELS',
            }
      );
    } else {
      initialValue = [
        {
          channelType: 'ALL_CHANNELS',
          growthValue: null,
        },
      ];
    }
    return (
      <Form
        name="dynamic_form_nest_item"
        autoComplete="off"
        onValuesChange={(changedValues, allValues) => {
          this.setState({ audienceGrowths: allValues.audienceGrowths });
        }}
      >
        <Form.List initialValue={initialValue} name="audienceGrowths">
          {(fields, { add, remove }) => (
            <>
              <Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    shape="round"
                    ghost
                    style={{
                      marginLeft: -10,
                      cursor:
                        usedChannels.length < 7 ? 'pointer' : 'not-allowed',
                    }}
                    onClick={() => {
                      if (usedChannels.length < 7) {
                        add({
                          channelType: channelsUniqueArray[0],
                          growthValue: null,
                        });
                      }
                    }}
                  >
                    ADD AUDIENCE GROWTH
                  </Button>
                </Form.Item>
              </Form.Item>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                    marginBottom: 8,
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'channelType']}
                    rules={[
                      {
                        required: true,
                        message: 'Missing filed',
                      },
                    ]}
                  >
                    <Select style={{ width: 200 }}>
                      {channelsUniqueArray.map((item) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item {...restField} name={[name, 'growthValue']}>
                    <Input />
                  </Form.Item>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <DeleteOutlined
                      style={{
                        fontSize: 20,
                        marginTop: 5,
                        marginLeft: 20,
                        cursor: 'pointer',
                        color: '#9a9ca3',
                      }}
                      onClick={() => remove(name)}
                    />
                    {usedChannels.length < 7 && (
                      <i
                        style={{
                          marginTop: 4,
                          cursor: 'pointer',
                          border: '2px solid',
                          borderRadius: '50%',
                          fontSize: '20px',
                          marginLeft: 10,
                        }}
                        onClick={() => {
                          if (usedChannels.length < 7) {
                            add({
                              channelType: channelsUniqueArray[0],
                              growthValue: null,
                            });
                          }
                        }}
                        className="fl-dark fl-add"
                      ></i>
                    )}
                  </div>
                </Space>
              ))}
            </>
          )}
        </Form.List>
      </Form>
    );
  };
  renderDedicatedResource = () => {
    const usedDedicated = this.state.dedicatedTopics
      .filter((item) => item?.hasOwnProperty('topicName'))
      .map((item) => item.topicName);
    const dedicatedTopics = ['EVENT_API', 'CALL_API_NOTIFICATION'];
    const dedicatedUniqueArray = dedicatedTopics.filter(
      (item) => !usedDedicated.includes(item)
    );
    let initialValue = [
      {
        partitionCount: null,
        replicaCount: null,
        topicName: 'EVENT_API',
      },
    ];

    if (
      this.props.currentAdminProduct?.hasOwnProperty('dedicatedTopics') &&
      Array.isArray(this.props.currentAdminProduct?.dedicatedTopics) &&
      this.props.currentAdminProduct?.dedicatedTopics.length > 0
    ) {
      initialValue = this.props.currentAdminProduct?.dedicatedTopics;
    } else {
      initialValue = [
        {
          partitionCount: null,
          replicaCount: null,
          topicName: 'EVENT_API',
        },
      ];
    }
    return (
      <Form
        name="dynamic_form_nest_item"
        autoComplete="off"
        onValuesChange={(changedValues, allValues) => {
          this.setState({ dedicatedTopics: allValues.dedicatedTopics });
        }}
      >
        <Form.List initialValue={initialValue} name="dedicatedTopics">
          {(fields, { add, remove }) => (
            <>
              <Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    shape="round"
                    ghost
                    style={{
                      marginLeft: -10,
                      cursor:
                        usedDedicated.length < 2 ? 'pointer' : 'not-allowed',
                    }}
                    onClick={() => {
                      if (usedDedicated.length < 2) {
                        add({
                          topicName: dedicatedUniqueArray[0],
                          partitionCount: null,
                          replicaCount: null,
                        });
                      }
                    }}
                  >
                    ADD dedicated Resource
                  </Button>
                </Form.Item>
              </Form.Item>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                    marginBottom: 8,
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'topicName']}
                    rules={[
                      {
                        required: true,
                        message: 'Missing filed',
                      },
                    ]}
                  >
                    <Select style={{ width: 200 }}>
                      {dedicatedUniqueArray.map((item) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item {...restField} name={[name, 'partitionCount']}>
                    <InputNumber
                      placeholder="partitionCount"
                      style={{ width: 120 }}
                    />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, 'replicaCount']}>
                    <InputNumber
                      placeholder="replicaCount"
                      style={{ width: 120 }}
                    />
                  </Form.Item>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <DeleteOutlined
                      style={{
                        fontSize: 20,
                        marginTop: 5,
                        marginLeft: 20,
                        cursor: 'pointer',
                        color: '#9a9ca3',
                      }}
                      onClick={() => remove(name)}
                    />
                    {usedDedicated.length < 2 && (
                      <i
                        style={{
                          marginTop: 4,
                          cursor: 'pointer',
                          border: '2px solid',
                          borderRadius: '50%',
                          fontSize: '20px',
                          marginLeft: 10,
                        }}
                        onClick={() => {
                          if (usedDedicated.length < 2) {
                            add({
                              topicName: dedicatedUniqueArray[0],
                              partitionCount: null,
                              replicaCount: null,
                            });
                          }
                        }}
                        className="fl-dark fl-add"
                      ></i>
                    )}
                  </div>
                </Space>
              ))}
            </>
          )}
        </Form.List>
      </Form>
    );
  };

  render() {
    const loadingSpin = <LoadingOutlined style={{ fontSize: 40 }} spin />;
    const usedIntervals = this.state.eventRateLimits.map(
      (item) => item.interval
    );
    const productId = this.props.match.params.id;

    return (
      <div>
        <Card
          styles={{
            padding: 30,
          }}
        >
          {this.props.loadingAdminProduct ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '100px',
              }}
            >
              <Spin indicator={loadingSpin} />{' '}
            </div>
          ) : (
            <React.Fragment>
              <FormBase
                ref={this.formRef}
                errors={this.props.errors}
                value={{
                  ...this.props.currentAdminProduct,
                  apiCallEndpoints: isArray(
                    this.props.currentAdminProduct?.apiCallEndpoints
                  )
                    ? this.props.currentAdminProduct?.apiCallEndpoints
                    : this.props.currentAdminProduct?.apiCallEndpoints?.split(
                        ','
                      ),
                }}
                onChange={(values) => {
                  this.props.adminProductChangeCurrent({
                    ...this.props.currentAdminProduct,
                    ...values,
                  });
                }}
                onFinish={(values) => {
                  this.onFinish(values);
                }}
                hideSubmit
                fullWidth
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    marginLeft: 20,
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'baseline',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div style={{ ...styles.title, marginRight: 16 }}>NAME</div>
                    <Form.Item
                      style={styles.description}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your name!',
                        },
                      ]}
                    >
                      <Input
                        value={
                          this.props.currentAdminProduct &&
                          this.props.currentAdminProduct.name
                        }
                      />
                    </Form.Item>
                  </div>
                  <div style={styles.rowItem}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <div style={{ ...styles.title, marginRight: 16 }}>
                        REPLICAS
                      </div>
                      <Form.Item
                        style={{ ...styles.description, width: 130 }}
                        name="replicas"
                        rules={[{ required: true, message: 'required!' }]}
                      >
                        <InputNumber
                          value={
                            this.props.currentAdminProduct &&
                            this.props.currentAdminProduct.replicas
                          }
                          min={0}
                        />
                      </Form.Item>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                        justifyContent: 'flex-start',
                        marginLeft: '50px',
                      }}
                    >
                      <div style={{ ...styles.title, marginRight: 16 }}>
                        MAP REPLICAS
                      </div>
                      <Form.Item
                        style={{ ...styles.description, width: 130 }}
                        name="mapReplicas"
                        rules={[{ required: true, message: 'required!' }]}
                      >
                        <InputNumber
                          value={
                            this.props.currentAdminProduct &&
                            this.props.currentAdminProduct.mapReplicas
                          }
                          min={0}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div style={styles.rowItem}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <div style={{ ...styles.title, marginRight: 16 }}>
                        SHARDS
                      </div>
                      <Form.Item
                        style={{ ...styles.description, width: 130 }}
                        name="shards"
                        rules={[{ required: true, message: 'required!' }]}
                      >
                        <InputNumber
                          value={
                            this.props.currentAdminProduct &&
                            this.props.currentAdminProduct.shards
                          }
                          min={0}
                        />
                      </Form.Item>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <div style={{ ...styles.title, marginRight: 16 }}>
                        MAP SHARDS
                      </div>
                      <Form.Item
                        style={{ ...styles.description, width: 130 }}
                        name="mapShards"
                        rules={[{ required: true, message: 'required!' }]}
                      >
                        <InputNumber
                          value={
                            this.props.currentAdminProduct &&
                            this.props.currentAdminProduct.mapShards
                          }
                          min={0}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                    }}
                  >
                    <div style={{ ...styles.title }}>DESCRIPTION</div>
                    <Form.Item
                      style={{ marginLeft: 30, width: '100%' }}
                      name="description"
                    >
                      <Input.TextArea
                        value={
                          this.props.currentAdminProduct &&
                          this.props.currentAdminProduct.description
                        }
                        style={{ width: 1000 }}
                        rows={4}
                      />
                    </Form.Item>
                  </div>
                  {
                    <div style={styles.rowItem}>
                      <div
                        style={{
                          ...styles.rowItem,
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          marginBottom: 16,
                          gap: 16,
                        }}
                      >
                        <div style={styles.title}>LEGACY PUSH ENABLED</div>
                        <Form.Item
                          name="legacyPushEnabled"
                          style={{ marginBottom: 0 }}
                        >
                          <Switch
                            defaultChecked={
                              this.props.currentAdminProduct &&
                              this.props.currentAdminProduct.legacyPushEnabled
                            }
                          />
                        </Form.Item>
                        <div
                          style={{
                            ...styles.title,
                            // marginLeft: 130,
                            // width: 150,
                          }}
                        >
                          DEMO
                        </div>
                        <Form.Item name="demo" style={{ marginBottom: 0 }}>
                          <Switch
                            defaultChecked={
                              this.props.currentAdminProduct &&
                              this.props.currentAdminProduct.demo
                            }
                          />
                        </Form.Item>
                        <div
                          style={{
                            ...styles.title,
                            // marginLeft: 130,
                            // marginRight: 10,
                            // width: 140,
                          }}
                        >
                          ENABLE ADS
                        </div>
                        <Form.Item
                          name="adsEnabled"
                          style={{ marginBottom: 0 }}
                        >
                          <Switch
                            defaultChecked={
                              this.props.currentAdminProduct?.hasOwnProperty(
                                'adsEnabled'
                              )
                                ? this.props.currentAdminProduct.adsEnabled
                                : false
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>
                  }
                  <div style={styles.rowItem}>
                    <div
                      style={{
                        ...styles.rowItem,
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 16,
                        marginBottom: 16,
                        flexWrap: 'wrap',
                      }}
                    >
                      <div style={styles.title}>ENABLED</div>
                      <Form.Item name="enabled" style={{ marginBottom: 0 }}>
                        <Switch
                          defaultChecked={
                            this.props.currentAdminProduct &&
                            this.props.currentAdminProduct.enabled
                          }
                        />
                      </Form.Item>
                      <div
                        style={{
                          ...styles.title,
                          // marginLeft: 100,
                          // marginRight: 10,
                          // width: 150,
                        }}
                      >
                        ENABLE THROTTLING
                      </div>
                      <Form.Item
                        name="throttlingEnabled"
                        style={{ marginBottom: 0 }}
                      >
                        <Switch
                          defaultChecked={
                            this.props.currentAdminProduct?.hasOwnProperty(
                              'throttlingEnabled'
                            )
                              ? this.props.currentAdminProduct.throttlingEnabled
                              : false
                          }
                        />
                      </Form.Item>
                      <div
                        style={{
                          ...styles.title,
                          // marginLeft: 100,
                          // marginRight: 10,
                          // width: 150,
                        }}
                      >
                        SENDIFY
                      </div>
                      <Form.Item name="sendify" style={{ marginBottom: 0 }}>
                        <Switch
                          defaultChecked={
                            this.props?.currentAdminProduct?.sendify
                          }
                          disabled={!this.createMode}
                        />
                      </Form.Item>
                      <div
                        style={{
                          ...styles.title,
                          // marginLeft: 100,
                          // marginRight: 10,
                          // width: 150,
                        }}
                      >
                        SDK PROFILE UPDATE
                      </div>
                      <Form.Item
                        name="allowProfileUpdateFromWebSdk"
                        style={{ marginBottom: 0 }}
                      >
                        <Switch
                          defaultChecked={
                            this.props?.currentAdminProduct
                              ?.allowProfileUpdateFromWebSdk
                          }
                          disabled={!this.createMode}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div style={styles.rowItem}>
                    <div
                      style={{
                        ...styles.rowItem,
                        alignItems: 'baseline',
                        justifyContent: 'flex-start',
                        gap: 16,
                      }}
                    >
                      <div style={styles.title}>ALLOWED DATA SOURCEIDS</div>
                      <Form.Item name="allowedDataSourceIds">
                        <Input
                          style={{ width: 300 }}
                          value={
                            this.props.currentAdminProduct?.hasOwnProperty(
                              'allowedDataSourceIds'
                            )
                              ? this.props.currentAdminProduct
                                  .allowedDataSourceIds
                              : null
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        ...styles.rowItem,
                        alignItems: 'baseline',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <div style={{ ...styles.title, marginRight: 16 }}>
                        THIRD PARTY OPTION
                      </div>
                      <Form.Item name="thirdPartyOption">
                        <Segmented
                          style={{ borderRadius: 8 }}
                          onChange={(val) =>
                            this.handleThirdPartyOptionChange(val)
                          }
                          defaultValue={
                            this.props.currentAdminProduct &&
                            this.props.currentAdminProduct.thirdPartyOption
                              ? this.props.currentAdminProduct.thirdPartyOption
                              : thirdPartyEnums.NORMAL
                          }
                          options={Object.values(thirdPartyEnums).map(
                            (val) => ({
                              label: val,
                              value: val,
                            })
                          )}
                          disabled={!this.createMode}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {(this.props.currentAdminProduct &&
                  this.props.currentAdminProduct.thirdPartyOption
                    ? this.props.currentAdminProduct.thirdPartyOption ===
                      thirdPartyEnums.CHILD
                    : this.state.thirdPartyOption
                    ? this.state.thirdPartyOption === thirdPartyEnums.CHILD
                    : false) && (
                    <div>
                      <div
                        style={{
                          ...styles.rowItem,
                          alignItems: 'baseline',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <div style={styles.title}>PARENT PRODUCTS</div>
                        <Form.Item name="parentProduct" id="parentProduct">
                          <Select
                            style={{ width: 200 }}
                            onChange={(val) => {
                              this.setState({ parentProduct: val });
                            }}
                            disabled={!this.createMode}
                          >
                            {this.props.parentProducts?.map((item) => {
                              return (
                                <Select.Option key={item.id} value={item.id}>
                                  {item.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  )}
                  <div style={{ ...styles.rowItem, width: '100%' }}>
                    <div
                      style={{
                        ...styles.rowItem,
                        width: '100%',
                        alignItems: 'baseline',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <div style={styles.title}>White-list hosts</div>
                      <div style={{ flexGrow: 1 }}>
                        <Form.Item name="apiCallEndpoints">
                          <Select
                            mode="tags"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Please enter hostnames"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <Divider
                    style={{
                      color: '#9a9ca3',
                      fontSize: 14,
                      marginBottom: 40,
                      marginTop: 40,
                    }}
                  />
                  <div style={styles.rowItem}>
                    <div
                      style={{
                        ...styles.rowItem,
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Button
                        type="primary"
                        shape="round"
                        ghost
                        style={{
                          marginLeft: -10,
                          marginBottom: 30,
                          cursor:
                            usedIntervals.length < 6
                              ? 'pointer'
                              : 'not-allowed',
                        }}
                        onClick={() => {
                          if (usedIntervals.length < 6) {
                            const nextValue = EVENT_DATES.filter(
                              (item) => !usedIntervals.includes(item)
                            )[0];
                            this.setState({}, () => {
                              this.state.eventRateLimits.push({
                                interval: nextValue,
                                threshold: null,
                              });
                              this.setState(
                                {
                                  eventRateLimits: this.state.eventRateLimits,
                                },
                                () => {
                                  this.eventIsField();
                                }
                              );
                            });
                          }
                        }}
                      >
                        ADD EVENT LIMITATION
                      </Button>
                    </div>
                  </div>
                  {this.renderEventRateLimits()}
                  <Divider
                    style={{
                      color: '#9a9ca3',
                      fontSize: 14,
                      marginBottom: 40,
                      marginTop: 40,
                    }}
                  />
                  <div style={styles.rowItem}>
                    <div
                      style={{
                        ...styles.rowItem,
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                      }}
                    ></div>
                  </div>
                  {this.renderChannelsAudienceGrowth()}
                  {this.renderDedicatedResource()}
                  {!productId && (
                    <>
                      <Divider
                        style={{
                          color: '#9a9ca3',
                          fontSize: 14,
                          marginBottom: 40,
                          marginTop: 40,
                        }}
                      />
                      <div style={styles.rowItem}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            marginBottom: 40,
                          }}
                        >
                          <Form.Item
                            name="webPushManagedByCustomer"
                            valuePropName="checked"
                            noStyle
                          >
                            <Checkbox
                              checked={
                                this.state.currentAdminProduct
                                  ?.webPushManagedByCustomer
                              }
                              onChange={(e) => {
                                this.props.adminProductChangeCurrent({
                                  ...this.props.currentAdminProduct,
                                  webPushKeyType: '',
                                  webPushManagedByCustomer: e.target.checked,
                                });
                                this.setState({
                                  activeWebPushKeyType: e.target.checked,
                                });
                              }}
                            >
                              Using custom push
                            </Checkbox>
                          </Form.Item>
                        </div>
                      </div>
                      {this.state.activeWebPushKeyType && (
                        <div style={styles.rowItem}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <div style={{ ...styles.title }}>WEB PUSH TYPE</div>
                            <Form.Item
                              name="webPushKeyType"
                              rules={[{ required: true, message: 'required!' }]}
                            >
                              <Select
                                style={{
                                  width: 120,
                                }}
                              >
                                <Option value="BROWSER">Browser</Option>
                                <Option value="FIREBASE">Firebase</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  <Divider
                    style={{
                      color: '#9a9ca3',
                      fontSize: 14,
                      marginBottom: 20,
                      marginTop: 40,
                    }}
                  />
                  <div
                    style={{
                      width: '100%',
                      fontWeight: 'bold',
                      fontSize: 16,
                      marginBottom: 20,
                      color: '#9a9ca3',
                    }}
                  >
                    Storage limitation
                  </div>
                  <div
                    style={{
                      ...styles.rowItem,
                      width: '100%',
                      flexWrap: 'wrap',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        marginBottom: 40,
                        flexBasis: '33%',
                      }}
                    >
                      <div style={styles.title}>
                        Maximum Events for known users
                      </div>
                      <Form.Item
                        style={{ ...styles.description, width: '100%' }}
                        name="knownMaximumEventCount"
                      >
                        <InputNumber
                          fullWidth
                          style={{ width: '100%' }}
                          min={1}
                        />
                      </Form.Item>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        marginBottom: 40,
                        flexBasis: '33%',
                      }}
                    >
                      <div style={styles.title}>
                        Maximum Events for unknown users
                      </div>
                      <Form.Item
                        style={{ ...styles.description, width: '100%' }}
                        name="unknownMaximumEventCount"
                      >
                        <InputNumber
                          fullWidth
                          style={{ width: '100%' }}
                          min={1}
                        />
                      </Form.Item>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        marginBottom: 40,
                        flexBasis: '33%',
                      }}
                    >
                      <div style={styles.title}>Maximum Device</div>
                      <Form.Item
                        style={{ ...styles.description, width: '100%' }}
                        name="maximumDeviceCount"
                      >
                        <InputNumber
                          fullWidth
                          style={{ width: '100%' }}
                          min={1}
                        />
                      </Form.Item>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        marginBottom: 40,
                        flexBasis: '33%',
                      }}
                    >
                      <div style={styles.title}>
                        Event Retention Days for known users
                      </div>
                      <Form.Item
                        style={{ ...styles.description, width: '100%' }}
                        name="knownEventRetentionDays"
                      >
                        <InputNumber
                          fullWidth
                          style={{ width: '100%' }}
                          min={1}
                        />
                      </Form.Item>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        marginBottom: 40,
                        flexBasis: '33%',
                      }}
                    >
                      <div style={styles.title}>
                        Event Retention Days for unknown users
                      </div>
                      <Form.Item
                        style={{ ...styles.description, width: '100%' }}
                        name="unknownEventRetentionDays"
                      >
                        <InputNumber
                          fullWidth
                          style={{ width: '100%' }}
                          min={1}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <Divider
                    style={{
                      color: '#9a9ca3',
                      fontSize: 14,
                      marginBottom: 20,
                      marginTop: 40,
                    }}
                  />
                  <div
                    style={{
                      width: '100%',
                      fontWeight: 'bold',
                      fontSize: 16,
                      marginBottom: 20,
                      color: '#9a9ca3',
                    }}
                  >
                    Logs and view
                  </div>
                  <div
                    style={{
                      ...styles.rowItem,
                      width: '100%',
                      flexWrap: 'wrap',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        marginBottom: 40,
                        flexBasis: '33%',
                      }}
                    >
                      <div style={styles.title}>Limited Segment Prefix</div>
                      <Form.Item
                        style={{ ...styles.description, width: '100%' }}
                        name="limitedSegmentPrefix"
                      >
                        <Input fullWidth style={{ width: '100%' }} min={1} />
                      </Form.Item>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        marginBottom: 40,
                        flexBasis: '33%',
                      }}
                    >
                      <div style={styles.title}>
                        Watched Event Api Partitions
                      </div>
                      <Form.Item
                        style={{ ...styles.description, width: '100%' }}
                        name="watchedEventApiPartitions"
                      >
                        <Select mode="tags" style={{ width: 200 }} />
                      </Form.Item>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        marginBottom: 40,
                        flexBasis: '33%',
                      }}
                    >
                      <div style={styles.title}>Limited User Ids</div>
                      <Form.Item
                        style={{ ...styles.description, width: '100%' }}
                        name="limitedUserIds"
                      >
                        <Select mode="tags" style={{ width: 200 }} />
                      </Form.Item>
                    </div>
                  </div>

                  <Divider
                    style={{
                      color: '#9a9ca3',
                      fontSize: 14,
                      marginBottom: 20,
                      marginTop: 40,
                    }}
                  />
                  <div
                    style={{
                      width: '100%',
                    }}
                  >
                    <Form.List name="demoConfigurations">
                      {(fields, { add, remove }) => (
                        <>
                          <Form.Item>
                            <Button
                              type="primary"
                              shape="round"
                              ghost
                              onClick={() => {
                                add({
                                  enabled: true,
                                });
                              }}
                            >
                              ADD DEMO CONFIGURATION
                            </Button>
                          </Form.Item>
                          {fields.map(({ key, name, ...restField }) => (
                            <div
                              key={key}
                              style={{
                                display: 'flex',
                                marginBottom: 8,
                                flexWrap: 'wrap',
                              }}
                            >
                              <div style={{ width: '100%', display: 'flex' }}>
                                <Form.Item
                                  {...restField}
                                  label="Enabled"
                                  style={{ width: '200px' }}
                                  name={[name, 'enabled']}
                                >
                                  <Switch
                                    defaultChecked={
                                      this.props?.currentAdminProduct
                                        ?.demoConfigurations &&
                                      this.props.currentAdminProduct
                                        .demoConfigurations[name].enabled
                                    }
                                  />
                                </Form.Item>

                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <DeleteOutlined
                                    style={{
                                      fontSize: 20,
                                      marginTop: 5,
                                      marginLeft: 20,
                                      cursor: 'pointer',
                                      color: '#9a9ca3',
                                    }}
                                    onClick={() => remove(name)}
                                  />
                                  <i
                                    style={{
                                      marginTop: 4,
                                      cursor: 'pointer',
                                      border: '2px solid',
                                      borderRadius: '50%',
                                      fontSize: '20px',
                                      height: '25px',
                                      marginLeft: 10,
                                    }}
                                    onClick={() => {
                                      add({
                                        enabled: true,
                                      });
                                    }}
                                    className="fl-dark fl-add"
                                  ></i>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'flex-start',
                                  justifyContent: 'flex-start',
                                  flexBasis: '50%',
                                }}
                              >
                                <div style={styles.title}>
                                  Target Product ID
                                </div>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'demoProductId']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Missing field',
                                    },
                                  ]}
                                >
                                  <InputNumber style={{ width: 200 }} />
                                </Form.Item>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'flex-start',
                                  justifyContent: 'flex-start',
                                  flexBasis: '50%',
                                }}
                              >
                                <div style={styles.title}>Demo API Address</div>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'demoApiAddress']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Missing field',
                                    },
                                  ]}
                                >
                                  <Input
                                    style={{ width: 200 }}
                                    placeholder="https://demo.automauto.ir"
                                  />
                                </Form.Item>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'flex-start',
                                  justifyContent: 'flex-start',
                                  flexBasis: '50%',
                                }}
                              >
                                <div style={styles.title}>Demo API Key</div>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'demoApiKey']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Missing field',
                                    },
                                  ]}
                                >
                                  <Input style={{ width: 200 }} />
                                </Form.Item>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'flex-start',
                                  justifyContent: 'flex-start',
                                  marginBottom: 40,
                                  flexBasis: '50%',
                                }}
                              >
                                <div style={styles.title}>
                                  User Acceptance Terms
                                </div>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'userAcceptanceTerms']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Missing field',
                                    },
                                  ]}
                                >
                                  <Select mode="tags" style={{ width: 200 }} />
                                </Form.Item>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </Form.List>
                  </div>
                </div>
                <Divider
                  style={{
                    color: '#9a9ca3',
                    fontSize: 14,
                    marginBottom: 20,
                    marginTop: 40,
                  }}
                />
                <b>Dedicated Domain URL</b>
                <p style={{ fontSize: '13px', color: 'gray' }}>
                  If a specific URL is defined for this product, add the URL
                  here. This product will be visible only in this <i>URL</i> and
                  in the default domain.
                </p>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    marginBottom: 40,
                    width: '500px',
                  }}
                >
                  <div style={styles.title}>URL</div>
                  <Form.Item
                    style={{ ...styles.description }}
                    name="dedicatedDomainUrl"
                  >
                    <Input />
                  </Form.Item>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 10,
                    paddingBottom: 30,
                  }}
                >
                  <Link to={productId ? '../../list' : 'list'}>
                    <Button
                      type="primary"
                      shape="round"
                      size={'large'}
                      className="w-200"
                    >
                      {'Back'}
                    </Button>
                  </Link>
                  <Button
                    loading={this.props.submitLoading}
                    type="primary"
                    shape="round"
                    size={'large'}
                    className="w-200"
                    htmlType="submit"
                  >
                    {'Submit'}
                  </Button>
                </div>
              </FormBase>
            </React.Fragment>
          )}
        </Card>
      </div>
    );
  }
}

export default AdminProductUpdateComponent;
