exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ---blue---*/\n/* ---light theme---*/\n/* ---status---*/\n.CsvButton-module__headerCsvBtn--27uEf {\n  margin-left: 10px;\n  font-size: 12px !important;\n  padding: 4px !important;\n  color: #13c2c2;\n  cursor: pointer;\n}\n.CsvButton-module__headerCsvBtn--27uEf span :nth-of-type(1) {\n  margin-right: 2px;\n  font-size: 15px;\n}\n.CsvButton-module__headerCsvBtn--27uEf span :nth-of-type(2) {\n  font-size: 14px;\n}\n.CsvButton-module__headerCsvBtn--27uEf :hover {\n  color: #13c2c2;\n}\n", ""]);

// exports
exports.locals = {
	"headerCsvBtn": "CsvButton-module__headerCsvBtn--27uEf"
};