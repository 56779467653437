import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Layout, Menu, Select, Tooltip } from 'antd';
import { history } from '../../../../redux/store';
import vars from '../../../../theme/styles/vars';
import { getProductId } from '../../../../utils/AuthorityProvider';

import style from './SiderComponent.module.less';
import routes from '../../../../routes';
import { detectFlavor, flavors } from '@Utils/FlavorUtils';
import { thirdPartyEnums } from '@Modules/admin/thirdParty/components/ThirdPartyTypes';
import { getProduct } from '@Utils/getProduct';

const { Sider } = Layout;

const menuIds = {
  users: 'users',
  dataManagement: 'data-management',
  uploadData: 'upload-data',
  alerts: 'alerts',
  pushNotification: 'push-notifications',
  smsMessages: 'sms-messages',
  webPush: 'web-notifications',
  emails: 'emails',
  auditLog: 'auditLog',
  staticSegment: 'segments/static',
  liveSegment: 'segments/live',
  journeys: 'journeys',
  journey: 'journey',
  relayes: 'relays',
  relay: 'relay',
  settings: 'settings',
  channels: 'channels',
  restApi: 'rest-api',
  sdk: 'sdk',
  webhooks: 'webhooks',
  overview: 'engagement/overview',
  eventAnalytics: 'event-analytics',
  customChannel: 'custom-channel',
  inApp: 'in-app',
  onSite: 'onSiteChannel',
  accountProfile: 'profile/update',
  roles: 'role/list',
  userList: 'user/list',
  fileStorage: 'file-storage',
  updateConfiguration: 'configuration/update',
  managementDashboard: 'management-dashboard',
  createProduct: 'create-product',
  manageProducts: 'adminProduct/list',
  thirdParty: 'third-party/list',
  releaseNote: 'releaseNote',
  funnel: 'funnels',
  cohort: 'cohorts',
  uninstalls: 'uninstalls',
  teamMember: 'team/members',
  projectContract: 'contract',
};

let theme = {};
switch (detectFlavor()) {
  case flavors.usermost:
    theme.logo = vars.logoMenu;
    theme.closeLogo = vars.logoMenuClose;
    break;
  case flavors.automauto:
    theme.logo = vars.logoMenu;
    theme.closeLogo = vars.logoMenuClose;
    break;
  case flavors.DK:
    theme.logo = vars.logoMenuDk;
    theme.closeLogo = vars.logoMenuCloseDk;
    break;
  case flavors.MCI:
    theme.logo = vars.logoMenuMci;
    theme.closeLogo = vars.logoMenuCloseMci;
    break;
  case flavors.SNAPP:
    theme.logo = vars.logoMenuSnapp;
    theme.closeLogo = vars.logoMenuCloseSnapp;
    break;
  case flavors.OZONE:
    theme.logo = vars.logoMenuOzone;
    theme.closeLogo = vars.logoMenuCloseOzone;
    break;
  case flavors.NOVATO:
    theme.logo = vars.logoMenuNovato;
    theme.closeLogo = vars.logoMenuCloseNovato;
}

const styles = {
  logoWhite: {
    background: theme.logo,
  },

  logoWhiteClose: {
    background: theme.closeLogo,
  },
  sideMenuContainer: {
    display: 'inline-block',
    position: 'relative',
  },
  productSelector: {
    width: 184,
    color: '#ffffff',
  },
  subMenu: {
    fontSize: 12,
    // backgroundColor: '#1c1c1c',
  },
  subMenuLast: {
    fontSize: 12,
    margin: 0,
    // backgroundColor: '#1c1c1c',
  },
  subMenuClose: {
    margin: 0,
    height: 0,
  },
  menuContainer: {
    borderLeft: '3px solid transparent',
    marginBottom: 0,
    marginTop: 0,
  },
  menuContainerSingle: {
    borderLeft: '3px solid transparent',
    marginBottom: 13,
    marginTop: 12,
  },
  menuContainerSingleSelected: {
    backgroundColor: vars.backgroundColorLight,
    marginBottom: 0,
    marginTop: 0,
    position: 'relative',
    before: {
      position: 'absolute',
      content: '',
      width: 4,
      height: 19,
      backgroundColor: vars.primaryColor,
      right: -1,
      borderRadius: vars.borderRadiusMenu,
    },
  },
  menuContainerSelected: {
    backgroundColor: vars.backgroundColorLight,
    marginBottom: 0,
    marginTop: 0,
    before: {
      position: 'absolute',
      content: '',
      width: 4,
      height: 19,
      backgroundColor: vars.primaryColor,
      right: -1,
      borderRadius: vars.borderRadiusMenu,
    },
  },
  menuText: {
    fontSize: 12,
    color: 'white',
  },
  menuTextSelected: {
    fontSize: 14,
    color: 'white',
  },
  menuDivider: {
    width: '100%',
    height: 1,
    border: '1px solid #333333',
    marginTop: 15,
    marginBottom: 20,
  },
  menuDividerClose: {
    width: '70%',
    marginLeft: '15%',
    marginRight: '15%',
    height: 1,
    border: '1px solid #333333',
    marginTop: 15,
    marginBottom: 0,
  },
  svgIcon: {
    width: 'auto',
    height: 15,
  },
};

class SiderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.closeMenuWidth = 85;
    this.openMenuWidth = 222;
    this.state = {
      openKeys: JSON.parse(localStorage.getItem('openSubMenuKeys')) || [],
    };
  }
  handleSubMenuOpenChange = (keys) => {
    this.setState({ openKeys: keys });
    localStorage.setItem('openSubMenuKeys', JSON.stringify(keys));
  };
  componentDidMount() {
    this.props.currentUserFetch(this.props.pathname);
  }

  getCurrentProductIdKey = () => {
    let pid = getProductId(this.props.pathname);
    if (this.props.activeProduct) {
      return this.props.activeProduct;
    } else if (pid) {
      return pid;
    } else if (this.props.currentUser.lastProduct) {
      return this.props.currentUser.lastProduct;
    }
    return null;
  };

  getSelectedMenu = () => {
    let result = '';
    const pid = getProductId(this.props.pathname);
    if (this.props.pathname.includes(pid + '/' + menuIds.users)) {
      result = menuIds.users;
    } else if (
      this.props.pathname.includes(pid + '/' + menuIds.eventAnalytics)
    ) {
      result = menuIds.eventAnalytics;
    } else if (
      this.props.pathname.includes(pid + '/' + menuIds.dataManagement + '/')
    ) {
      result = menuIds.dataManagement;
    } else if (
      this.props.pathname.includes(pid + '/' + menuIds.channels + '/')
    ) {
      result = menuIds.channels;
    } else if (
      this.props.pathname.includes(pid + '/' + menuIds.pushNotification + '/')
    ) {
      result = menuIds.pushNotification;
    } else if (
      this.props.pathname.includes(pid + '/' + menuIds.smsMessages + '/')
    ) {
      result = menuIds.smsMessages;
    } else if (
      this.props.pathname.includes(pid + '/' + menuIds.webPush + '/')
    ) {
      result = menuIds.webPush;
    } else if (this.props.pathname.includes(pid + '/' + menuIds.emails + '/')) {
      result = menuIds.emails;
    } else if (
      this.props.pathname.includes(pid + '/' + menuIds.customChannel)
    ) {
      result = menuIds.customChannel;
    } else if (this.props.pathname.includes(pid + '/' + menuIds.inApp)) {
      result = menuIds.inApp;
    } else if (this.props.pathname.includes(pid + '/' + menuIds.onSite)) {
      result = menuIds.onSite;
    } else if (
      this.props.pathname.includes(pid + '/' + menuIds.settings + '/')
    ) {
      result = menuIds.settings;
    } else if (
      this.props.pathname.includes(pid + '/' + menuIds.restApi + '/')
    ) {
      result = menuIds.restApi;
    } else if (this.props.pathname.includes(pid + '/' + menuIds.sdk + '/')) {
      result = menuIds.sdk;
    } else if (
      this.props.pathname.includes(pid + '/' + menuIds.liveSegment + '/')
    ) {
      result = menuIds.liveSegment;
    } else if (
      this.props.pathname.includes(pid + '/' + menuIds.staticSegment + '/')
    ) {
      result = menuIds.staticSegment;
    } else if (
      this.props.pathname.includes(pid + '/' + menuIds.journeys + '/') ||
      this.props.pathname.includes(pid + '/' + menuIds.journey + '/')
    ) {
      result = menuIds.journeys;
    } else if (
      this.props.pathname.includes(pid + '/' + menuIds.relayes + '/') ||
      this.props.pathname.includes(pid + '/' + menuIds.relay + '/')
    ) {
      result = menuIds.relayes;
    } else if (
      this.props.pathname.includes(pid + '/' + menuIds.overview + '/')
    ) {
      result = menuIds.overview;
    } else if (
      this.props.pathname.includes(pid + '/' + menuIds.webhooks + '/')
    ) {
      result = menuIds.webhooks;
    } else if (
      this.props.pathname.includes(pid + '/' + menuIds.auditLog + '/')
    ) {
      result = menuIds.auditLog;
    } else if (
      this.props.pathname.includes(pid + '/' + menuIds.uploadData + '/')
    ) {
      result = menuIds.uploadData;
    } else if (this.props.pathname.includes(pid + '/' + menuIds.alerts)) {
      result = menuIds.alerts;
    } else if (this.props.pathname.includes('/' + menuIds.accountProfile)) {
      result = menuIds.accountProfile;
    } else if (this.props.pathname.includes('/' + menuIds.roles)) {
      result = menuIds.roles;
    } else if (this.props.pathname.includes('/' + menuIds.userList)) {
      result = menuIds.userList;
    } else if (this.props.pathname.includes('/' + menuIds.manageProducts)) {
      result = menuIds.manageProducts;
    } else if (
      this.props.pathname.includes(
        '/' + menuIds.thirdParty + '/' + this.getCurrentProductIdKey()
      )
    ) {
      result = menuIds.thirdParty;
    } else if (this.props.pathname.includes(menuIds.teamMember)) {
      result = menuIds.teamMember;
    } else if (this.props.pathname.includes(menuIds.projectContract)) {
      result = menuIds.projectContract;
    } else if (this.props.pathname.includes(menuIds.releaseNote)) {
      result = menuIds.releaseNote;
    } else if (this.props.pathname.includes(pid + '/' + menuIds.funnel + '/')) {
      result = menuIds.funnel;
    } else if (this.props.pathname.includes(pid + '/' + menuIds.cohort + '/')) {
      result = menuIds.cohort;
    } else if (
      this.props.pathname.includes(pid + '/' + menuIds.uninstalls + '/')
    ) {
      result = menuIds.uninstalls;
    } else if (this.props.pathname.includes(menuIds.fileStorage)) {
      result = menuIds.fileStorage;
    } else if (this.props.pathname.includes(menuIds.updateConfiguration)) {
      result = menuIds.updateConfiguration;
    } else if (this.props.pathname.includes(menuIds.managementDashboard)) {
      result = menuIds.managementDashboard;
    }
    return result;
  };

  closeMenu = () => {
    this.props.changeMenuLayout(false);
  };

  openMenu = () => {
    this.props.changeMenuLayout(true);
  };

  changeActiveProductId = (pid) => {
    const product = getProduct(this.props.currentUser, pid);

    if (product.thirdPartyOption === thirdPartyEnums.PARENT) {
      history.push(`/admin/third-party/${pid}/list`);
    } else {
      history.push(`/product/${pid}/users/overview`);
    }
    this.props.updateLastProductSubmit({ lastProduct: pid });
    this.props.changeActiveProduct(pid);
  };

  verticalMenu = (pathname, pid) => {
    const siderRoute = routes(pid, this.props);
    const { openKeys } = this.state;

    return (
      <Menu
        theme="dark"
        className={'side-menu-recolor'}
        style={{ ...styles.sideMenuContainer, marginBottom: '40px' }}
        selectedKeys={[pathname]}
        mode="inline"
        openKeys={openKeys}
        onOpenChange={this.handleSubMenuOpenChange}
        defaultOpenKeys={[]}
        {...(!this.props.isMenuOpen && {
          expandIcon: <React.Fragment />,
        })}
      >
        {siderRoute.navData.map((item, index) =>
          item.subRoutes ? (
            <Menu.SubMenu
              key={item.key}
              title={item.title}
              style={styles.subMenu}
              {...(!this.props.isMenuOpen && {
                className: 'submenu-container-close',
              })}
              disabled={!this.props.currentUser?.emailVerified}
            >
              <>
                {item.subRoutes.map((sub) => (
                  <SideMenuItem
                    key={sub.key}
                    icon={sub.icon}
                    dataTestid={sub.testid}
                    path={sub.path}
                    title={sub.title}
                    classNameItem={style.menuContainerSelected}
                    isMenuOpen={this.props.isMenuOpen}
                    basePath={sub.basePath}
                  />
                ))}
              </>
            </Menu.SubMenu>
          ) : (
            <>
              {item.key === 'update-profile' && (
                <div style={styles.menuDivider}></div>
              )}
              <SideMenuItem
                key={index}
                icon={item.icon}
                dataTestid={item.testid}
                path={item.path}
                title={item.title}
                classNameItem={style.menuContainerSelected}
                clickFn={item.clickFn}
                isMenuOpen={this.props.isMenuOpen}
                basePath={item.basePath}
                disabled={
                  !this.props.currentUser?.emailVerified &&
                  item.path &&
                  item.key !== 'update-profile'
                }
              />
            </>
          )
        )}
      </Menu>
    );
  };

  getUserActiveProduct = (currentUser) => {
    return (
      getProductId(this.props.pathname) ||
      (currentUser && currentUser?.login && currentUser?.lastProduct) ||
      (currentUser?.products &&
        Array.isArray(currentUser?.products) &&
        currentUser?.products.length > 0 &&
        currentUser?.products[0].id)
    );
  };

  render() {
    let pathname = this.props.pathname;
    if (!pathname) {
      pathname = '/overview';
    } else {
      let split = pathname.split('/');
      if (split.length > 3) {
        pathname = '/' + split[1] + '/' + split[2] + '/' + split[3] + '/';
      }
    }
    let pid = this.getUserActiveProduct(this.props.currentUser);

    if (!this.props.currentUser || !this.props.currentUser.login) {
      return <React.Fragment />;
    }
    // if (true) {
    return (
      <Sider
        width={this.props.isMenuOpen ? this.openMenuWidth : this.closeMenuWidth}
        id={'sider_main_menu_left'}
        style={{
          height: `calc(100vh - ${this.props.offset ?? 0}px)`,
          // backgroundColor:
          //   detectFlavor() === flavors.automauto
          //     ? vars.layoutSiderBackground
          //     : vars.layoutSiderBackgroundEnterprise,
          overflow: 'auto',
          position: 'fixed',
          zIndex: 1000,
          top: this.props.offset ?? 0,
        }}
      >
        <React.Fragment>
          <div
            id={
              this.props.isMenuOpen
                ? `${style.logoWhite}`
                : `${style.logoWhiteClose}`
            }
            style={
              this.props.isMenuOpen
                ? { ...styles.logoWhite, height: '50px' }
                : styles.logoWhiteClose
            }
          />
          <div
            className={
              this.props.isMenuOpen
                ? style.productSelectorContainerOpenMenu
                : style.productSelectorContainer
            }
          >
            {this.props.currentUser &&
              this.props.currentUser.products &&
              this.props.currentUser.products.length > 0 && (
                <React.Fragment>
                  {this.props.isMenuOpen ? (
                    <Select
                      showSearch
                      value={this.getCurrentProductIdKey()}
                      onChange={(productId) => {
                        this.changeActiveProductId(productId);
                      }}
                      placeholder={'Search by product name'}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      bordered={false}
                      className={'project-selector'}
                      style={styles.productSelector}
                      getPopupContainer={(trigger) => trigger.parentElement}
                    >
                      {this.props.currentUser.products.map((x) => (
                        <Select.Option value={x.id} key={`/product/${x.id}/`}>
                          {x.name}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <span
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '17px',
                        marginTop: '90px',
                      }}
                    >
                      {this.props.currentUser.products
                        .find((p) => p.id === this.getCurrentProductIdKey())
                        .name.split('-')
                        .map((name) => name[0])
                        .join('-')
                        .toUpperCase()}
                    </span>
                  )}
                </React.Fragment>
              )}
          </div>
          {this.verticalMenu(pathname, pid)}
        </React.Fragment>
      </Sider>
    );
  }
}

export function SideMenuItem({
  icon,
  dataTestid,
  path,
  title,
  clickFn,
  isMenuOpen,
  basePath,
  disabled = false,
}) {
  const { pathname } = useLocation();
  return (
    <>
      {path && !disabled ? (
        <NavLink
          to={path}
          activeClassName={'active-menu'}
          isActive={() => pathname?.includes(basePath)}
        >
          <Menu.Item
            icon={
              <img src={icon} style={{ width: 16, height: 'auto' }} alt="" />
            }
            data-testid={dataTestid}
            key={path}
            style={{ paddingLeft: 16 }}
          >
            {isMenuOpen ? (
              <span>{title}</span>
            ) : (
              <Tooltip placement={'right'} title={title}>
                <Link></Link>
              </Tooltip>
            )}
          </Menu.Item>
        </NavLink>
      ) : (
        <Menu.Item
          disabled={disabled}
          icon={<img src={icon} style={{ width: 16, height: 'auto' }} alt="" />}
          data-testid={dataTestid}
          key={path}
          onClick={clickFn}
          style={{ height: 40, paddingLeft: 16 }}
        >
          {isMenuOpen ? (
            <span>{title}</span>
          ) : (
            <Tooltip placement={'right'} title={title}>
              <Link></Link>
            </Tooltip>
          )}
        </Menu.Item>
      )}
    </>
  );
}
export default SiderComponent;
