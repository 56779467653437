import { history } from '../../../redux/store';
import { showMessageError } from '../../../utils/RenderUtils';
import {
  AUTH_CURRENT_USER_FETCH,
  AUTH_CURRENT_USER_FETCH_FAIL,
  AUTH_CURRENT_USER_FETCH_SUCCESS,
  AUTH_LOGIN_CHANGE_CURRENT,
  AUTH_LOGIN_FETCH,
  AUTH_LOGIN_FETCH_FAIL,
  AUTH_LOGIN_FETCH_SUCCESS,
  AUTH_REGISTER_FETCH,
  AUTH_REGISTER_FETCH_FAIL,
  AUTH_REGISTER_FETCH_SUCCESS,
  AUTH_FORGOT_FETCH,
  AUTH_FORGOT_FETCH_FAIL,
  AUTH_FORGOT_FETCH_SUCCESS,
  AUTH_FORGOT_SUBMIT_FETCH,
  AUTH_FORGOT_SUBMIT_FETCH_FAIL,
  AUTH_FORGOT_SUBMIT_FETCH_SUCCESS,
  // AUTH_LOGOUT_FETCH,
  AUTH_LOGOUT_FETCH_FAIL,
  // AUTH_LOGOUT_FETCH_SUCCESS,
  CHANGE_MENU_LAYOUT,
  CHANGE_ACTIVE_PRODUCT,
  AUTH_VERIFICATION_CODE,
  AUTH_LOGIN_SECOND_STEP,
  AUTH_LOADING_RECAPTCHA,
} from './AuthConstants';
import AuthApi from './AuthApi';
import { CAPTCHA_TOKEN, CAPTCHA_ENABLED } from './AuthConstants';
import { thirdPartyEnums } from '@Modules/admin/thirdParty/components/ThirdPartyTypes';

export function loadCurrentUserFetch(dispatch, path = '') {
  dispatch({
    type: AUTH_CURRENT_USER_FETCH,
    payload: {},
  });
  AuthApi.currentUser()
    .then((response) => {
      if (!response?.login && !['/', '/register'].includes(path)) {
        history.replace({
          pathname: '/',
          search: history.location.search,
          state: history.location.state,
        });
      }
      dispatch({
        type: AUTH_CURRENT_USER_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: AUTH_CURRENT_USER_FETCH_FAIL,
        payload: response,
      });
    });
}

export function currentUserFetch(path) {
  return (dispatch) => {
    loadCurrentUserFetch(dispatch, path);
  };
}

export function loginChangeCurrent(login) {
  return (dispatch) => {
    dispatch({
      type: AUTH_LOGIN_CHANGE_CURRENT,
      payload: login,
    });
  };
}

export function changeMenuLayout(layout) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_MENU_LAYOUT,
      payload: layout,
    });
  };
}

export function changeActiveProduct(pid) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_ACTIVE_PRODUCT,
      payload: pid,
    });
  };
}

export function loginFetch({ login, cb, resendCaptcha }) {
  return (dispatch) => {
    dispatch({
      type: AUTH_LOGIN_FETCH,
      payload: login,
    });
    AuthApi.login(login)
      .then((response) => {
        dispatch({
          type: AUTH_LOGIN_FETCH_SUCCESS,
          payload: response,
        });
        if (response?.login === false && response.emailVerified === true) {
          cb &&
            cb({
              otpId: response.otpId,
            });
        } else if (response?.login === true) {
          window?.autoMauto?.identify({
            userId: `automauto-user-${response?.userId}`,
            email: response?.email,
          });
          const captcha = document.querySelector('.grecaptcha-badge');
          if (captcha) {
            captcha.style.visibility = 'hidden';
          }

          if (response?.emailVerified === false) {
            history.push(`/account/profile/update`);
          }
        }
      })
      .catch((response) => {
        dispatch({
          type: AUTH_LOGIN_FETCH_FAIL,
          payload: response,
        });
        resendCaptcha && resendCaptcha();
        showMessageError(response);
      });
  };
}

export function loginSecondStepFetch({ body, returnUrl }) {
  return (dispatch) => {
    dispatch({
      type: AUTH_LOGIN_SECOND_STEP,
      payload: { loading: true },
    });
    AuthApi.loginSecondStepByOTP(body)
      .then((response) => {
        dispatch({
          type: AUTH_LOGIN_FETCH_SUCCESS,
          payload: response,
        });

        const captcha = document.querySelector('.grecaptcha-badge');
        if (captcha) {
          captcha.style.visibility = 'hidden';
        }
        setUpLandingPage(response, returnUrl);
        sessionStorage.removeItem('timerDuration');
      })
      .catch((response) => {
        showMessageError(response);
      })
      .finally(() => {
        dispatch({
          type: AUTH_LOGIN_SECOND_STEP,
          payload: { loading: false },
        });
      });
  };
}

function setUpLandingPage(response, returnUrl) {
  if (returnUrl) return history.push(returnUrl);

  const productId = response.lastProduct
    ? response.lastProduct
    : response.products[0].id;

  const lastProductObject = response.products.find(
    (x) => x.id === response.lastProduct
  );

  if (lastProductObject.thirdPartyOption === thirdPartyEnums.PARENT) {
    history.push(`/admin/third-party/${productId}/list`);
  } else {
    history.push(`/product/${productId}/users/overview`);
  }
}

export function registerFetch(user) {
  return (dispatch) => {
    dispatch({
      type: AUTH_REGISTER_FETCH,
      payload: user,
    });
    AuthApi.register(user)
      .then((response) => {
        dispatch({
          type: AUTH_REGISTER_FETCH_SUCCESS,
          payload: response,
        });
        history.push('/');
      })
      .catch((response) => {
        dispatch({
          type: AUTH_REGISTER_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function forgotFetch(user) {
  return (dispatch) => {
    dispatch({
      type: AUTH_FORGOT_FETCH,
      payload: user,
    });
    AuthApi.forgotPassword(user)
      .then((response) => {
        dispatch({
          type: AUTH_FORGOT_FETCH_SUCCESS,
          payload: response,
        });
        history.push('/');
      })
      .catch((response) => {
        dispatch({
          type: AUTH_FORGOT_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function forgotSubmitFetch(user) {
  return (dispatch) => {
    dispatch({
      type: AUTH_FORGOT_SUBMIT_FETCH,
      payload: user,
    });
    AuthApi.forgotPasswordSubmit(user)
      .then((response) => {
        dispatch({
          type: AUTH_FORGOT_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        history.push('/');
      })
      .catch((response) => {
        dispatch({
          type: AUTH_FORGOT_SUBMIT_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function logoutFetch(clearStorage) {
  return (dispatch) => {
    // dispatch({
    //   type: AUTH_LOGOUT_FETCH,
    //   payload: {},
    // });
    AuthApi.logout()
      .then(() => {
        // dispatch({
        //   type: AUTH_LOGOUT_FETCH_SUCCESS,
        //   payload: response,
        // });
        // history.push('/');
        if (clearStorage) {
          localStorage.removeItem('path');
        }
        window.location = '/';
        sessionStorage.removeItem('timerDuration');
      })
      .catch((response) => {
        dispatch({
          type: AUTH_LOGOUT_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function resendOTPCodeFetch(otpId) {
  return (dispatch) => {
    dispatch({
      type: AUTH_VERIFICATION_CODE,
      payload: { loading: true, error: false },
    });
    dispatch({
      type: AUTH_LOGIN_SECOND_STEP,
      payload: { disabled: true },
    });
    AuthApi.resendOtpCode(otpId)
      .then(() => {
        dispatch({
          type: AUTH_VERIFICATION_CODE,
          payload: { loading: false, error: false },
        });
      })
      .catch((response) => {
        showMessageError(response);
        dispatch({
          type: AUTH_VERIFICATION_CODE,
          payload: { loading: false, error: true },
        });
      })
      .finally(() => {
        dispatch({
          type: AUTH_LOGIN_SECOND_STEP,
          payload: { disabled: false },
        });
      });
  };
}

export function getCaptcha(setToken, setCaptchaEnabled) {
  return async (dispatch) => {
    dispatch({
      type: AUTH_LOADING_RECAPTCHA,
      payload: true,
    });
    const captchaImage = document.getElementById('captcha-image');

    AuthApi.captcha()
      .then((response) => {
        if (response.customHeaders[CAPTCHA_ENABLED] === 'true') {
          if (captchaImage !== null) {
            captchaImage.src = response?.imageUrl;
          }
          setToken(response.customHeaders[CAPTCHA_TOKEN]);
          setCaptchaEnabled(true);
        } else {
          setToken(null);
          setCaptchaEnabled(false);
        }
      })
      .catch((error) => {
        showMessageError(error);
      })
      .finally(() => {
        dispatch({
          type: AUTH_LOADING_RECAPTCHA,
          payload: false,
        });
      });
  };
}
