import { Button, Col, Row, Table } from 'antd';
import React, { useEffect } from 'react';
import { currentUserFetch } from '../../../../account/auth/AuthActions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import BlackLogo from '../../../../../../static/images/automauto/automauto-black.svg';
import styles from './downloadInvoice.module.less';
import ProfileCard from './ProfileCard';
import { fetchInvoiceView } from '@Redux/slices/setting/invoiceSlice';
import { profileType, invoiceLable } from './static';
import { convertNumberToWords } from './digitToLetters';
import { convertDate } from '@Utils/DateUtils';
import { numberWithCommas } from '@Utils/RenderUtils';

function ContractDownloadInvoice() {
  const dispatch = useDispatch();

  const { id } = useParams();
  const { vendor, customer } = profileType;

  const invoice = useSelector((state) => state.setting.invoice.invoice);
  const handlePrintButtonClick = () => {
    window.onload = window.print();
  };
  useEffect(() => {
    dispatch(currentUserFetch());
    dispatch(fetchInvoiceView(id));
  }, []);

  const columns = [
    {
      title: invoiceLable.number,
      dataIndex: 'key',
      width: '5%',

      onCell: (_, index) => ({
        colSpan: index === 3 ? 0 : 1,
      }),
    },
    {
      title: invoiceLable.productServices,
      dataIndex: 'name',
      width: '25%',
      onCell: (_, index) => ({
        colSpan: index === 3 ? 5 : 1,
      }),
    },
    {
      title: invoiceLable.quantity,
      dataIndex: 'count',
      width: '5%',
      onCell: (_, index) => ({
        colSpan: index === 3 ? 0 : 1,
      }),
    },
    {
      title: invoiceLable.unit,
      dataIndex: 'unit',
      width: '5%',
      onCell: (_, index) => ({
        colSpan: index === 3 ? 0 : 1,
      }),
    },
    {
      title: invoiceLable.unitPrice,
      dataIndex: 'unitAmount',
      width: '10%',
      onCell: (_, index) => ({
        colSpan: index === 3 ? 0 : 1,
      }),
    },
    {
      title: invoiceLable.totalPrice,
      dataIndex: 'totalAmount',
      width: '10%',
    },
    {
      title: invoiceLable.discountAmount,
      dataIndex: 'discount',
      width: '10%',
    },
    {
      title: invoiceLable.totalDiscountedPrice,
      dataIndex: 'totalAmountAfterDiscount',
      width: '10%',
    },
    {
      title: invoiceLable.taxCharges,
      dataIndex: 'taxes',
      width: '10%',
    },
    {
      title: invoiceLable.totalPriceAfterTax,
      dataIndex: 'totalAmountPlusTaxesFees',
      width: '10%',
    },
  ];

  const data = [
    {
      key: '1',
      name: invoiceLable.automauto,
      count: 1,
      unit: invoiceLable.month,
      unitAmount: numberWithCommas(invoice?.basePriceDetails?.price),
      totalAmount: numberWithCommas(invoice?.basePriceDetails?.price),
      discount: numberWithCommas(invoice?.basePriceDetails?.discount),
      totalAmountAfterDiscount: numberWithCommas(
        invoice?.basePriceDetails?.discountedPrice
      ),
      taxes: numberWithCommas(invoice?.basePriceDetails?.vat),
      totalAmountPlusTaxesFees: numberWithCommas(
        invoice?.basePriceDetails?.finalPrice
      ),
    },
    {
      key: '2',
      name: invoiceLable.events,
      count: invoice.eventsCount,
      unit: invoiceLable.eventNumber,
      unitAmount: numberWithCommas(invoice.perEventPrice),
      totalAmount: numberWithCommas(invoice?.eventsPriceDetails?.price),
      discount: numberWithCommas(invoice?.eventsPriceDetails?.discount),
      totalAmountAfterDiscount: numberWithCommas(
        invoice?.eventsPriceDetails?.discountedPrice
      ),
      taxes: numberWithCommas(invoice?.eventsPriceDetails?.vat),
      totalAmountPlusTaxesFees: numberWithCommas(
        invoice?.eventsPriceDetails?.finalPrice
      ),
    },
    {
      key: '3',
    },
    {
      key: '4',
      name: invoiceLable.total,
      totalAmount: numberWithCommas(invoice?.totalPrice),
      discount: numberWithCommas(invoice?.totalDiscount),
      totalAmountAfterDiscount: numberWithCommas(invoice?.totalDiscountedPrice),
      taxes: numberWithCommas(invoice.totalVat),
      totalAmountPlusTaxesFees: numberWithCommas(invoice.finalPrice),
    },
  ];
  return (
    <div id="invoice" className={styles.invoice}>
      <Row justify="space-between" className={styles.page_header} id="noPrint">
        <Col span={4}>
          <img src={BlackLogo} />
        </Col>
        <Col span={4} style={{ textAlign: 'left' }}>
          <Button shape="round" type="primary" onClick={handlePrintButtonClick}>
            download / Print
          </Button>
        </Col>
      </Row>

      <div className={styles.invoiceInfo}>
        <InvoiceHeader invoice={invoice} />
        <ProfileCard type={vendor} />
        <ProfileCard type={customer} contract={invoice?.contract} />
        <Row>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '16px 16px 32px',
            }}
          >
            <span>{invoiceLable.productDetails}</span>
          </Col>
        </Row>
        <Table
          className="table-invoice"
          columns={columns}
          dataSource={data}
          bordered
          pagination={false}
          size="small"
        />
        <Row className={styles.invoiceDescription}>
          <Col span={10} className={styles.invoiceDescriptionSell}>
            <div>
              <span>{invoiceLable.salesConditions}</span>
              <span style={{ paddingLeft: 4 }}>{invoiceLable.cash} </span>
              <span>{invoiceLable.notCash}</span>
            </div>
          </Col>
          <Col span={14} className={styles.invoiceDescriptionSell}>
            <div>
              <span>{invoiceLable.grandTotal}</span>
              <span>{convertNumberToWords(invoice.finalPrice)}</span>
              <span style={{ paddingRight: 4 }}>{invoiceLable.rial}</span>
            </div>
          </Col>
        </Row>
        <div className={styles.invoiceNotes}>
          <span>{invoiceLable.description}</span>
          <span>{invoice.notes}</span>
        </div>
        <Row className={styles.invoiceSign}>
          <Col span={12}>
            <span>{invoiceLable.sellerSignature}</span>
          </Col>
          <Col span={12}>
            <span>{invoiceLable.buyerSignature}</span>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const InvoiceHeader = ({ invoice }) => {
  const { endDate, id } = invoice;

  return (
    <Row justify="space-between" className={styles.invoice_header}>
      <Col span={4}>
        <img src={BlackLogo} />
      </Col>
      <Col span={10} className={styles.invoice_headerTitle}>
        <span>{invoiceLable.invoiceTitle}</span>
      </Col>
      <Col span={4}>
        <Row className={styles.invoice_headerInfo}>
          <Col span={24}>
            <span>{invoiceLable.invoiceCode}</span>
            <span>{id}</span>
          </Col>
          <Col span={24} style={{ display: 'flex' }}>
            <span>{invoiceLable.invoiceDate}</span>
            <div>{endDate && convertDate(endDate, 'fa')}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ContractDownloadInvoice;
