exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ---blue---*/\n/* ---light theme---*/\n/* ---status---*/\n#keyValue {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 350px;\n}\n#keyValue .ant-form-item {\n  margin-bottom: 0;\n}\n.channel-module__btnDocuments--Db5Wa {\n  position: absolute !important;\n  left: 4px !important;\n  color: #1b1b1d !important;\n}\n.channel-module__serviceProviderContainer--1svaD {\n  display: flex;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  align-items: center;\n  padding: 10px;\n}\n.channel-module__serviceProviderContainer--1svaD .channel-module__serviceProviderBox--8WzxJ {\n  border: 1px solid #f2f2f2;\n  border-radius: 8px;\n  margin-right: 10px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-evenly;\n  margin-top: 10px;\n  height: 140px;\n  width: 140px;\n  cursor: pointer;\n}\n.channel-module__serviceProviderContainer--1svaD .channel-module__serviceProviderBox--8WzxJ img {\n  width: auto;\n  height: 50px;\n}\n", ""]);

// exports
exports.locals = {
	"btnDocuments": "channel-module__btnDocuments--Db5Wa",
	"serviceProviderContainer": "channel-module__serviceProviderContainer--1svaD",
	"serviceProviderBox": "channel-module__serviceProviderBox--8WzxJ"
};