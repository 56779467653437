exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ---blue---*/\n/* ---light theme---*/\n/* ---status---*/\n#backOfficeUploadReceipt .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {\n  background-color: #fff !important;\n}\n#backOfficeUploadReceipt .ant-collapse {\n  border-radius: 8px;\n  background-color: transparent;\n}\n#backOfficeUploadReceipt .ant-collapse-header {\n  display: flex;\n  align-items: center;\n}\n#backOfficeUploadReceipt .ant-collapse > .ant-collapse-item:last-child,\n#backOfficeUploadReceipt .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {\n  border-radius: 8px;\n}\n.BackOfficeConfirmationReceiptModalContent-module__header--1w21h {\n  font-size: 12px;\n  color: #38373b;\n}\n.BackOfficeConfirmationReceiptModalContent-module__title--1dG8r {\n  font-weight: 600;\n  color: #38373b;\n  margin-top: 8px;\n}\n.BackOfficeConfirmationReceiptModalContent-module__inputLabel--2aH7i {\n  font-weight: 500;\n  color: #38373b;\n  margin-bottom: 8px;\n}\n.BackOfficeConfirmationReceiptModalContent-module__uploadBox--19xX- {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.BackOfficeConfirmationReceiptModalContent-module__panelTitle--2nIAu {\n  font-weight: 600;\n  color: #38373b;\n  align-items: center;\n  display: flex;\n}\n.BackOfficeConfirmationReceiptModalContent-module__customPanel--1jEYk {\n  background: #f7f7f7;\n  border-radius: 4px;\n  margin-bottom: 24px;\n  overflow: hidden;\n}\n", ""]);

// exports
exports.locals = {
	"header": "BackOfficeConfirmationReceiptModalContent-module__header--1w21h",
	"title": "BackOfficeConfirmationReceiptModalContent-module__title--1dG8r",
	"inputLabel": "BackOfficeConfirmationReceiptModalContent-module__inputLabel--2aH7i",
	"uploadBox": "BackOfficeConfirmationReceiptModalContent-module__uploadBox--19xX-",
	"panelTitle": "BackOfficeConfirmationReceiptModalContent-module__panelTitle--2nIAu",
	"customPanel": "BackOfficeConfirmationReceiptModalContent-module__customPanel--1jEYk"
};