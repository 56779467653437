import React from 'react'; // eslint-disable-line
import App from './modules/App';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './redux/store';
import 'toastr/build/toastr.min.css';
import './images/favicon.png';
import './theme/styles/style.less';

const target = document.querySelector('#root');

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  target
);
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-undef
  module.hot.accept();
}
