/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
import React from 'react';
import { useState, useEffect } from 'react';
import '../style/ProductSdkAndroidStyle.less';
import ProductSdkTabItem from './fragments/ProductSdkTabItem';
import SetupFragment from './fragments/SetupFragment';
import TrackEventFragment from './fragments/TrackEventFragment';
import TrackUserFragment from './fragments/TrackUserFragment';
import PushFragment from './fragments/PushFragment';
import InitializeFragment from './fragments/InitializeFragment';
import ProductSdkButton from './fragments/ProductSdkButton';
import { Select, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import texts from '../../../../utils/texts';
import { useSelector } from 'react-redux';
import Card from '@Utils/Card';
import {
  featureAccessCheckSDKWeb,
  featureAccessCheckSDKAndroid,
  featureAccessCheckSDKIOS,
  featureAccessCheckSDKNative,
  featureAccessCheckSDKFlutter,
} from '@Utils/AccessManagement';

const ProductSdkViewComponent = (props) => {
  const currentUser = useSelector((state) => state.account.auth.currentUser);
  const productId = props.match?.params?.productId;
  const currentProduct = currentUser?.products?.find(
    (p) => p.id === +productId
  );
  useEffect(() => {
    props.productSdkAndroidViewFetch();
    props.productSdkWebViewFetch();
    props.productSdkIosViewFetch();
  }, []);

  const setSelectedItem = () => {
    if (featureAccessCheckSDKWeb()) {
      return 'web';
    } else if (featureAccessCheckSDKAndroid()) {
      return 'android';
    } else if (featureAccessCheckSDKIOS()) {
      return 'ios';
    } else if (featureAccessCheckSDKNative()) {
      return 'react-native';
    } else if (featureAccessCheckSDKFlutter()) {
      return 'flutter';
    }
    return '';
  };

  const sdkTabList = ['setup', 'initialize', 'user', 'event', 'push'];
  const [currentLevel, setCurrentLevel] = useState('setup');
  const [currentSdkType, setSdkType] = useState(setSelectedItem());

  const goToNext = () => {
    if (currentLevel === 'setup') {
      setCurrentLevel('initialize');
    } else if (currentLevel === 'initialize') {
      setCurrentLevel('user');
    } else if (currentLevel === 'user') {
      setCurrentLevel('event');
    } else if (currentLevel === 'event') {
      setCurrentLevel('push');
    }
  };
  const gotoPrevious = () => {
    if (currentLevel === 'push') {
      setCurrentLevel('event');
    } else if (currentLevel === 'event') {
      setCurrentLevel('user');
    } else if (currentLevel === 'user') {
      setCurrentLevel('initialize');
    } else if (currentLevel === 'initialize') {
      setCurrentLevel('setup');
    }
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 35 }} spin />;
  const mobileAppKey =
    props.currentProductSdkAndroid && props.currentProductSdkAndroid.licenceCode
      ? props.currentProductSdkAndroid.licenceCode
      : 'YOUR_PROJECT_ID';
  const mobileAuthKey =
    props.currentProductSdkAndroid && props.currentProductSdkAndroid.androidKey
      ? props.currentProductSdkAndroid.androidKey
      : 'YOUR_PROJECT_KEY';
  const webAppKey =
    props.currentProductSdkWeb && props.currentProductSdkWeb.licenceCode
      ? props.currentProductSdkWeb.licenceCode
      : 'YOUR_PROJECT_ID';
  const webPublicKey =
    props.currentProductSdkWeb && props.currentProductSdkWeb.webPublicKey
      ? props.currentProductSdkWeb.webPublicKey
      : 'YOUR_PUBLIC_KEY';
  const webAuthKey =
    props.currentProductSdkWeb && props.currentProductSdkWeb.webKey
      ? props.currentProductSdkWeb.webKey
      : 'YOUR_PROJECT_KEY';
  const iosAppkey =
    props.currentProductSdkIos?.licenceCode || 'YOUR_PUBLIC_KEY';
  const iosAuthKey = props.currentProductSdkIos?.iosKey || 'YOUR_PROJECT_KEY';
  return (
    <div>
      <Card>
        <div className="row-content">
          <div>
            <span className="header-left-item">Integration Guide for:</span>
            <Select
              onChange={(type) => setSdkType(type)}
              value={currentSdkType}
              style={{ width: 240 }}
              className={'chart-selector'}
              bordered={false}
            >
              {texts.id === 'um' && featureAccessCheckSDKWeb() ? (
                <Select.Option value={'web'}>Web SDK</Select.Option>
              ) : (
                <>
                  {featureAccessCheckSDKNative() && (
                    <Select.Option value={'react-native'}>
                      React Native SDK
                    </Select.Option>
                  )}
                  {featureAccessCheckSDKFlutter() && (
                    <Select.Option value={'flutter'}>Flutter SDK</Select.Option>
                  )}
                  {featureAccessCheckSDKAndroid() && (
                    <Select.Option value={'android'}>Android SDK</Select.Option>
                  )}
                  {featureAccessCheckSDKIOS() && (
                    <Select.Option value={'ios'}>IOS SDK</Select.Option>
                  )}
                  {featureAccessCheckSDKWeb() && (
                    <Select.Option value={'web'}>Web SDK</Select.Option>
                  )}
                </>
              )}
            </Select>
          </div>
          <div>
            <a href={texts.docs} target="_blank" rel="noreferrer">
              <span className="header-right-item">VIEW DOCUMENTATION</span>
            </a>
          </div>
        </div>

        <div>
          <div className="raman-paper-profile" style={{ background: 'none' }}>
            <div className="levels-container">
              {sdkTabList.map((items, i) => (
                <ProductSdkTabItem
                  key={i}
                  arrayFinished={items === 'push' ? false : true}
                  tabNames={items}
                  onClick={() => setCurrentLevel(items)}
                  className={
                    currentLevel === items ? 'selected-level' : 'normal-level'
                  }
                >
                  {items === 'setup'
                    ? 'SetUp'
                    : items === 'initialize'
                    ? 'Initialize'
                    : items === 'user'
                    ? 'Track User'
                    : items === 'event'
                    ? 'Track Event'
                    : 'Push'}
                </ProductSdkTabItem>
              ))}
            </div>
            {currentLevel === 'setup' && (
              <SetupFragment currentSdkType={currentSdkType} />
            )}
            {currentLevel === 'initialize' &&
              (props.loadingProductSdkAndroidView ||
                props.loadingProductSdkWebView) && (
                <div className="spin-style">
                  <Spin indicator={antIcon} />
                </div>
              )}
            {currentLevel === 'initialize' &&
              !props.loadingProductSdkAndroidView &&
              !props.loadingProductSdkWebView && (
                <InitializeFragment
                  sdkType={currentSdkType}
                  appKey={
                    currentSdkType === 'web'
                      ? webAppKey
                      : currentSdkType === 'ios'
                      ? iosAppkey
                      : mobileAppKey
                  }
                  authKey={
                    currentSdkType === 'web'
                      ? webAuthKey
                      : currentSdkType === 'ios'
                      ? iosAuthKey
                      : mobileAuthKey
                  }
                  publicKey={currentSdkType === 'web' ? webPublicKey : null}
                  iosAuthKey={iosAuthKey}
                  webPushKeyType={
                    currentProduct?.webPushConfigs?.webPushKeyType
                  }
                  managedByCustomer={
                    currentProduct?.webPushConfigs?.managedByCustomer
                  }
                />
              )}
            {currentLevel === 'user' && (
              <TrackUserFragment sdkType={currentSdkType} />
            )}
            {currentLevel === 'event' && (
              <TrackEventFragment sdkType={currentSdkType} />
            )}
            {currentLevel === 'push' && (
              <PushFragment
                sdkType={currentSdkType}
                webPushKeyType={currentProduct?.webPushConfigs?.webPushKeyType}
                managedByCustomer={
                  currentProduct?.webPushConfigs?.managedByCustomer
                }
              />
            )}
            <div
              className={
                currentLevel === 'setup'
                  ? 'footer-right'
                  : currentLevel === 'push'
                  ? 'footer-left'
                  : 'footer-space-between '
              }
            >
              {currentLevel !== 'setup' && (
                <ProductSdkButton onClick={gotoPrevious}>
                  {' '}
                  BACK
                </ProductSdkButton>
              )}

              {currentLevel !== 'push' && (
                <ProductSdkButton onClick={goToNext}>NEXT</ProductSdkButton>
              )}
            </div>
          </div>{' '}
        </div>
      </Card>
    </div>
  );
};
export default ProductSdkViewComponent;
