exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ---blue---*/\n/* ---light theme---*/\n/* ---status---*/\n.uploadEventData-module__root--2sZVM {\n  background-color: white;\n  border-radius: 5px;\n  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);\n}\n.uploadEventData-module__header--2Js3p {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  padding: 24px;\n  border-bottom: solid 2px #f4f9ff;\n}\n.uploadEventData-module__headerTitle--2wmxr {\n  font-size: 18px;\n  font-weight: bold;\n}\n.uploadEventData-module__headerDocs--1IhjA a {\n  color: #13c2c2 !important;\n  font-size: 12px;\n}\n.uploadEventData-module__content--1EAEh {\n  padding: 30px;\n}\n.uploadEventData-module__contentInput--1kTVh {\n  display: none;\n}\n.uploadEventData-module__contentButton--1YpJU {\n  cursor: pointer;\n  display: inline-block;\n  margin-top: 30px;\n  border: 2px solid #13c2c2 !important;\n  color: #13c2c2 !important;\n  padding: 0 28px !important;\n  border-radius: 20px !important;\n  font-weight: 600;\n}\n", ""]);

// exports
exports.locals = {
	"root": "uploadEventData-module__root--2sZVM",
	"header": "uploadEventData-module__header--2Js3p",
	"headerTitle": "uploadEventData-module__headerTitle--2wmxr",
	"headerDocs": "uploadEventData-module__headerDocs--1IhjA",
	"content": "uploadEventData-module__content--1EAEh",
	"contentInput": "uploadEventData-module__contentInput--1kTVh",
	"contentButton": "uploadEventData-module__contentButton--1YpJU"
};