import React, { useEffect, useState } from 'react';
import { Select, Tooltip } from 'antd';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { CUSTOM_FONT_OPTIONS } from '../inApp/helpers';
import { SketchPicker } from 'react-color';
import './inlineThemePicker.less';

const fontSizes = Array.from({ length: 10 }, (_, i) => (i + 6) * 2);

function normalizeFont(font) {
  if (!font) return font;

  return font.replace(/\.(ttf|woff|woff2)$/, '');
}

export const InlineThemePicker = ({
  onChange,
  variation,
  customFonts,
  name,
  actionIndex,
  channel,
}) => {
  const { productId } = useParams();

  const fontName =
    name === 'actions'
      ? (variation?.theme?.[name] ?? [])[actionIndex]?.style?.font_family
      : variation?.theme?.[name]?.font_family;

  const fontSize =
    name === 'actions'
      ? (variation?.theme?.[name] ?? [])[actionIndex]?.style?.font_size
      : variation?.theme?.[name]?.font_size;

  const color =
    name === 'actions'
      ? (variation?.theme?.[name] ?? [])[actionIndex]?.style?.color
      : variation?.theme?.[name]?.color;

  const [showSketchPicker, setShowSketchPicker] = useState(false);

  useEffect(() => {
    if (!fontName || !Array.isArray(customFonts)) return;

    const currentFont = customFonts.find(
      (font) => normalizeFont(font?.file?.fileName) === fontName
    );

    if (!currentFont || !currentFont?.file?.publicPath) return;

    const url = currentFont?.file?.publicPath;

    const styleTag = document.createElement('style');

    const head = document.getElementsByTagName('head')[0];

    styleTag.id = fontName;

    styleTag.innerHTML = `
        @font-face {
            font-family: "${fontName}";
            src: url('${url}') format('woff2');
        }
    `;
    head.appendChild(styleTag);

    return () => {
      head.removeChild(styleTag);
      styleTag.remove();
    };
  }, [customFonts, fontName]);

  const handleFontSizeChange = (value) => {
    if (name === 'actions') {
      const actionsList = variation?.theme?.[name] || [];

      actionsList[actionIndex] = {
        ...(variation?.theme?.[name]?.[actionIndex] ?? {}),
        style: {
          ...(variation?.theme?.[name]?.[actionIndex]?.style ?? {}),
          font_size: value,
        },
      };

      onChange?.({
        ...(variation?.theme ?? {}),
        [name]: actionsList,
      });
    } else {
      onChange?.({
        ...(variation?.theme ?? {}),
        [name]: {
          ...(variation?.theme?.[name] ?? {}),
          font_size: value,
        },
      });
    }
  };

  const handleColorChange = (hex) => {
    if (name === 'actions') {
      const actionsList = variation?.theme?.[name] || [];

      actionsList[actionIndex] = {
        ...(variation?.theme?.[name]?.[actionIndex] ?? {}),
        style: {
          ...(variation?.theme?.[name]?.[actionIndex]?.style ?? {}),
          color: hex,
        },
      };

      onChange?.({
        ...(variation?.theme ?? {}),
        [name]: actionsList,
      });
    } else {
      onChange?.({
        ...(variation?.theme ?? {}),
        [name]: {
          ...(variation?.theme?.[name] ?? {}),
          color: hex,
        },
      });
    }
  };

  useEffect(() => {
    if (!fontSize) handleFontSizeChange(16);

    if (!color) handleColorChange('#1b1b1d');
  }, [fontSize, color]);

  return (
    <div className="inline-container">
      {channel !== 'IN_APP' && (
        <Select
          style={{ width: 150 }}
          className="font-selector"
          showSearch
          value={fontName ?? null}
          optionFilterProp="children"
          onChange={(value) => {
            if (value) value = normalizeFont(value);

            if (name === 'actions') {
              const actionsList = variation?.theme?.[name] || [];

              actionsList[actionIndex] = {
                ...(variation?.theme?.[name]?.[actionIndex] ?? {}),
                style: {
                  ...(variation?.theme?.[name]?.[actionIndex]?.style ?? {}),
                  font_family: value,
                },
              };

              onChange?.({
                ...(variation?.theme ?? {}),
                [name]: actionsList,
              });
            } else {
              onChange?.({
                ...(variation?.theme ?? {}),
                [name]: {
                  ...(variation?.theme?.[name] ?? {}),
                  font_family: value,
                },
              });
            }
          }}
        >
          <Select.OptGroup label={<Label productId={productId} />}>
            {customFonts?.map((font) => (
              <Select.Option
                key={font.file.fileName}
                value={font.file.fileName}
              >
                {font.file.fileName}
              </Select.Option>
            ))}
          </Select.OptGroup>

          <Select.OptGroup label="System">
            {CUSTOM_FONT_OPTIONS.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.title}
              </Select.Option>
            ))}
          </Select.OptGroup>
        </Select>
      )}
      <Select
        style={{ width: 60 }}
        className="fontsize-selector"
        value={fontSize ?? 16}
        optionFilterProp="children"
        onChange={(value) => handleFontSizeChange(value)}
      >
        {fontSizes.map((value) => (
          <Select.Option key={value} value={value}>
            {value}
          </Select.Option>
        ))}
      </Select>
      <div
        className="color-placeholder"
        onClick={(e) => {
          e.stopPropagation();
          setShowSketchPicker(true);
        }}
      >
        <div
          className="color-thumb"
          style={{ background: color ?? '#1B1B1D' }}
        ></div>
        <span className="color-text">{color ?? '#1B1B1D'}</span>
        {showSketchPicker && (
          <div className="picker">
            <div
              className="cover"
              onClick={(e) => {
                e.stopPropagation();
                setShowSketchPicker(false);
              }}
            />
            <SketchPicker
              disableAlpha={true}
              color={color ?? '#1b1b1d'}
              onChange={(value) => handleColorChange(value.hex)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const Label = ({ productId }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>Custom</span>
      <Tooltip
        placement={'top'}
        title={
          <div>
            To use a custom font, upload the font in the{' '}
            <Link to={`/product/${productId}/settings/fonts`}>
              <span style={{ width: 200 }}>settings</span>
            </Link>{' '}
            page. Once uploaded, the font will be available for selection here.
          </div>
        }
      >
        <i
          className="fl-info"
          style={{
            color: '#4AA0F8',
            fontSize: 24,
            fontWeight: 400,
          }}
        ></i>
      </Tooltip>
    </div>
  );
};
