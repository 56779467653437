import React, { useContext, useEffect, useState } from 'react';

import { AllTimesType } from './../../utils/static';
import { AppContext } from '../../app';
import {
  Button,
  Form,
  Popconfirm,
  Select,
  Spin,
  TimePicker,
  Tooltip,
  Switch,
} from 'antd';
import {
  LoadingOutlined,
  MinusCircleOutlined,
  InfoCircleOutlined,
  InfoCircleFilled,
} from '@ant-design/icons';
import moment from 'moment';
import { isNil } from 'lodash';
const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;

const styles = {
  wrap: {
    backgroundColor: 'white',
    paddingBottom: 30,
    borderRadius: 5,
  },
  wrap__inner: {
    padding: '20px 10px',
  },
  head: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    paddingBottom: 0,
    paddingLeft: '10px',
    alignItems: 'center',
  },
  select: {
    width: '200px',
  },
  text: {
    fontSize: 13,
    color: '#3d404e',
    marginRight: '20px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 800,
    marginBottom: 20,
    border: '1px solid #eee',
    padding: 10,
  },
  floodGateSection: {
    padding: '0px 20px',
  },
  tooltip: {
    padding: '10px',
  },
  displayFlex: {
    display: 'flex',
    flexDirection: 'row',
  },
  marginRight: {
    marginRight: '30px',
  },
};

const timeZoneOptions = [
  {
    label: 'Project Time Zone(GMT+4:30)',
    value: false,
  },
  {
    label: "User's Time Zone",
    value: true,
  },
];

const FlowWaitForTimeSlot = (props) => {
  const [form] = Form.useForm();
  const { journey, syncCache } = useContext(AppContext);
  const [loading] = useState(false);
  const [timeZone, setTimeZone] = useState(false);
  const [floodGate, setFloodGate] = useState(true);
  const [timeSlots, setTimeSlots] = useState([
    {
      weekDayType: 'DAILY',
      startTime: moment().add(-2, 'hours').format('HH:mm:ss'),
      endTime: moment().format('HH:mm:ss'),
    },
  ]);
  const selectedNode = journey?.steps?.find(
    (item) => item.id + '' === props?.node?.id
  );
  useEffect(() => {
    if (selectedNode) {
      setTimeZone(selectedNode.useUserTimeZone);
      setFloodGate(selectedNode.floodGate);
      if (selectedNode.timeSlots.length) setTimeSlots(selectedNode.timeSlots);
    }
  }, [selectedNode]);

  const onFinish = () => {
    let currentStep = Object.assign({}, selectedNode);
    currentStep['floodGate'] = floodGate;
    currentStep['useUserTimeZone'] = timeZone;
    currentStep['timeSlots'] = timeSlots;
    delete currentStep.ui?.error;

    syncCache('update-step', currentStep, true);
    // openSnackBar("success", {
    //   message: "Successfully updated",
    // });
    props.closeModal();
  };

  const renderTimeTypeItems = () => {
    const allTypes = AllTimesType;
    // return []
    // const selectedDndTypes = [];
    // this.props.dnd.dndTimes.forEach((item) => {
    //     selectedDndTypes.push(item.dndTimeType);
    // });
    // const availableDnd = cloneDeep(allDnd);
    // selectedDndTypes.forEach(item => {
    //     delete availableDnd[item];
    // });
    return (
      <React.Fragment>
        (
        {Object.keys(allTypes).map((type) => (
          <Select.Option value={type} key={type}>
            {allTypes[type]}
          </Select.Option>
        ))}
        )
        {/* {this.getDndType(index) && <Select.Option value={this.getDndType(index)} key={this.getDndType(index)}>{this.getDndType(index)}</Select.Option>} */}
      </React.Fragment>
    );
  };

  const handleChangeValue = (type, value, index) => {
    let timeSlotSelected = timeSlots[index];
    switch (type) {
      case 'weekDayType':
        timeSlotSelected = Object.assign({}, timeSlotSelected, {
          weekDayType: value,
        });
        break;
      case 'startTime':
        timeSlotSelected = Object.assign({}, timeSlotSelected, {
          startTime: value,
        });
        break;
      case 'endTime':
        timeSlotSelected = Object.assign({}, timeSlotSelected, {
          endTime: value,
        });
        break;
      default:
        break;
    }
    // Validation
    if (type === 'startTime' || type === 'endTime') {
      const isStartGreaterThanEnd = moment(
        timeSlotSelected.startTime,
        'HH:mm:ss'
      ).isAfter(moment(timeSlotSelected.endTime, 'HH:mm:ss'));
      const isTimeBetweenLessThanThirtyMinutes =
        moment
          .duration(
            moment(timeSlotSelected.endTime, 'HH:mm:ss').diff(
              moment(timeSlotSelected.startTime, 'HH:mm:ss')
            )
          )
          .asMinutes() < 10;

      if (isStartGreaterThanEnd) {
        timeSlotSelected = Object.assign({}, timeSlotSelected, {
          error: 'From time should be before To time',
        });
      } else if (isTimeBetweenLessThanThirtyMinutes) {
        timeSlotSelected = Object.assign({}, timeSlotSelected, {
          error: 'Minimum time between From and To is 10 minutes',
        });
      } else {
        delete timeSlotSelected.error;
      }
    }
    const tms_ = [...timeSlots];
    tms_[index] = timeSlotSelected;
    setTimeSlots(tms_);
  };

  const handleChangeFloodGate = (e) => {
    setFloodGate(e);
  };

  const handleChangeTimeZone = (e) => {
    setTimeZone(e);
  };

  const addTimeSlot = () => {
    setTimeSlots((old) => [...old, {}]);
  };

  const confirm = (e, index) => {
    setTimeSlots((old) => old.filter((item, i) => i !== index));
  };

  const cancel = () => {};

  const renderTimeSlots = () => {
    const format = 'HH:mm';
    // const times = [1]
    return (
      <div style={styles.wrap}>
        <div style={styles.wrap__inner}>
          {!loading ? (
            timeSlots.map((item, index) => (
              <div style={styles.row} key={index}>
                <div style={styles.displayFlex}>
                  <div style={(styles.displayFlex, styles.marginRight)}>
                    <Select
                      // filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      showSearch
                      onChange={(weekDayType) =>
                        handleChangeValue('weekDayType', weekDayType, index)
                      }
                      value={item.weekDayType}
                      style={{ width: 200 }}
                      className={'chart-selector'}
                      bordered={false}
                    >
                      {renderTimeTypeItems(index)}
                    </Select>
                  </div>
                  <div style={(styles.displayFlex, styles.marginRight)}>
                    <span style={{ marginTop: 3, marginRight: 20 }}>from</span>
                    <TimePicker
                      style={{ width: 150 }}
                      value={
                        isNil(item.startTime)
                          ? null
                          : moment(item.startTime, 'HH:mm:ss')
                      }
                      onChange={(time) =>
                        handleChangeValue(
                          'startTime',
                          isNil(time) ? null : moment(time).format('HH:mm:ss'),
                          index
                        )
                      }
                      format={format}
                    />
                  </div>
                  <div style={styles.displayFlex}>
                    <span style={{ marginTop: 3, marginRight: 20 }}>to </span>
                    <TimePicker
                      style={{ width: 150 }}
                      value={
                        isNil(item.endTime)
                          ? null
                          : moment(item.endTime, 'HH:mm:ss')
                      }
                      onChange={(time) =>
                        handleChangeValue(
                          'endTime',
                          isNil(time) ? null : moment(time).format('HH:mm:ss'),
                          index
                        )
                      }
                      format={format}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: 5, paddingTop: 5 }}>
                  <Popconfirm
                    title="Are you sure delete this slot?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={(e) => confirm(e, index)}
                    onCancel={(e) => cancel(e, index)}
                  >
                    <MinusCircleOutlined
                      style={{ color: '#bbbcc1', fontSize: 20 }}
                    />
                  </Popconfirm>
                  {item.error ? (
                    <Tooltip placement="top" title={item.error}>
                      <InfoCircleFilled
                        style={{ color: 'red', fontSize: 20, paddingLeft: 5 }}
                      />
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ))
          ) : (
            <div style={styles.loadingSpinStyle}>
              <Spin indicator={loadingSpin} />
            </div>
          )}
          <Button
            type="primary"
            shape="round"
            size={'large'}
            ghost
            style={styles.addNewSlotButton}
            onClick={() => addTimeSlot()}
          >
            Add time slot
          </Button>
        </div>
      </div>
    );
  };

  const hasError = timeSlots.find((timeSlot) => timeSlot.error);
  const isAllPropertiesFilled = timeSlots.every((slot) => {
    return (
      slot.hasOwnProperty('weekDayType') &&
      slot.hasOwnProperty('startTime') &&
      slot.hasOwnProperty('endTime') &&
      slot.weekDayType !== null &&
      slot.startTime !== null &&
      slot.endTime !== null
    );
  });

  return (
    <>
      <Form form={form} onFinish={onFinish} className="modal_wrap">
        <div className={'modal-body'}>
          <div className="content__item1">
            <div style={styles.head}>
              <div style={styles.text}>
                {`Wait for the following time slots and
                proceed from this block only during the 
                following time slots. All timings are in`}
              </div>
              <Select
                style={styles.select}
                onChange={handleChangeTimeZone}
                value={timeZone}
              >
                {timeZoneOptions.map((item) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
            {renderTimeSlots()}
            <div style={styles.floodGateSection}>
              <div>
                FLOOD GATE{' '}
                <Switch
                  defaultChecked
                  onChange={handleChangeFloodGate}
                  checked={floodGate}
                />
                <Tooltip
                  placement={'top'}
                  title="If the floodgate option is enabled, 
                users will proceed
                 from this block in a randomly distributed manner 
                 in between the applicable time slot.
                 If the floodgate option is disabled, users will proceed from 
                 this block almost immediately at the start of the applicable time slot."
                >
                  <InfoCircleOutlined style={styles.tooltip} />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <div className={'modal-footer'}>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              shape="round"
              disabled={props.readOnly || hasError || !isAllPropertiesFilled}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};
export default FlowWaitForTimeSlot;
