import React from 'react';
import { Modal, Select, Spin, Tooltip, Radio } from 'antd';
import {
  LoadingOutlined,
  InfoCircleFilled,
  CaretDownOutlined,
  CaretUpOutlined,
} from '@ant-design/icons';
import { isNil, find as lodashFind, filter } from 'lodash';
import {
  numberWithCommas,
  numberWithoutCommas,
  percentage,
  renderCampaignStatus,
} from '../../../../utils/RenderUtils';
import {
  getChannel,
  getCommunicationId,
} from '../../../../utils/AuthorityProvider';
import { variationLetter } from '../../../../utils/FormUtils';
import { readableDate } from '../../../../utils/DateUtils';
import moment from 'moment';
import chartLineSelected from '../../../../theme/icons/chart-line-selected.svg';
import chartLine from '../../../../theme/icons/chart-line.svg';
import table from '../../../../theme/icons/table.svg';
import tableSelected from '../../../../theme/icons/table-selected.svg';
import { Line } from '@ant-design/charts';
import CommunicationPreviewWidgetComponent from './CommunicationPreviewWidgetComponent';

const styles = {
  summeryTitleText: {
    color: 'rgba(61,64,78,.55)',
    fontWeight: '550',
    fontSize: 16,
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  tableTitle: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 8,
    fontSize: '11px',
    fontWeight: '600',
    color: '#94969e',
    width: '8%',
    wordWrap: 'break-word',
  },
  tableData: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 8,
    fontSize: '14px',
    color: 'rgba(61,64,78,.85)',
    width: '8%',
    wordWrap: 'break-word',
  },
  sentItems: {
    color: 'rgba(61,64,78,.55)',
    fontSize: 12,
    marginBottom: 5,
    display: 'flex',
    flexGrow: 'row',
    justifyContent: 'space-between',
    marginRight: 20,
  },
  text: {
    width: 80,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

class CommunicationViewComponent extends React.Component {
  constructor(props) {
    // const controlGroupConverted = null;
    // const controlGroupUniqueDelivered = null;
    super(props);
    this.state = {
      selectedVariationIndex: null,
      selectedVariationReportId: null,
      showVariationChart: true,
      showPercentage: true,
      incrementalRevenue: null,
    };
    this.channel = getChannel(this.props.pathname);
  }

  componentDidMount() {
    //const productId = this.props.match.params.productId;
    this.communicationId = getCommunicationId(this.props.pathname);
    this.props.communicationUpdateAudienceFetch(this.communicationId);
    this.props.communicationViewFetch(this.communicationId);
    this.props.communicationListSmsChannelsFetch();
    // this.props.communicationFailuresReportFetch({
    //   communicationId: this.communicationId,
    //   communicationChannelType: this.channel,
    //   productId,
    // });
    this.props.revenueMappingUpdateFetch();
    this.isEmail =
      this.props?.match?.params?.type?.toLocaleLowerCase() === 'emails';
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.loadingView &&
      prevProps.loadingView &&
      !isNil(this.props.currentCommunication)
    ) {
      if (
        this.props.currentCommunication &&
        this.props.currentCommunication.variations &&
        Array.isArray(this.props.currentCommunication.variations) &&
        this.props.currentCommunication.variations.length === 1
      ) {
        this.setState({
          selectedVariationReportId:
            this.props.currentCommunication.variations[0].id,
        });
      }
    }
    if (
      !this.props.loadingView &&
      prevProps.loadingView
      // !isNil(this.props.currentCommunication.startDate)
    ) {
      this.props.communicationVariationReportFetch({
        communicationId: this.communicationId,
        fromDate: this.getStartDate(
          this.props.currentCommunication?.startDate ||
            this.props.currentCommunication?.createdDate
        ),
        toDate: this.getEndDate(this.props.currentCommunication),
      });
      this.props.communicationCommunicationReportFetch(this.communicationId, {
        fromDate: this.getStartDate(
          this.props.currentCommunication?.startDate ||
            this.props.currentCommunication?.createdDate
        ),
        toDate: this.getEndDate(this.props.currentCommunication),
      });
    }
  }
  getEndDate = (currentCommunication) => {
    const toDay = moment().startOf('day');
    if (currentCommunication.type === 'ONE_TIME') {
      const nextThreeMounth = moment(currentCommunication.startDate).add(
        3,
        'months'
      );
      return toDay < nextThreeMounth
        ? moment().format('YYYY-MM-DD')
        : nextThreeMounth.format('YYYY-MM-DD');
    } else if (
      currentCommunication.type === 'TRIGGERED' ||
      currentCommunication.type === 'RECURRING'
    ) {
      const endDate = currentCommunication.endDate
        ? moment(currentCommunication.endDate).add(3, 'months')
        : moment();
      return toDay < endDate
        ? moment().format('YYYY-MM-DD')
        : endDate.format('YYYY-MM-DD');
    } else if (
      currentCommunication.type === 'JOURNEY' ||
      currentCommunication.type === 'RELAY'
    ) {
      return moment().format('YYYY-MM-DD');
    }
  };

  getStartDate = (startDate) => {
    if (startDate) {
      return moment(startDate).format('YYYY-MM-DD');
    } else {
      return moment().subtract(6, 'days').format('YYYY-MM-DD');
    }
  };

  renderNumber = (number) => {
    if (isNil(number)) {
      return 0;
    }
    return numberWithCommas(number);
  };

  renderSelectItems = () => {
    let result = [];
    const ids = [];
    this.props?.currentVariationReport?.forEach((cvr) => {
      if (cvr.variationId && !ids.includes(cvr.variationId)) {
        ids.push(cvr.variationId);
      }
    });
    this.props?.currentCommunication?.variations?.forEach((variation) => {
      if (variation.id && !ids.includes(variation.id)) {
        ids.push(variation.id);
      }
    });
    if (ids.length > 1) {
      result.push(<Select.Option value={null}>{'Overall'}</Select.Option>);
    }
    if (
      this.props.currentCommunication &&
      this.props.currentCommunication.variations &&
      Array.isArray(this.props.currentCommunication.variations)
    ) {
      ids.forEach((itemId) => {
        const idx = this.props.currentCommunication.variations.findIndex(
          (variation) => variation.id === itemId
        );
        const variationName =
          idx !== -1 ? `Variation ${variationLetter[idx]}` : 'UNKNOWN';

        result.push(
          <Select.Option value={itemId}>{variationName}</Select.Option>
        );
      });
    }
    if (this.hasControlGroup()) {
      result.push(<Select.Option value={-1}>{'Control Group'}</Select.Option>);
    }
    return result;
  };

  renderVariationChart = (config) => {
    return (
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            writingMode: 'vertical-rl',
            textOrientation: 'mixed',
            textAlign: 'center',
            paddingLeft: 14,
          }}
        >
          {'COUNT'}
        </div>
        <Line
          {...config}
          style={{
            flex: 1,
            paddingRight: 30,
            paddingLeft: 20,
            paddingBottom: 20,
            paddingTop: 20,
            width: window.innerWidth - 300,
          }}
        />
      </div>
    );
  };

  renderVariationTable = (data) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          borderRadius: 5,
          paddingBottom: 5,
          boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              ...styles.tableTitle,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
            }}
          >
            DATE
          </div>
          <div
            style={{
              ...styles.tableTitle,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
            }}
          >
            SENT
          </div>
          <div
            style={{
              ...styles.tableTitle,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
            }}
          >
            UNIQUE FAILURE
          </div>
          <div
            style={{
              ...styles.tableTitle,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
            }}
          >
            UNIQUE QUEUED
          </div>
          <div
            style={{
              ...styles.tableTitle,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
            }}
          >
            DELIVERED
          </div>

          <div
            style={{
              ...styles.tableTitle,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
            }}
          >
            UNIQUE CLICKS
          </div>
          <div
            style={{
              ...styles.tableTitle,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
            }}
          >
            UNIQUE CONVERSIONS
          </div>
          <div
            style={{
              ...styles.tableTitle,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
            }}
          >
            ON CLICK CONVERSIONS
          </div>
        </div>
        {data.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                borderTop: '1px solid #e7e8e9',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  ...styles.tableData,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                {readableDate(item.date)}
              </div>
              <div
                style={{
                  ...styles.tableData,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                {this.renderNumbers(item, 'sent', true)}
              </div>
              <div
                style={{
                  ...styles.tableData,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                {this.renderNumbers(item, 'failed', true)}
              </div>
              <div
                style={{
                  ...styles.tableData,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                {this.renderNumbers(item, 'queued', true)}
              </div>
              <div
                style={{
                  ...styles.tableData,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                {this.renderNumbers(item, 'delivered', true)}
              </div>
              <div
                style={{
                  ...styles.tableData,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                {this.renderNumbers(item, 'clicked', true)}
              </div>
              <div
                style={{
                  ...styles.tableData,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                {this.renderNumbers(item, 'converted', true)}
              </div>
              <div
                style={{
                  ...styles.tableData,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                {this.renderNumbers(item, 'convertedAfterClick', true)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  renderReport = () => {
    let temp = [];
    const data = [];
    let values = {
      clicked: 'Unique Clicks',
      converted: 'Unique Conversions',
      delivered: 'Delivered',
      notDelivered: 'Not Delivered',
      failed: 'Unique Failures',
      queued: 'Unique Queued',
      sent: 'Sent',
      convertedAfterClick: 'On Click Conversions',
    };
    if (isNil(this.state.selectedVariationReportId)) {
      const dateSet = new Set();
      this.props.currentVariationReport.forEach((item) => {
        if (!isNil(item.variationId)) {
          dateSet.add(item.date);
        }
      });
      temp = [];
      dateSet.forEach((date) => {
        const foundItems = filter(this.props.currentVariationReport, (o) => {
          return !isNil(o.variationId) && o.date === date;
        });
        let result = {
          date: null,
          totalCount: 0,
          queued: 0,
          sent: 0,
          delivered: 0,
          notDelivered: 0,
          failed: 0,
          clicked: 0,
          converted: 0,
          revenue: 0,
          convertedAfterClick: 0,
        };
        foundItems.forEach((item) => {
          result = {
            date,
            totalCount: Number(item.totalCount) + result.totalCount,
            queued: Number(item.queued) + result.queued,
            sent: Number(item.sent) + result.sent,
            delivered: Number(item.delivered) + result.delivered,
            notDelivered: Number(item.notDelivered) + result.notDelivered,
            failed: Number(item.failed) + result.failed,
            clicked: Number(item.clicked) + result.clicked,
            converted: Number(item.converted) + result.converted,
            revenue: Number(item.revenue) + result.revenue,
            convertedAfterClick:
              Number(item.convertedAfterClick) + result.convertedAfterClick,
          };
        });
        temp.push(result);
      });
    } else {
      temp = filter(this.props.currentVariationReport, (o) => {
        if (
          !isNil(this.state.selectedVariationReportId) &&
          this.state.selectedVariationReportId !== -1
        ) {
          return o.variationId === this.state.selectedVariationReportId;
        }
        if (this.state.selectedVariationReportId === -1) {
          return !o.hasOwnProperty('variationId');
        }
      });
    }
    if (this.state.selectedVariationReportId === -1) {
      values = {
        converted: 'Unique Conversions',
        sent: 'Sent',
      };
    }
    temp.forEach((item) => {
      const date = moment(item.date, 'YYYY-MM-DD').format('DD MMM');
      Object.keys(values).forEach((category) => {
        data.push({
          date,
          value: item[category],
          category: values[category],
        });
      });
    });
    const config = {
      data,
      height: 400,
      xField: 'date',
      yField: 'value',
      padding: 'auto',
      seriesField: 'category',
      color: ['#fc587d', '#008BFA', '#FFC009', '#58FEA1', '#02eb7f', '#773a80'],
      lineStyle: function lineStyle() {
        return {
          lineDash: [4, 4],
          opacity: 1,
        };
      },
      yAxis: {
        label: {
          formatter: function formatter(v) {
            return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
              return ''.concat(s, ',');
            });
          },
        },
      },
      point: {
        size: 5,
        shape: 'circle',
      },
      legend: {
        position: 'right',
        verticalAlign: 'middle',
      },
      meta: {
        value: {
          formatter: (v) => {
            return this.renderNumber(v);
          },
        },
      },
    };
    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          borderRadius: 5,
          paddingBottom: 5,
          marginTop: 24,
          boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
        }}
      >
        {this.props.loadingVariationReport ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              paddingTop: 50,
              paddingBottom: 50,
            }}
          >
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <React.Fragment>
            {' '}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '12px',
                paddingLeft: '20px',
                borderBottom: 'solid 2px #f4f9ff',
              }}
            >
              <div
                style={{ color: '#1c1c1c', fontSize: 16, fontWeight: '600' }}
              >
                <Select
                  value={this.state.selectedVariationReportId}
                  onChange={(selectedVariationReportId) =>
                    this.setState({ selectedVariationReportId })
                  }
                  style={{ width: 150, marginLeft: 20, marginRight: 10 }}
                  className={'chart-selector'}
                  bordered={false}
                >
                  {this.renderSelectItems()}
                </Select>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginRight: 10,
                      marginBottom: 5,
                    }}
                  >
                    <div
                      onClick={() =>
                        this.setState({ showVariationChart: true })
                      }
                      style={{
                        cursor: 'pointer',
                        marginLeft: 25,
                        marginRight: 15,
                      }}
                    >
                      <img
                        src={
                          this.state.showVariationChart
                            ? chartLineSelected
                            : chartLine
                        }
                        style={{ width: 16, height: 'auto' }}
                        alt=""
                      />
                    </div>
                    <div
                      onClick={() =>
                        this.setState({ showVariationChart: false })
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      <img
                        src={
                          this.state.showVariationChart ? table : tableSelected
                        }
                        style={{ width: 16, height: 'auto' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.showVariationChart
              ? this.renderVariationChart(config)
              : this.renderVariationTable(temp)}
          </React.Fragment>
        )}
      </div>
    );
  };
  renderTotalCount = () => {
    if (
      this.props.communicationReport &&
      this.props.communicationReport.totalAggregation
    ) {
      return numberWithCommas(
        this.props.communicationReport.totalAggregation.sent +
          this.props.communicationReport.totalAggregation.failed +
          this.props.communicationReport.totalAggregation.queued
      );
    } else {
      return '-';
    }
  };

  upLiftCalculation = () => {
    if (
      this.props.communicationReport &&
      this.props.communicationReport.totalAggregation &&
      this.props.communicationReport.variationsAggregations &&
      Array.isArray(this.props.communicationReport.variationsAggregations)
    ) {
      const foundItem = lodashFind(
        this.props.communicationReport.variationsAggregations,
        (o) => isNil(o.variationId)
      );
      if (!isNil(foundItem)) {
        const controlGroup = percentage(
          foundItem.converted,
          foundItem.uniqueDelivered
        );
        if (parseFloat(controlGroup) === 0) {
          return (0).toFixed(2);
        } else {
          const upLift =
            (percentage(
              this.props.communicationReport.totalAggregation.converted,
              this.props.communicationReport.totalAggregation.uniqueDelivered
            ) /
              parseFloat(controlGroup)) *
              100 -
            100;
          return parseFloat(upLift).toFixed(2);
        }
      }
    }
  };
  controlGroupCalculation = () => {
    if (
      this.props.communicationReport &&
      this.props.communicationReport.variationsAggregations &&
      Array.isArray(this.props.communicationReport.variationsAggregations)
    ) {
      const foundItem = lodashFind(
        this.props.communicationReport.variationsAggregations,
        (o) => isNil(o.variationId)
      );
      if (!isNil(foundItem)) {
        this.controlGroupConverted = foundItem.converted;
        this.controlGroupUniqueDelivered = foundItem.sent;

        const controlGroup = percentage(foundItem.converted, foundItem.sent);
        if (controlGroup === 0) {
          return parseFloat(0).toFixed(2);
        } else {
          return parseFloat(controlGroup).toFixed(2);
        }
      }
    }
  };
  incrementalRevenueCalculation = () => {
    let incrementalRevenue = '-';
    const variations = [];
    if (
      this.props.communicationReport &&
      this.props.communicationReport.variationsAggregations &&
      Array.isArray(this.props.communicationReport.variationsAggregations)
    ) {
      this.props.communicationReport.variationsAggregations.forEach((item) => {
        variations.push(item);
      });
    }
    variations.forEach((item) => {
      let totalRevenueVariations = numberWithoutCommas(
        this.sumItem(variations, 'revenue')
      );
      let totalDeliveredVariations = numberWithoutCommas(
        this.sumItem(variations, 'uniqueDelivered')
      );
      if (isNil(item.variationId)) {
        const controlGroupRevenue = item?.revenue || 0;
        const controlGroupSent = numberWithoutCommas(item.uniqueDelivered) || 0;
        if (controlGroupSent === 0) {
          incrementalRevenue = '-';
        } else {
          incrementalRevenue =
            totalRevenueVariations -
            (totalDeliveredVariations * controlGroupRevenue) / controlGroupSent;
        }
      }
    });
    if (incrementalRevenue === '-') {
      return '-';
    } else {
      return numberWithCommas(incrementalRevenue.toFixed(2));
    }
  };
  unknownSmsSent = () => {
    let unknown = null;
    if (
      this.props.communicationReport &&
      this.props.communicationReport.totalAggregation
    ) {
      unknown =
        this.props.communicationReport?.totalAggregation?.sent -
        (this.props.communicationReport?.totalAggregation?.delivered +
          this.props.communicationReport?.totalAggregation?.notDelivered);
      return numberWithCommas(unknown);
    } else {
      return '-';
    }
  };
  notOpenedInEmail = () => {
    let notOpened = null;
    if (
      this.props.communicationReport &&
      this.props.communicationReport.totalAggregation
    ) {
      notOpened =
        this.props.communicationReport?.totalAggregation?.delivered -
        this.props.communicationReport?.totalAggregation?.opened;
      return numberWithCommas(notOpened);
    } else {
      return '-';
    }
  };
  renderSummary = () => {
    const increaseTooltipTitle =
      'Campaign performed better than control group. Campaign conversion was ' +
      this.upLiftCalculation() +
      ' higher than control group conversion. Calculations based on unique conversions.';
    const decreaseTooltipTitle =
      'Control group performed better than campaign. Campaign conversion was ' +
      this.upLiftCalculation() +
      ' lower than control group conversion. Calculations based on unique conversions.';
    const zeroTooltipTitle =
      'Campaign performed better than Control Group. Control Group conversion was lower than conversion of journey. Calculation based on unique conversion.';
    const uniqueConversionTooltip =
      this.props.communicationReport?.totalAggregation?.converted +
      ' unique conversions out of ' +
      this.props.communicationReport?.totalAggregation?.uniqueDelivered +
      ' users engaged through campaigns where message was successfully delivered (for Push, SMS, WebPush & Email) or viewed.';
    const controlGroupToolTip =
      this.controlGroupConverted +
      ' unique conversions out of ' +
      this.controlGroupUniqueDelivered +
      ' users in control group';
    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const firstThreefailures =
      Array.isArray(this.props?.failures) && this.props?.failures.slice(0, 3);
    return (
      <React.Fragment>
        {this.props.loadingCommunicationReport ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              paddingTop: 50,
              paddingBottom: 50,
              backgroundColor: 'white',
              borderRadius: 5,
              marginTop: 24,
              boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
            }}
          >
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flex: 3,
                marginRight: 20,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
                    height: 290,
                    marginBottom: 10,
                    width: '100%',
                    paddingTop: 20,
                    paddingLeft: 20,
                    marginRight: 20,
                    borderRadius: 10,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: 20,
                    }}
                  >
                    <div
                      style={{
                        overflow: 'hidden',
                        backgroundColor: '#fe5196',
                        width: 12,
                        height: 12,
                        borderRadius: '50%',
                        marginRight: 5,
                      }}
                    ></div>
                    <div
                      style={{
                        ...styles.summeryTitleText,
                        fontSize: 14,
                        lineHeight: '0.5em',
                      }}
                    >
                      TOTAL COUNT
                    </div>
                  </div>
                  <div style={styles.summeryTitleText}>
                    {this.renderTotalCount()}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 60,
                        width: '100%',
                        flex: 1,
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {' '}
                        <div
                          style={{
                            overflow: 'hidden',
                            backgroundColor: '#0FF4C4',
                            width: 10,
                            height: 10,
                            borderRadius: '50%',
                            marginRight: 5,
                            marginTop: 5,
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        ></div>
                        <div
                          style={{
                            ...styles.summeryTitleText,
                            fontSize: 14,
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          SENT
                        </div>
                      </div>{' '}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <div
                          style={{
                            marginTop: 5,
                            flexDirection: 'row',
                          }}
                        >
                          <div
                            style={{
                              ...styles.summeryTitleText,
                              fontSize: 12,
                              marginBottom: 20,
                            }}
                          >
                            {this.props.communicationReport &&
                            this.props.communicationReport.totalAggregation
                              ? numberWithCommas(
                                  this.props.communicationReport
                                    ?.totalAggregation?.sent
                                )
                              : '-'}
                          </div>
                          <div style={styles.sentItems}>
                            {this.isEmail ? 'Not-Opened' : 'Unknown'}
                            <div style={{ marginLeft: 30 }}>
                              {this.isEmail
                                ? this.notOpenedInEmail()
                                : this.unknownSmsSent()}
                            </div>
                          </div>
                          <div style={styles.sentItems}>
                            Delivered
                            <div>
                              {this.props.communicationReport &&
                              this.props.communicationReport?.totalAggregation
                                ? numberWithCommas(
                                    this.props.communicationReport
                                      ?.totalAggregation?.delivered
                                  )
                                : '-'}
                            </div>
                          </div>
                          <div style={styles.sentItems}>
                            Not Delivered
                            <div>
                              {this.props.communicationReport &&
                              this.props.communicationReport?.totalAggregation
                                ? numberWithCommas(
                                    this.props.communicationReport
                                      ?.totalAggregation?.notDelivered
                                  )
                                : '-'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        justifyContent: 'flex-start',
                        marginTop: 40,
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 20,
                          alignItems: 'flex-start',
                        }}
                      >
                        <div
                          style={{
                            overflow: 'hidden',
                            backgroundColor: '#A46BF6',
                            width: 10,
                            height: 10,
                            borderRadius: '50%',
                            marginRight: 5,
                            marginTop: 5,
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        ></div>
                        <div
                          style={{
                            ...styles.summeryTitleText,
                            fontSize: 14,
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          QUEUED
                        </div>
                      </div>
                      <div
                        style={{
                          ...styles.summeryTitleText,
                          fontSize: 12,
                          marginTop: 5,
                        }}
                      >
                        {this.props.communicationReport &&
                        this.props.communicationReport.totalAggregation
                          ? numberWithCommas(
                              this.props.communicationReport.totalAggregation
                                .queued
                            )
                          : '-'}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        marginTop: 40,
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 20,
                          alignItems: 'flex-start',
                        }}
                      >
                        <div
                          style={{
                            overflow: 'hidden',
                            backgroundColor: '#F6AC6B',
                            width: 10,
                            height: 10,
                            borderRadius: '50%',
                            marginRight: 5,
                            marginTop: 5,
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        ></div>
                        <div
                          style={{
                            ...styles.summeryTitleText,
                            fontSize: 14,
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          FAILED
                        </div>
                      </div>
                      <div
                        style={{
                          ...styles.summeryTitleText,
                          fontSize: 12,
                          marginTop: 5,
                        }}
                      >
                        {this.props.communicationReport &&
                        this.props.communicationReport.totalAggregation
                          ? numberWithCommas(
                              this.props.communicationReport.totalAggregation
                                .failed
                            )
                          : '-'}
                      </div>
                      <div style={{ marginTop: 20 }}>
                        {false &&
                          firstThreefailures.map((failure, index) => {
                            return (
                              <div key={index} style={styles.sentItems}>
                                <Tooltip
                                  title={failure.reason}
                                  color={'red'}
                                  key={'red'}
                                >
                                  <span style={styles.text}>
                                    {failure.reason}
                                  </span>
                                </Tooltip>
                                <div style={{ marginLeft: 30 }}>
                                  {failure.count}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                flex: 5,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
                    height: 130,
                    marginBottom: 10,
                    width: '100%',
                    paddingTop: 20,
                    paddingLeft: 20,
                    marginRight: 10,
                    borderRadius: 10,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: 20,
                    }}
                  >
                    <div
                      style={{
                        overflow: 'hidden',
                        backgroundColor: '#008bfa',
                        width: 12,
                        height: 12,
                        borderRadius: '50%',
                        marginRight: 5,
                      }}
                    ></div>
                    <div
                      style={{
                        ...styles.summeryTitleText,
                        fontSize: 14,
                        lineHeight: '0.5em',
                      }}
                    >
                      DELIVERED
                    </div>
                  </div>
                  <div style={{ ...styles.summeryTitleText, fontSize: 14 }}>
                    {this.props.communicationReport &&
                    this.props.communicationReport.totalAggregation
                      ? percentage(
                          this.props.communicationReport.totalAggregation
                            .delivered,
                          this.props.communicationReport.totalAggregation.sent
                        ) + '%'
                      : '-'}
                  </div>
                  <div style={{ ...styles.summeryTitleText, fontSize: 14 }}>
                    {this.props.communicationReport &&
                    this.props.communicationReport.totalAggregation
                      ? numberWithCommas(
                          this.props.communicationReport.totalAggregation
                            .delivered
                        )
                      : '-'}
                  </div>
                </div>
                {this.isEmail && (
                  <div
                    style={{
                      backgroundColor: 'white',
                      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
                      height: 130,
                      marginBottom: 10,
                      width: '100%',
                      paddingTop: 20,
                      paddingLeft: 20,
                      marginRight: 10,
                      borderRadius: 10,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: 20,
                      }}
                    >
                      <div
                        style={{
                          overflow: 'hidden',
                          backgroundColor: '#008b',
                          width: 12,
                          height: 12,
                          borderRadius: '50%',
                          marginRight: 5,
                        }}
                      ></div>
                      <div
                        style={{
                          ...styles.summeryTitleText,
                          fontSize: 14,
                          lineHeight: '0.5em',
                        }}
                      >
                        OPENED
                      </div>
                    </div>
                    <div style={{ ...styles.summeryTitleText, fontSize: 14 }}>
                      {this.props.communicationReport &&
                      this.props.communicationReport.totalAggregation
                        ? percentage(
                            this.props.communicationReport.totalAggregation
                              .opened,
                            this.props.communicationReport.totalAggregation
                              .delivered
                          ) + '%'
                        : '-'}
                    </div>
                    <div style={{ ...styles.summeryTitleText, fontSize: 14 }}>
                      {this.props.communicationReport &&
                      this.props.communicationReport.totalAggregation
                        ? numberWithCommas(
                            this.props.communicationReport.totalAggregation
                              .opened
                          )
                        : '-'}
                    </div>
                  </div>
                )}
                <div
                  style={{
                    backgroundColor: 'white',
                    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
                    height: 130,
                    marginBottom: 10,
                    width: '100%',
                    paddingTop: 20,
                    paddingLeft: 20,
                    marginRight: 10,
                    borderRadius: 10,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: 20,
                    }}
                  >
                    <div
                      style={{
                        overflow: 'hidden',
                        backgroundColor: '#EA3A3A',
                        width: 12,
                        height: 12,
                        borderRadius: '50%',
                        marginRight: 5,
                      }}
                    ></div>
                    <div
                      style={{
                        ...styles.summeryTitleText,
                        fontSize: 14,
                        lineHeight: '0.5em',
                      }}
                    >
                      UNIQUE CLICKS
                    </div>
                  </div>
                  <div style={{ ...styles.summeryTitleText, fontSize: 14 }}>
                    {this.props.communicationReport &&
                    this.props.communicationReport.totalAggregation
                      ? percentage(
                          this.props.communicationReport.totalAggregation
                            .clicked,
                          this.props.communicationReport.totalAggregation
                            .uniqueDelivered
                        ) + '%'
                      : '-'}
                  </div>
                  <div style={{ ...styles.summeryTitleText, fontSize: 14 }}>
                    {this.props.communicationReport &&
                    this.props.communicationReport.totalAggregation
                      ? numberWithCommas(
                          this.props.communicationReport.totalAggregation
                            .clicked
                        )
                      : '-'}
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: 'white',
                    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
                    height: 130,
                    marginBottom: 10,
                    width: '100%',
                    paddingTop: 20,
                    paddingLeft: 20,
                    borderRadius: 10,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: 20,
                    }}
                  >
                    <div
                      style={{
                        overflow: 'hidden',
                        backgroundColor: '#03F8FD',
                        width: 12,
                        height: 12,
                        borderRadius: '50%',
                        marginRight: 5,
                      }}
                    ></div>
                    <div
                      style={{
                        ...styles.summeryTitleText,
                        fontSize: 14,
                      }}
                    >
                      ON CLICK CONVERSIONS
                    </div>
                  </div>
                  <div style={{ ...styles.summeryTitleText, fontSize: 14 }}>
                    {this.props.communicationReport &&
                    this.props.communicationReport.totalAggregation
                      ? percentage(
                          this.props.communicationReport.totalAggregation
                            .convertedAfterClick,
                          this.props.communicationReport.totalAggregation
                            .totalClicked
                        ) + '%'
                      : '-'}
                  </div>
                  <div style={{ ...styles.summeryTitleText, fontSize: 14 }}>
                    {this.props.communicationReport &&
                    this.props.communicationReport.totalAggregation
                      ? numberWithCommas(
                          this.props.communicationReport.totalAggregation
                            .convertedAfterClick
                        )
                      : '-'}
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{
                    backgroundColor: 'white',
                    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
                    height: 150,
                    marginBottom: 10,
                    width: '100%',
                    paddingTop: 20,
                    paddingLeft: 20,
                    marginRight: 10,
                    borderRadius: 10,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: 20,
                    }}
                  >
                    <div
                      style={{
                        overflow: 'hidden',
                        backgroundColor: '#24CE36',
                        width: 12,
                        height: 12,
                        borderRadius: '50%',
                        marginRight: 5,
                      }}
                    ></div>
                    <div
                      style={{
                        ...styles.summeryTitleText,
                        fontSize: 14,
                        lineHeight: '0.5em',
                      }}
                    >
                      REVENUE
                      {this.props.currentRevenueMapping &&
                        ' (' +
                          this.props.currentRevenueMapping.revenueCurrency +
                          ')'}
                    </div>
                  </div>
                  <div style={{ ...styles.summeryTitleText, fontSize: 14 }}>
                    {this.props.communicationReport &&
                    this.props.communicationReport.totalAggregation
                      ? numberWithCommas(
                          this.props.communicationReport.totalAggregation
                            .revenue
                        )
                      : '-'}
                  </div>
                  {this.hasControlGroup() ? (
                    <div
                      style={{
                        justifyContent: 'center',
                        color: 'rgba(61,64,78,.55)',
                        fontSize: 12,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 10,
                        }}
                      >
                        Incremental Revenue:
                        <div
                          style={{
                            marginLeft: 10,
                            fontWeight: 'bold',
                          }}
                        >
                          {this.incrementalRevenueCalculation()}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  <div
                    style={{
                      justifyContent: 'center',
                      color: 'rgba(61,64,78,.55)',
                      fontSize: 12,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 10,
                      }}
                    >
                      Incremental conversion:
                      <div
                        style={{
                          marginLeft: 10,
                          fontWeight: 'bold',
                        }}
                      >
                        {this.props.communicationReport &&
                        this.props.communicationReport.totalAggregation
                          ?.incrementalConversion
                          ? numberWithCommas(
                              this.props.communicationReport.totalAggregation
                                .incrementalConversion
                            )
                          : '0'}{' '}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: 'white',
                    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
                    height: 150,
                    marginBottom: 10,
                    width: '100%',
                    paddingTop: 20,
                    paddingLeft: 20,
                    borderRadius: 10,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: 20,
                    }}
                  >
                    <div
                      style={{
                        overflow: 'hidden',
                        backgroundColor: '#E7EA3A',
                        width: 12,
                        height: 12,
                        borderRadius: '50%',
                        marginRight: 5,
                      }}
                    ></div>
                    <div
                      style={{
                        ...styles.summeryTitleText,
                        fontSize: 14,
                        lineHeight: '0.5em',
                      }}
                    >
                      UNIQUE CONVERSIONS
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div style={{ ...styles.summeryTitleText, fontSize: 14 }}>
                      {this.props.communicationReport &&
                      this.props.communicationReport.totalAggregation
                        ? parseFloat(
                            percentage(
                              this.props.communicationReport.totalAggregation
                                .converted,
                              this.props.communicationReport.totalAggregation
                                .uniqueDelivered
                            )
                          ).toFixed(2) + '%'
                        : '-'}
                      <Tooltip placement="top" title={uniqueConversionTooltip}>
                        <InfoCircleFilled
                          style={{
                            color: 'rgba(61,64,78,.55)',
                            fontSize: 15,
                            paddingLeft: 5,
                          }}
                        />
                      </Tooltip>
                    </div>
                    {this.hasControlGroup() ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginTop: this.upLiftCalculation() <= 0 ? 5 : -5,
                          alignItems: 'flex-end',
                        }}
                      >
                        {this.upLiftCalculation() > 0 && (
                          <CaretUpOutlined
                            style={{ color: '#09d88c', marginRight: 15 }}
                          />
                        )}
                        <Tooltip
                          placement="top"
                          title={
                            this.upLiftCalculation() > (0).toFixed(2)
                              ? increaseTooltipTitle
                              : this.upLiftCalculation() === (0).toFixed(2)
                              ? this.props?.communicationReport
                                  ?.totalAggregation?.delivered === 0
                                ? ''
                                : zeroTooltipTitle
                              : decreaseTooltipTitle
                          }
                        >
                          {' '}
                          <div
                            style={{
                              ...styles.summeryTitleText,
                              lineHeight: '0.8em',
                              fontSize: 13,
                              fontWeight: 550,
                              marginLeft: 50,
                              color:
                                this.upLiftCalculation() < (0).toFixed(2)
                                  ? '#f76464'
                                  : this.upLiftCalculation() === (0).toFixed(2)
                                  ? 'rgba(61,64,78,.55)'
                                  : '#09d88c',
                            }}
                          >
                            {this.upLiftCalculation() + '%'}
                          </div>
                        </Tooltip>
                        {this.upLiftCalculation() < 0 && (
                          <CaretDownOutlined
                            style={{ color: '#f76464', marginRight: 15 }}
                          />
                        )}
                      </div>
                    ) : null}
                  </div>
                  {this.hasControlGroup() ? (
                    <div
                      style={{
                        borderTop: '1px solid rgba(61,64,78,.2)',
                        borderRadius: 10,
                        padding: 10,
                        borderBottom: '1px solid rgba(61,64,78,.2)',
                        borderRight: '1px solid rgba(61,64,78,.2)',
                        borderLeft: '1px solid rgba(61,64,78,.2)',
                        marginTop: this.upLiftCalculation() < 0 ? 15 : 25,
                        marginBottom: 5,
                        marginRight: 20,
                      }}
                    >
                      <div
                        style={{
                          color: 'rgba(61,64,78,.55)',
                          fontSize: 12,
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        Control Group:
                        <div style={{ fontWeight: 'bold' }}>
                          {' '}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {this.controlGroupCalculation() + '%'}
                          <Tooltip placement="top" title={controlGroupToolTip}>
                            {' '}
                            <InfoCircleFilled
                              style={{
                                color: 'rgba(61,64,78,.55)',
                                fontSize: 15,
                                paddingLeft: 5,
                              }}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  renderNumbers = (item, selector, isVariationReport = false) => {
    if (!isVariationReport) {
      if (isNil(item.variationId) && selector === 'converted') {
        return numberWithCommas(item[selector]);
      }
      if (
        isNil(item.variationId) &&
        (selector === 'sent' || selector === 'totalCount')
      ) {
        return (
          <React.Fragment>
            {numberWithCommas(item.sent)}
            <Tooltip
              placement="right"
              title={
                'Represent the total number of users in control group to whom no message was sent'
              }
            >
              <i
                className="fl-info fl-dark"
                style={{ fontSize: 24, position: 'absolute' }}
              ></i>
            </Tooltip>
          </React.Fragment>
        );
      }
      if (isNil(item.variationId) && selector === 'revenue') {
        return numberWithCommas(item[selector]);
      }
      if (isNil(item.variationId) && selector !== 'revenue') {
        return '-';
      } else if (!isNil(item)) {
        return numberWithCommas(item[selector]);
      }
    }
    if (!isNil(item) && this.state.selectedVariationReportId !== -1) {
      return numberWithCommas(item[selector]);
    }
    if (
      !isNil(item) &&
      this.state.selectedVariationReportId === -1 &&
      (selector === 'sent' || selector === 'converted')
    ) {
      return numberWithCommas(item[selector]);
    }
    return '-';
  };

  renderPercentage = (item, selectorFrom, selectorTotal) => {
    if (isNil(item.variationId) && selectorFrom === 'converted') {
      return percentage(item[selectorFrom], item.sent) + '%';
    } else if (isNil(item.variationId) && selectorFrom === 'sent') {
      return (
        <React.Fragment>
          {isNil(item.sent) || !item.sent
            ? '0%'
            : percentage(item.sent, item.sent) + '%'}
          <Tooltip
            placement="right"
            title={
              'Represent the total number of users in control group to whom no message was sent'
            }
          >
            <i
              className="fl-info fl-dark"
              style={{ fontSize: 24, position: 'absolute' }}
            ></i>
          </Tooltip>
        </React.Fragment>
      );
    } else if (isNil(item.variationId)) {
      return '-';
    } else if (!isNil(item)) {
      return percentage(item[selectorFrom], item[selectorTotal]) + '%';
    }
  };

  renderVariationLetter = (item, index, isInWinnerBox = false) => {
    if (!isNil(item.variationId)) {
      const variationData = lodashFind(
        this.props.currentCommunication.variations,
        (o) => o.id === item?.variationId
      );
      const idx = this.props.currentCommunication.variations?.findIndex(
        (variation) => variation.id === item.variationId
      );
      const variationName = variationData
        ? `Variation ${variationLetter[idx]}`
        : 'UNKNOWN';
      return (
        <React.Fragment>
          {variationName}
          {!isInWinnerBox &&
            this.renderVariationPercentage(item.variationId, index)}
          {!isInWinnerBox && variationName === 'UNKNOWN' ? (
            <Tooltip
              placement="right"
              title={
                'this variation might be deleted, in this case, other variation names might have been changed accordingly'
              }
            >
              <i
                className="fl-info fl-dark"
                style={{ fontSize: 24, position: 'absolute' }}
              ></i>
            </Tooltip>
          ) : null}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        Control Group {this.renderVariationPercentage(null, null)}
      </React.Fragment>
    );
  };

  renderVariationPercentage = (variationId) => {
    let result = '';
    let foundItem = null;
    if (
      !isNil(variationId) &&
      this.props.currentCommunication &&
      this.props.currentCommunication.variations &&
      Array.isArray(this.props.currentCommunication.variations) &&
      this.props.currentCommunication.variations.length === 1
    ) {
      foundItem = lodashFind(
        this.props.currentCommunication.variations,
        (o) => o.id === variationId
      );
      if (foundItem) {
        result = (
          <React.Fragment>
            <Tooltip placement="top" title={'Preview'}>
              <i
                onClick={() =>
                  this.setState({ selectedVariationIndex: variationId })
                }
                className="fl-overview fl-dark"
                style={{
                  fontSize: 24,
                  cursor: 'pointer',
                  position: 'relative',
                  top: 5,
                }}
              ></i>
            </Tooltip>
          </React.Fragment>
        );
      }
    }
    if (
      !isNil(variationId) &&
      this.props.currentCommunication &&
      this.props.currentCommunication.variations &&
      Array.isArray(this.props.currentCommunication.variations) &&
      this.props.currentCommunication.variations.length > 1
    ) {
      foundItem = lodashFind(
        this.props.currentCommunication.variations,
        (o) => o.id === variationId
      );
      if (foundItem) {
        result = (
          <React.Fragment>
            ({foundItem.percentage}%
            <Tooltip
              placement="right"
              title={
                'This % represets how the total variation % was split among each of the variations (i.e Var A = x % of Variation % etc. where Variation % = 100% - CG %)'
              }
            >
              <i
                className="fl-info fl-dark"
                style={{ fontSize: 24, position: 'relative', top: 5 }}
              ></i>
            </Tooltip>
            ){' '}
            <Tooltip placement="top" title={'Preview'}>
              <i
                onClick={() =>
                  this.setState({ selectedVariationIndex: variationId })
                }
                className="fl-overview fl-dark"
                style={{
                  fontSize: 24,
                  cursor: 'pointer',
                  position: 'relative',
                  top: 5,
                }}
              ></i>
            </Tooltip>
          </React.Fragment>
        );
      }
    } else if (
      isNil(variationId) &&
      this.props.currentCommunication &&
      this.props.currentCommunication.controlGroup
    ) {
      result = (
        <React.Fragment>
          ({this.props.currentCommunication.controlGroupPercentage}%
          <Tooltip
            placement="right"
            title={
              'This % represets the size of the control group compared to the campaign variations (i.e CG % = 100% - Variation %). This distrubution above for each of the variations is how the total variations % was split among each of the variations (i.e Var A = x % of Variation % etc. where Variation % = 100% - CG %)'
            }
          >
            <i
              className="fl-info fl-dark"
              style={{ fontSize: 24, position: 'relative', top: 5 }}
            ></i>
          </Tooltip>
          )
        </React.Fragment>
      );
    }
    return result;
  };

  hasControlGroup = () => {
    return (
      this.props.currentCommunication &&
      this.props.currentCommunication.conversion &&
      this.props.currentCommunication.controlGroup
    );
  };

  sumItem = (variations, selector) => {
    let result = 0;
    if (Array.isArray(variations)) {
      variations.forEach((item) => {
        if (item.variationId) {
          result += isNil(item[selector]) ? 0 : Number(item[selector]);
        }
      });
      return numberWithCommas(result);
    }
    return '-';
  };

  renderSumPercentage = (variations, selectorFrom, selectorTotal) => {
    let resultFrom = 0;
    let resultTotal = 0;
    if (Array.isArray(variations)) {
      variations.forEach((item) => {
        if (item.variationId) {
          resultFrom += isNil(item[selectorFrom])
            ? 0
            : Number(item[selectorFrom]);
          resultTotal += isNil(item[selectorTotal])
            ? 0
            : Number(item[selectorTotal]);
        }
      });
      return percentage(resultFrom, resultTotal) + '%';
    }
    return '-';
  };

  renderOverall = (variations) => {
    if (variations.length > 1) {
      let label = 'Total';
      if (this.hasControlGroup()) {
        label += ' (Variations Only)';
      }
      return (
        <div
          style={{
            borderTop: '1px solid #e7e8e9',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              ...styles.tableData,
              borderRight: '1px solid #e7e8e9',
              flex: 2,
              color: '#3d404e',
              fontWeight: 700,
            }}
          >
            {label}
          </div>
          <div
            style={{
              ...styles.tableData,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
              color: '#3d404e',
              fontWeight: 700,
            }}
          >
            {this.sumItem(variations, 'totalCount')}
          </div>
          <div
            style={{
              ...styles.tableData,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
              color: '#3d404e',
              fontWeight: 700,
            }}
          >
            {this.state.showPercentage
              ? this.renderSumPercentage(variations, 'sent', 'totalCount')
              : this.sumItem(variations, 'sent')}
          </div>
          <div
            style={{
              ...styles.tableData,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
              color: '#3d404e',
              fontWeight: 700,
            }}
          >
            {this.state.showPercentage
              ? this.renderSumPercentage(variations, 'failed', 'sent')
              : this.sumItem(variations, 'failed')}
          </div>
          <div
            style={{
              ...styles.tableData,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
              color: '#3d404e',
              fontWeight: 700,
            }}
          >
            {this.state.showPercentage
              ? this.renderSumPercentage(variations, 'queued', 'sent')
              : this.sumItem(variations, 'queued')}
          </div>
          <div
            style={{
              ...styles.tableData,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
              color: '#3d404e',
              fontWeight: 700,
            }}
          >
            {this.state.showPercentage
              ? this.renderSumPercentage(variations, 'delivered', 'sent')
              : this.sumItem(variations, 'delivered')}
          </div>
          <div
            style={{
              ...styles.tableData,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
              color: '#3d404e',
              fontWeight: 700,
            }}
          >
            {this.state.showPercentage
              ? this.renderSumPercentage(variations, 'notDelivered', 'sent')
              : this.sumItem(variations, 'notDelivered')}
          </div>
          <div
            style={{
              ...styles.tableData,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
              color: '#3d404e',
              fontWeight: 700,
            }}
          >
            {this.state.showPercentage
              ? this.renderSumPercentage(
                  variations,
                  'clicked',
                  'uniqueDelivered'
                )
              : this.sumItem(variations, 'clicked')}
          </div>
          <div
            style={{
              ...styles.tableData,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
              color: '#3d404e',
              fontWeight: 700,
            }}
          >
            {this.state.showPercentage
              ? this.renderSumPercentage(
                  variations,
                  'converted',
                  'uniqueDelivered'
                )
              : this.sumItem(variations, 'converted')}
          </div>
          <div
            style={{
              ...styles.tableData,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
              color: '#3d404e',
              fontWeight: 700,
            }}
          >
            {this.sumItem(variations, 'revenue')}
          </div>
          <div
            style={{
              ...styles.tableData,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
              color: '#3d404e',
              fontWeight: 700,
            }}
          >
            {this.state.showPercentage
              ? this.renderSumPercentage(
                  variations,
                  'convertedAfterClick',
                  'clicked'
                )
              : this.sumItem(variations, 'convertedAfterClick')}
          </div>
        </div>
      );
    }
    return null;
  };

  renderVariations = () => {
    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const variations = [];
    let isWinnerMechanismEnabled =
      this.props.currentCommunication?.winnerMechanismEnabled;

    let currentStatus = this.props.currentCommunication?.status;

    if (
      this.props.communicationReport &&
      this.props.communicationReport.variationsAggregations &&
      Array.isArray(this.props.communicationReport.variationsAggregations)
    ) {
      this.props.communicationReport.variationsAggregations.forEach((item) => {
        if (item?.winnerMechanismTestPhase !== false) {
          variations.push(item);
        }
      });
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          borderRadius: 5,
          paddingBottom: 5,
          marginTop: 24,
          boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
        }}
      >
        {this.props.loadingCommunicationReport || this.props.loadingView ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              paddingTop: 50,
              paddingBottom: 50,
            }}
          >
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <React.Fragment>
            <div
              style={{
                padding: 10,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  fontWeight: '700',
                }}
              >
                {isWinnerMechanismEnabled ? (
                  <>
                    Test Audience
                    {['W_UPCOMING', 'W_RUNNING'].includes(currentStatus) && (
                      <span style={{ marginLeft: '8px' }}>
                        {renderCampaignStatus(currentStatus)}
                      </span>
                    )}
                  </>
                ) : (
                  ''
                )}
              </div>
              <div>
                <Radio.Group
                  value={this.state.showPercentage}
                  onChange={(e) =>
                    this.setState({ showPercentage: e.target.value })
                  }
                >
                  <Radio.Button value={true}>%</Radio.Button>
                  <Radio.Button value={false}>#</Radio.Button>
                </Radio.Group>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                borderTop: '1px solid #e7e8e9',
              }}
            >
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 2,
                }}
              >
                TYPE
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                TOTAL
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                SENT
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                FAILURES
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                QUEUED
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                DELIVERED
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                NOT DELIVERED
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                UNIQUE CLICKS
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                UNIQUE CONVERSIONS
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                REVENUE
                {this.props.currentRevenueMapping &&
                  ' (' + this.props.currentRevenueMapping.revenueCurrency + ')'}
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                ON CLICK CONVERSIONS
              </div>
            </div>
            {variations.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    borderTop: '1px solid #e7e8e9',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 2,
                    }}
                  >
                    {this.renderVariationLetter(item, index)}
                  </div>
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {this.renderNumbers(item, 'totalCount')}
                  </div>
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {this.state.showPercentage
                      ? this.renderPercentage(item, 'sent', 'totalCount')
                      : this.renderNumbers(item, 'sent')}
                  </div>
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {this.state.showPercentage
                      ? this.renderPercentage(item, 'failed', 'totalCount')
                      : this.renderNumbers(item, 'failed')}
                  </div>
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {this.state.showPercentage
                      ? this.renderPercentage(item, 'queued', 'totalCount')
                      : this.renderNumbers(item, 'queued')}
                  </div>
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {this.state.showPercentage
                      ? this.renderPercentage(item, 'delivered', 'sent')
                      : this.renderNumbers(item, 'delivered')}
                  </div>
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {this.state.showPercentage
                      ? this.renderPercentage(item, 'notDelivered', 'sent')
                      : this.renderNumbers(item, 'notDelivered')}
                  </div>
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {this.state.showPercentage
                      ? this.renderPercentage(
                          item,
                          'clicked',
                          'uniqueDelivered'
                        )
                      : this.renderNumbers(item, 'clicked')}
                  </div>
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {this.state.showPercentage
                      ? this.renderPercentage(
                          item,
                          'converted',
                          'uniqueDelivered'
                        )
                      : this.renderNumbers(item, 'converted')}
                  </div>
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {this.renderNumbers(item, 'revenue')}
                  </div>
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {this.state.showPercentage
                      ? this.renderPercentage(
                          item,
                          'convertedAfterClick',
                          'totalClicked'
                        )
                      : this.renderNumbers(item, 'convertedAfterClick')}
                  </div>
                </div>
              );
            })}
            {!isWinnerMechanismEnabled && this.renderOverall(variations)}
          </React.Fragment>
        )}
      </div>
    );
  };

  renderWinnerVariations = () => {
    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const variations = [];
    if (
      this.props.communicationReport &&
      this.props.communicationReport.variationsAggregations &&
      Array.isArray(this.props.communicationReport.variationsAggregations)
    ) {
      const foundItem =
        this.props.communicationReport.variationsAggregations.find(
          (element) =>
            element?.winnerMechanismTestPhase === false &&
            element?.variationId ===
              this.props.currentCommunication?.winnerMechanismWinnerVariationId
        );

      if (foundItem) {
        variations.push(foundItem);
      } else {
        this.props.communicationReport.variationsAggregations?.forEach(
          (item) => {
            if (
              item?.variationId ===
              this.props.currentCommunication?.winnerMechanismWinnerVariationId
            ) {
              variations.push(item);
            }
          }
        );
      }
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          borderRadius: 5,
          paddingBottom: 5,
          marginTop: 24,
          boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
        }}
      >
        {this.props.loadingCommunicationReport || this.props.loadingView ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              paddingTop: 50,
              paddingBottom: 50,
            }}
          >
            <Spin indicator={loadingSpin} />
          </div>
        ) : variations.length ? (
          <React.Fragment>
            <div
              style={{
                padding: 10,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  fontWeight: '700',
                }}
              >
                Winner Variation
              </div>
              <div>
                <Radio.Group
                  value={this.state.showPercentage}
                  onChange={(e) =>
                    this.setState({ showPercentage: e.target.value })
                  }
                >
                  <Radio.Button value={true}>%</Radio.Button>
                  <Radio.Button value={false}>#</Radio.Button>
                </Radio.Group>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                borderTop: '1px solid #e7e8e9',
              }}
            >
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 2,
                }}
              >
                TYPE
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                TOTAL
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                SENT
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                FAILURES
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                QUEUED
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                DELIVERED
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                NOT DELIVERED
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                UNIQUE CLICKS
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                UNIQUE CONVERSIONS
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                REVENUE
                {this.props.currentRevenueMapping &&
                  ' (' + this.props.currentRevenueMapping.revenueCurrency + ')'}
              </div>
              <div
                style={{
                  ...styles.tableTitle,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                ON CLICK CONVERSIONS
              </div>
            </div>

            {variations.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    borderTop: '1px solid #e7e8e9',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 2,
                    }}
                  >
                    {this.renderVariationLetter(item, index, true)}
                  </div>
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {this.renderNumbers(item, 'totalCount')}
                  </div>
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {this.state.showPercentage
                      ? this.renderPercentage(item, 'sent', 'totalCount')
                      : this.renderNumbers(item, 'sent')}
                  </div>
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {this.state.showPercentage
                      ? this.renderPercentage(item, 'failed', 'totalCount')
                      : this.renderNumbers(item, 'failed')}
                  </div>
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {this.state.showPercentage
                      ? this.renderPercentage(item, 'queued', 'totalCount')
                      : this.renderNumbers(item, 'queued')}
                  </div>
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {this.state.showPercentage
                      ? this.renderPercentage(item, 'delivered', 'sent')
                      : this.renderNumbers(item, 'delivered')}
                  </div>
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {this.state.showPercentage
                      ? this.renderPercentage(item, 'notDelivered', 'sent')
                      : this.renderNumbers(item, 'notDelivered')}
                  </div>
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {this.state.showPercentage
                      ? this.renderPercentage(
                          item,
                          'clicked',
                          'uniqueDelivered'
                        )
                      : this.renderNumbers(item, 'clicked')}
                  </div>
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {this.state.showPercentage
                      ? this.renderPercentage(
                          item,
                          'converted',
                          'uniqueDelivered'
                        )
                      : this.renderNumbers(item, 'converted')}
                  </div>
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {this.renderNumbers(item, 'revenue')}
                  </div>
                  <div
                    style={{
                      ...styles.tableData,
                      borderRight: '1px solid #e7e8e9',
                      flex: 1,
                    }}
                  >
                    {this.state.showPercentage
                      ? this.renderPercentage(
                          item,
                          'convertedAfterClick',
                          'totalClicked'
                        )
                      : this.renderNumbers(item, 'convertedAfterClick')}
                  </div>
                </div>
              );
            })}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div
              style={{
                padding: 10,
                display: 'flex',

                alignItems: 'center',
              }}
            >
              <div
                style={{
                  fontWeight: '700',
                }}
              >
                Winner Variation
              </div>
            </div>
            <div
              style={{
                padding: 10,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {this.props.currentCommunication
                ?.winnerMechanismWinnerVariationId === -1
                ? 'The test phase did not pass successfully.'
                : 'Control Group is winner'}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  renderPreviewModal = () => {
    return (
      <Modal
        width={900}
        bodyStyle={{ padding: 0 }}
        title={'Preview'}
        footer={null}
        visible={!isNil(this.state.selectedVariationIndex)}
        onOk={() => this.setState({ selectedVariationIndex: null })}
        onCancel={() => this.setState({ selectedVariationIndex: null })}
      >
        <CommunicationPreviewWidgetComponent
          currentCommunication={this.props.currentCommunication}
          smsChannels={this.props.smsChannels}
          activeVariationIndex={this.state.selectedVariationIndex}
        />
      </Modal>
    );
  };

  render() {
    return (
      <div style={{ padding: 30, position: 'relative' }}>
        {this.renderSummary()}
        {this.renderVariations()}
        {this.props.currentCommunication?.winnerMechanismEnabled &&
          !['W_UPCOMING', 'W_RUNNING'].includes(
            this.props.currentCommunication?.status
          ) &&
          this.renderWinnerVariations()}
        {this.renderReport()}
        {this.renderPreviewModal()}
      </div>
    );
  }
}

export default CommunicationViewComponent;
