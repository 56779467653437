import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { InputNumber, Select, Switch, Tooltip } from 'antd';
import { THEME_OPTIONS, CUSTOM_FONT_OPTIONS } from './helpers';
import FormItem from 'antd/es/form/FormItem';
import Card from '@Utils/Card';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router-dom';

const styles = {
  editFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 6,
    fontSize: 13,
    lineHeight: 1.43,
    color: '#3d404e',
  },

  headTitleContainer: {
    fontSize: 18,
    fontWeight: '500',
    color: '#3d404e',
    display: 'flex',
    flexDirection: 'row',
  },
  infoBoxHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 30px',
    borderBottom: '1px solid #e7e8e9',
    backgroundColor: '#fdfdfd',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dataRowContainer: {
    padding: 30,
  },
  fontContainer: {
    padding: '30px 30px 0px',
    display: 'flex',
  },
  dataRow: {
    paddingTop: 10,
    paddingBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  select: {
    width: '200px',
  },
  fontLabel: { display: 'flex', alignItems: 'center' },
  setting: { color: '#4AA0F8', fontSize: 14, cursor: 'pointer' },
};

const pickerStyles = {
  color: {
    width: '36px',
    height: '14px',
    borderRadius: '2px',
  },
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
    width: '65px',
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
};

export default function InAppThemeOptions({
  handleChange,
  variation,
  disabled,
  channel,
  customFonts,
}) {
  const { productId } = useParams();
  const [displayPicker, setDisplayPicker] = useState(false);
  const [displayOptions, setDisplayOptions] = useState(false);
  const [layoutType, setLayoutType] = useState('');
  useEffect(() => {
    if (
      variation?.theme?.label_color ||
      variation?.theme?.title_color ||
      variation?.theme?.description_color ||
      variation?.theme?.bg_color ||
      variation?.theme?.action_label_color ||
      variation?.theme?.action_bg_color ||
      variation?.theme?.border_radius ||
      variation?.theme?.font
    ) {
      setDisplayOptions(true);
    }
  }, [
    variation?.theme?.title_color,
    variation?.theme?.description_color,
    variation?.theme?.bg_color,
    variation?.theme?.action_label_color,
    variation?.theme?.action_bg_color,
    variation?.theme?.border_radius,
    variation?.theme?.label_color,
    variation?.theme?.font,
  ]);
  useEffect(() => {
    if (channel === 'IN_APP') {
      setLayoutType(variation?.inAppLayoutType);
    } else if (channel === 'ON_SITE') {
      setLayoutType(variation?.onSiteLayoutType);
    }
  }, [variation?.inAppLayoutType, variation?.onSiteLayoutType]);

  return (
    <>
      {layoutType !== 'IMAGE_ONLY' && (
        <Card
          id="theme_options"
          styles={{
            paddingLeft: 0,
            paddingRight: 0,
            border: '1px solid #F1F1F3',
            marginTop: 40,
            backgroundColor: 'white',
          }}
        >
          <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
            <div style={styles.headTitleContainer}>
              <span>Theme Options</span>
              <Switch
                disabled={disabled}
                checked={displayOptions}
                onChange={(value) => {
                  if (value === false) {
                    handleChange({});
                  }
                  setDisplayOptions(value);
                }}
                style={{ position: 'relative', top: 5, left: 10 }}
              />
            </div>
          </div>
          {displayOptions && (
            <>
              <div style={styles.fontContainer}>
                <div style={{ marginRight: 20 }}>Custom Font</div>
                <Select
                  showSearch
                  style={styles.select}
                  value={variation?.theme?.font ?? null}
                  optionFilterProp="children"
                  onChange={(value) => {
                    handleChange({
                      ...variation?.theme,
                      font: value,
                    });
                  }}
                >
                  <Select.OptGroup label={<Label productId={productId} />}>
                    {/*TODO: The value should be changed, base on new data model of theme
                    for in-app and on-site */}
                    {customFonts?.map((font) => (
                      <Select.Option
                        key={font.file.fileName}
                        value={font.file.fileName}
                      >
                        {font.file.fileName}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>

                  <Select.OptGroup label="System">
                    {CUSTOM_FONT_OPTIONS.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.title}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                </Select>
              </div>
              <div
                style={{
                  color: '#94969e',
                  fontSize: '10px',
                  width: '80%',
                  padding: '5px 30px',
                  marginLeft: '100px',
                  display: 'block',
                }}
              >
                When you select the default option, automauto will use the font
                based on your application’s font settings. Consequently, the
                preview section will display the automauto default font.
              </div>
              <div style={styles.dataRowContainer}>
                {THEME_OPTIONS.filter((opt) =>
                  opt.validFor.includes(
                    `${layoutType}${
                      layoutType === 'COUPON' ? `_${channel}` : ''
                    }`
                  )
                ).map((opt) => {
                  return (
                    <div key={opt.key} style={styles.dataRow}>
                      <div
                        style={{
                          ...styles.editLabel,
                          position: 'relative',
                        }}
                      >
                        {opt.title}
                      </div>
                      {opt.type === 'color_picker' ? (
                        <div
                          style={{
                            ...styles.editFieldContainer,
                            marginLeft: 20,
                            flex: 3,
                          }}
                        >
                          <div
                            style={{
                              ...pickerStyles.swatch,
                              backgroundColor:
                                (variation?.theme &&
                                  opt.key in variation.theme &&
                                  variation.theme[opt.key]) ||
                                opt.default,
                            }}
                            onClick={() => {
                              setDisplayPicker(opt.key);
                            }}
                          >
                            <div style={pickerStyles.color} />
                          </div>
                          {displayPicker === opt.key ? (
                            <div style={pickerStyles.popover}>
                              <div
                                style={pickerStyles.cover}
                                onClick={() => {
                                  setDisplayPicker(false);
                                }}
                              />
                              <SketchPicker
                                disableAlpha={true}
                                color={
                                  (variation?.theme &&
                                    opt.key in variation.theme &&
                                    variation.theme[opt.key]) ||
                                  opt.default
                                }
                                onChange={(value) => {
                                  handleChange({
                                    ...variation?.theme,
                                    [opt.key]: value.hex,
                                  });
                                }}
                              />
                            </div>
                          ) : null}
                        </div>
                      ) : opt.type === 'input' ? (
                        <div
                          style={{
                            ...styles.editFieldContainer,
                            marginLeft: 20,
                            flex: 3,
                          }}
                        >
                          <FormItem style={{ marginBottom: '0' }}>
                            <InputNumber
                              min={0}
                              onChange={(value) => {
                                handleChange({
                                  ...variation?.theme,
                                  [opt.key]: value,
                                });
                              }}
                              addonAfter="px"
                              value={
                                (variation?.theme &&
                                  opt.key in variation.theme &&
                                  variation.theme[opt.key]) ||
                                opt.default
                              }
                            />
                          </FormItem>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </Card>
      )}
    </>
  );
}

const Label = ({ productId }) => {
  return (
    <div style={styles.fontLabel}>
      <span>Custom</span>
      <Tooltip
        placement={'top'}
        title={
          <div>
            To use a custom font, upload the font in the{' '}
            <Link to={`/product/${productId}/settings/fonts`}>
              <span style={styles.setting}>settings</span>
            </Link>{' '}
            page. Once uploaded, the font will be available for selection here.
          </div>
        }
      >
        <i
          className="fl-info"
          style={{
            color: '#4AA0F8',
            fontSize: 24,
            fontWeight: 400,
          }}
        ></i>
      </Tooltip>
    </div>
  );
};
