export const flavors = {
  usermost: 'usermost',
  automauto: 'automauto',
  MCI: 'mci',
  DK: 'dk',
  SNAPP: 'snapp',
  OZONE: 'ozone',
  NOVATO: 'novato',
};

export function detectFlavor() {
  const location = window.location.href;

  if (location.includes('automauto')) {
    return flavors.automauto;
    // } else if (location.includes('mci.promo')) {
    //   return flavors.MCI;
    // } else if (location.includes('ecrm.digikala.promo')) {
    //   return flavors.DK;
    // } else if (location.includes('ecrm.ozone.ir')) {
    //   return flavors.OZONE;
    // } else if (location.includes('panel.novato.io')) {
    //   return flavors.NOVATO;
  } else {
    return flavors.usermost;
  }
}
