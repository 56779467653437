import React from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight.js';
import texts from '../../../../../utils/texts';

const PushFragment = (props) => {
  const { managedByCustomer, webPushKeyType } = props;
  return (
    <div>
      {(props.sdkType === 'react-native' ||
        props.sdkType === 'android' ||
        props.sdkType === 'flutter' ||
        props.sdkType === 'ios') && (
        <div style={{ padding: 30 }}>
          <div className="fragment-content">
            <span>
              Please configure Push as a channel{' '}
              <Link to={'../channels/push'}>here.</Link>
              and follow the instruction on documantation.
            </span>
          </div>
        </div>
      )}
      {props.sdkType === 'web' && (
        <div>
          {managedByCustomer && webPushKeyType === 'FIREBASE' && (
            <>
              <div style={{ padding: '32px 32px 16px' }}>
                <div style={{ paddingBottom: 16, paddingTop: 8 }}>
                  Import our Service Worker at the top of your Firebase SDK
                  Service Worker.
                </div>
              </div>
              <div className="rows-space">
                <div className="code-grey-card">
                  <Highlight language={'javascript'} className="highlight">
                    <div>{`${texts.importSw}\n`}</div>
                  </Highlight>
                </div>
              </div>
              <div style={{ padding: '32px 32px 16px' }}>
                <div style={{ paddingBottom: 16, paddingTop: 8 }}>
                  In the part of the incoming message, you must add the code
                  below in your Service Worker to return.
                </div>
              </div>
              <div className="rows-space">
                <div className="code-grey-card">
                  <Highlight language={'javascript'} className="highlight">
                    <div>{`onBackgroundMessage(messaging, (payload) => { \n if (payload.notification?.data?.source === "MAuto") return \n //... \n });`}</div>
                  </Highlight>
                </div>
              </div>

              <div style={{ padding: '32px 32px 16px' }}>
                The method sendFireBaseToken should be call when you retrieve
                the current token.
              </div>
              <div className="rows-space">
                <div className="code-grey-card">
                  <Highlight language={'javascript'} className="highlight">
                    <div>
                      {`getToken(messaging, { vapidKey: '<YOUR_PUBLIC_VAPID_KEY_HERE>' }).then((currentToken) => { \n if (currentToken) { \n  mAuto('sendFireBaseToken', currentToken);\n //...\n} else {\n//...
                        `}
                    </div>
                  </Highlight>
                </div>
              </div>
            </>
          )}
          {!(managedByCustomer && webPushKeyType === 'FIREBASE') && (
            <>
              <div style={{ padding: 30 }}>
                <div className="fragment-content">
                  <span>
                    Initializing {texts.brandName} notification service is done
                    by calling
                    <a
                      style={{ padding: '0 5px' }}
                      href={texts.webSdkNoti}
                      target={'_blank'}
                      rel="noreferrer"
                    >
                      {texts.BrandName}.InitWebPush()
                    </a>
                    with optional config parameter. This method should be called
                    after importing the {texts.brandName}.js file and calling{' '}
                    {texts.BrandName}.init()
                  </span>
                </div>
              </div>
              <div>
                <div className="rows-space">
                  <div
                    style={{
                      fontWeight: 'bold',
                      color: '#6e707a',
                      fontSize: '13px',
                      padding: '5px',
                    }}
                  >
                    Synchronous
                  </div>
                  <div className="code-grey-card">
                    <Highlight language={'javascript'} className="highlight">
                      <div>{`${texts.BrandName}.InitWebPush(config);`}</div>
                    </Highlight>
                  </div>
                  <div
                    style={{
                      fontWeight: 'bold',
                      color: '#6e707a',
                      fontSize: '13px',
                      padding: '5px',
                    }}
                  >
                    Asynchronous
                  </div>
                  <div className="code-grey-card">
                    <Highlight language={'javascript'} className="highlight">
                      <div>{"mAuto('InitWebPush', config);"}</div>
                    </Highlight>
                  </div>
                </div>
              </div>
              <div className="event-space">
                <div className="rows" style={{ marginLeft: 48 }}>
                  <div className="text">Sample Optional Config</div>
                </div>
                <div className="event-row-space ">
                  <div className="code-grey-card">
                    <Highlight language={'javascript'} className="highlight">
                      <div>
                        {'       config: {\n' +
                          '           subscriptionStyle: {\n' +
                          '             subscriptionTheme: 1,\n' +
                          '             subscriptionThemePos: 1,\n' +
                          '             subscriptionOverlayOpacity: 0,\n' +
                          '             subscriptionBoxColor: "#fff",\n' +
                          '             subscriptionBtnAllowTxt: "بله",\n' +
                          '             subscriptionBtnAllowColor: "#0e82e5",\n' +
                          '             subscriptionBtnAllowTxtColor: "#fff",\n' +
                          '             subscriptionBtnDenyTxt: "بعدا",\n' +
                          '             subscriptionBtnDenyColor: "#d3d3d3",\n' +
                          '             subscriptionBtnDenyTxtColor: "#888",\n' +
                          '             subscriptionTitle: "آخرین اطلاعات را از ما دریافت کنید!",\n' +
                          '             subscriptionTitleTxtColor: "#333",\n' +
                          '             subscriptionMessage: "آیا اجازه ارسال اعلان (نوتیفیکیشن) می‌دهید؟",\n' +
                          '             subscriptionMessageTxtColor: "#777",\n' +
                          '             subscriptionBoxDelay: 3000\n' +
                          '         },\n' +
                          '         subscriptionStyleMobileSeparate: false,\n' +
                          '         subscriptionStyleMobile: {\n' +
                          '             subscriptionTheme: 1,\n' +
                          '             subscriptionThemePos: 1,\n' +
                          '             subscriptionOverlayOpacity: 0,\n' +
                          '             subscriptionBoxColor: "#fff",\n' +
                          '             subscriptionBtnAllowTxt: "بله",\n' +
                          '             subscriptionBtnAllowColor: "#0e82e5",\n' +
                          '             subscriptionBtnAllowTxtColor: "#fff",\n' +
                          '             subscriptionBtnDenyTxt: "بعدا",\n' +
                          '             subscriptionBtnDenyColor: "#d3d3d3",\n' +
                          '             subscriptionBtnDenyTxtColor: "#888",\n' +
                          '             subscriptionTitle: "آخرین اطلاعات را از ما دریافت کنید!",\n' +
                          '             subscriptionTitleTxtColor: "#333",\n' +
                          '             subscriptionMessage: "آیا اجازه ارسال اعلان (نوتیفیکیشن) می‌دهید؟",\n' +
                          '             subscriptionMessageTxtColor: "#777",\n' +
                          '             subscriptionBoxDelay: 3000\n' +
                          '         },\n' +
                          '         isRTL: true\n' +
                          '       }\n'}
                      </div>
                    </Highlight>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default PushFragment;
