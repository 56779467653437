import React from 'react';
import { useSelector } from 'react-redux';
import { currentConversionType } from './CommunicationConst';
import { Form, Select, Input } from 'antd';
import { errorHandling } from '../../../../../utils/FormUtils';
import { timeDuration, winCriteria } from './CommunicationConst';

const WinnerMechanismSection = ({
  journey,
  winnerMechanism,
  handleWinnerMechanismChange,
  isEditDisable,
  winnerCriteriaFilter,
}) => {
  const currentConversion = useSelector(
    (state) => state.engage.communication.currentConversion
  );

  const errorUpdateConversionSubmit = useSelector(
    (state) => state.engage.communication.errorUpdateConversionSubmit
  );

  const currentType = currentConversion?.type;

  return (
    <div style={{ padding: '0px 0px 5px 5px' }}>
      <div style={{ ...styles.editContainer, marginTop: 20 }}>
        <div style={{ ...styles.editLabel }}>Size of Test Audience</div>
        <div
          style={{
            ...styles.editFieldContainer,
            marginLeft: 20,
            flexDirection: 'row',
          }}
        >
          {(currentType === currentConversionType.TRIGGERED || journey) && (
            <>
              <Form.Item
                validateStatus={
                  errorHandling(
                    errorUpdateConversionSubmit,
                    'winnerMechanismTestAudienceSize'
                  )
                    ? 'error'
                    : null
                }
                help={errorHandling(
                  errorUpdateConversionSubmit,
                  'winnerMechanismTestAudienceSize'
                )}
                id={
                  errorHandling(
                    errorUpdateConversionSubmit,
                    'winnerMechanismTestAudienceSize'
                  )
                    ? 'error'
                    : null
                }
              >
                <Input
                  id={'winner-mechanism-audience-value'}
                  disabled={isEditDisable()}
                  value={winnerMechanism.winnerMechanismTestAudienceSize}
                  onChange={(e) =>
                    handleWinnerMechanismChange(
                      {
                        ...winnerMechanism,
                        winnerMechanismTestAudienceSize: e.target.value,
                      },
                      'winnerMechanismTestAudienceSize'
                    )
                  }
                  style={{ width: 130, marginRight: '20px' }}
                />
                <span>messages</span>
                <div style={{ ...styles.timeTip }}>
                  <i
                    className="fl-info"
                    style={{
                      color: '#63C1FB',
                      fontSize: 24,
                      position: 'relative',
                      bottom: 2,
                      direction: 'ltr',
                    }}
                  ></i>
                  <span
                    style={{
                      color: '#545454',
                      fontSize: 12,
                    }}
                  >
                    Please note a minimum of 1000 messages will be sent (or not
                    sent in case of control group) before the winner is decided
                  </span>
                </div>
              </Form.Item>
            </>
          )}
          {currentType === currentConversionType.RECURRING && (
            <>
              <span>
                For recurring campaigns, all the variations (including control
                group, if any) will be sent (or not sent in case of control
                group) in equal proportions to the entire audience for the first
                run of the recurring campaign i.e the campaign sent to users on
                Day 1, Week 1 or Month 1. The winner will be decided based on
                the results of the first run so that for the subsequent runs
                only the winning variation gets sent (or not sent if control
                group is the winner)
              </span>
            </>
          )}
          {currentType === currentConversionType.ONE_TIME && (
            <Form.Item
              validateStatus={
                errorHandling(
                  errorUpdateConversionSubmit,
                  'winnerMechanismTestAudienceSize'
                )
                  ? 'error'
                  : null
              }
              help={errorHandling(
                errorUpdateConversionSubmit,
                'winnerMechanismTestAudienceSize'
              )}
              id={
                errorHandling(
                  errorUpdateConversionSubmit,
                  'winnerMechanismTestAudienceSize'
                )
                  ? 'error'
                  : null
              }
            >
              <Input
                id={'winner-mechanism-audience-value'}
                disabled={isEditDisable()}
                value={winnerMechanism.winnerMechanismTestAudienceSize}
                onChange={(e) =>
                  handleWinnerMechanismChange(
                    {
                      ...winnerMechanism,
                      winnerMechanismTestAudienceSize: e.target.value,
                    },
                    'winnerMechanismTestAudienceSize'
                  )
                }
                style={{ width: 130, marginRight: '20px' }}
                pos
                suffix={'%'}
              />
              <span>of audience</span>
            </Form.Item>
          )}
        </div>
      </div>
      <div style={{ ...styles.editContainer, marginTop: 20 }}>
        <div style={{ ...styles.editLabel }}>Time to Test</div>
        <div
          style={{
            ...styles.editFieldContainer,
            marginLeft: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {(currentType === currentConversionType.TRIGGERED || journey) && (
              <>
                <span>
                  For triggered campaigns, results are calculated daily at 6am
                  and 6pm. As soon as 1000 messages are successfully delivered
                  to users, automauto will wait for either the 6am or 6pm time
                  slot before deciding the winner.
                </span>
              </>
            )}
            {currentType === currentConversionType.RECURRING && (
              <>
                <span>
                  For recurring campaigns, the results of the test (based on the
                  first run) are decided just before the second run
                </span>
              </>
            )}
            {currentType === currentConversionType.ONE_TIME && (
              <>
                <Form.Item
                  validateStatus={
                    errorHandling(
                      errorUpdateConversionSubmit,
                      'winnerVariationTimeToTestMinutes'
                    )
                      ? 'error'
                      : null
                  }
                  help={errorHandling(
                    errorUpdateConversionSubmit,
                    'winnerVariationTimeToTestMinutes'
                  )}
                  id={
                    errorHandling(
                      errorUpdateConversionSubmit,
                      'winnerVariationTimeToTestMinutes'
                    )
                      ? 'error'
                      : null
                  }
                >
                  <Input
                    id={'int-winner-mechanism-time-to-test-value'}
                    disabled={isEditDisable()}
                    value={winnerMechanism.winnerMechanismTimeToTestValue}
                    onChange={(e) =>
                      handleWinnerMechanismChange(
                        {
                          ...winnerMechanism,
                          winnerMechanismTimeToTestValue: e.target.value,
                        },
                        'winnerVariationTimeToTestMinutes'
                      )
                    }
                    style={{ width: 60, marginRight: 20 }}
                  />
                  <Select
                    id={'int-winner-mechanism-time-to-test-duration'}
                    disabled={isEditDisable()}
                    style={{
                      width: 130,
                      marginRight: 20,
                      fontSize: 13,
                    }}
                    value={winnerMechanism.winnerMechanismTimeToTestDuration}
                    onChange={(conversionDuration) =>
                      handleWinnerMechanismChange({
                        ...winnerMechanism,
                        winnerMechanismTimeToTestDuration: conversionDuration,
                      })
                    }
                  >
                    {Object.keys(timeDuration).map((time) => {
                      return (
                        <Select.Option value={time} key={time}>
                          {timeDuration[time]}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <span style={{ marginBottom: '32px' }}>
                  from delivery of message to the last user
                </span>
              </>
            )}
          </div>
        </div>
      </div>

      <div style={{ ...styles.editContainer, marginTop: 20 }}>
        <div style={{ ...styles.editLabel }}>Win Criteria</div>
        <div
          style={{
            ...styles.editFieldContainer,
            marginLeft: 20,
          }}
        >
          <Form.Item
            validateStatus={
              errorHandling(
                errorUpdateConversionSubmit,
                'winnerMechanismWinVariationCriteria'
              )
                ? 'error'
                : null
            }
            help={errorHandling(
              errorUpdateConversionSubmit,
              'winnerMechanismWinVariationCriteria'
            )}
            id={
              errorHandling(
                errorUpdateConversionSubmit,
                'winnerMechanismWinVariationCriteria'
              )
                ? 'error'
                : null
            }
          >
            <Select
              id={'int-winner-mechanism-win-variation-criteria'}
              disabled={isEditDisable()}
              style={{
                width: 130,
                marginRight: 20,
                fontSize: 13,
              }}
              value={winnerMechanism.winnerMechanismWinVariationCriteria}
              onChange={(criteria) => {
                handleWinnerMechanismChange(
                  {
                    ...winnerMechanism,
                    winnerMechanismWinVariationCriteria: criteria,
                  },
                  'winnerMechanismWinVariationCriteria'
                );
              }}
            >
              {winnerCriteriaFilter.map((Criteria) => {
                return (
                  <Select.Option value={Criteria} key={Criteria}>
                    {winCriteria[Criteria]}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default WinnerMechanismSection;

const styles = {
  editContainer: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
  },
  editFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 5,
    fontSize: 13,
    lineHeight: 1.43,
    color: '#3d404e',
  },
  editField: {
    width: 350,
  },
  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
    flex: 1,
    textAlign: 'right',
  },
  timeTip: {
    justifyContent: 'center',
    flex: 2,
    display: 'flex',
    // paddingRight: '32px',
    marginTop: '8px',
  },
};
