import React from 'react';
import ListBase, { defaultActions } from '../../../../component/form/ListBase';
import smtpLogo from '../../../../theme/icons/smtp.png';
import mandrillLogo from '../../../../theme/icons/mandrill.png';
import sendmostLogo from '../../../../theme/icons/sendmost.png';
import privateEsp from '../../../../theme/icons/private ssp.svg';
import { isEmpty, isNil, cloneDeep } from 'lodash';
import { Button, Form, Input, InputNumber, Modal, Select, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import EmailProviders, {
  InheritableEmailProviders,
} from '../model/EmailProviders';
import { LoadingOutlined } from '@ant-design/icons';
import { hasAnyAuthority, AuthorityProvider } from '@Utils/AuthorityProvider';
import style from '../../channel.module.less';
import Card from '@Utils/Card';

const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  inputLoadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  editContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
  },
  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    fontWeight: '600',
    flex: 1,
  },
  editFieldContainer: {
    flex: 1,
  },
  editField: {
    marginLeft: 20,
    width: 350,
  },
  headTitleContainer: {
    fontSize: 18,
    fontWeight: '500',
    color: '#3d404e',
    display: 'flex',
    flexDirection: 'row',
  },
  infoBoxHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 30px',
    borderBottom: '1px solid #e7e8e9',
    backgroundColor: '#fdfdfd',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dataRowContainer: {
    padding: 30,
  },
};
const RenderDocsLink = ({ show }) => {
  if (!show) return null;
  return (
    <Button
      key="link"
      className={`text-title-md ${style.btnDocuments}`}
      type="link"
      href="#"
    >
      <u>View Documentation</u>
    </Button>
  );
};
class EmailChannelComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalId: null,
      vendor: null,
      name: '',
      deliveryByVendor: null,
    };

    this.permissions = {
      view: hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CHANNEL_EMAIL_VIEW),
      createOrUpdate: hasAnyAuthority(
        AuthorityProvider.ROLE_PRODUCT_CHANNEL_EMAIL_UPDATE,
        AuthorityProvider.ROLE_PRODUCT_CHANNEL_EMAIL_CREATE
      ),
      delete: hasAnyAuthority(
        AuthorityProvider.ROLE_PRODUCT_CHANNEL_EMAIL_DELETE
      ),
    };
  }

  componentDidMount() {
    this.props.emailChannelListFetch(this.props.pageRequest);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadingUpdateSubmit && !this.props.loadingUpdateSubmit) {
      this.setState({ modalId: null });
    }
    if (prevProps.loadingCreate && !this.props.loadingCreate) {
      this.setState({ modalId: null });
    }
  }
  providerOneOf = (providers) =>
    providers.includes(this.props.currentEmailChannel.vendor) ||
    providers.includes(this.props.currentEmailChannel.deliveryByVendor);
  addProvider = (vendor) => {
    if (this?.permissions?.createOrUpdate) {
      this.props.emailChannelChangeCurrent({
        name: null,
        domain: null,
        apiKey: null,
        password: null,
        vendor,
        sender: null,
        username: null,
        customSendHeaders: [],
        deliveryByVendor: null,
      });
      this.setState({ modalId: true });
    }
  };

  renderSystem = (x) => {
    if (isNil(x)) {
      return '--';
    }
    return x ? 'System' : 'Custom';
  };

  handleChangeSearch = (name) => {
    this.setState({ name });
    if (name === '' || name === null) {
      this.props.emailChannelListFetch({
        ...this.props.pageRequest,
        page: 0,
        name: null,
      });
    }
  };

  handleChangeProvider = (vendor) => {
    this.setState({ vendor });
    this.props.emailChannelListFetch({
      ...this.props.pageRequest,
      page: 0,
      vendor,
    });
  };

  openModal = (x) => {
    this.props.emailChannelUpdateFetch(x.id, this.permissions.createOrUpdate);
    this.setState({ modalId: x.id });
  };

  onOkModal = () => {
    if (this.state.modalId === true) {
      this.props.emailChannelCreateFetch(
        this.props.currentEmailChannel,
        this.props.pageRequest
      );
    } else {
      this.props.emailChannelUpdateSubmitFetch(
        this.state.modalId,
        this.props.currentEmailChannel,
        this.props.pageRequest
      );
    }
  };

  setAttribute = (key, value) => {
    const currentEmailChannel = cloneDeep(this.props.currentEmailChannel);
    currentEmailChannel[key] = value;
    this.props.emailChannelChangeCurrent(currentEmailChannel);
  };
  addKeyValue = () => {
    let headers = this.props.currentEmailChannel.customSendHeaders;
    headers.push({ key: '', value: '' });
    this.props.emailChannelChangeCurrent({
      ...this.props.currentEmailChannel,
      customSendHeaders: headers,
    });
  };
  handleRemoveKeyValue = (keyValueIndex) => {
    let headers = this.props.currentEmailChannel.customSendHeaders;
    headers = headers.filter((_, i) => i !== keyValueIndex);
    this.props.emailChannelChangeCurrent({
      ...this.props.currentEmailChannel,
      customSendHeaders: headers,
    });
  };
  handleChangeKey = (keyValueIndex, value) => {
    let { customSendHeaders } = this.props.currentEmailChannel;
    customSendHeaders = [
      ...customSendHeaders.slice(0, keyValueIndex),
      {
        ...customSendHeaders[keyValueIndex],
        key: value,
      },
      ...customSendHeaders.slice(keyValueIndex + 1),
    ];
    this.props.emailChannelChangeCurrent({
      ...this.props.currentEmailChannel,
      customSendHeaders: customSendHeaders,
    });
  };
  handleChangeValue = (keyValueIndex, value) => {
    let { customSendHeaders } = this.props.currentEmailChannel;
    customSendHeaders = [
      ...customSendHeaders.slice(0, keyValueIndex),
      {
        ...customSendHeaders[keyValueIndex],
        value: value,
      },
      ...customSendHeaders.slice(keyValueIndex + 1),
    ];
    this.props.emailChannelChangeCurrent({
      ...this.props.currentEmailChannel,
      customSendHeaders: customSendHeaders,
    });
  };
  renderContent = () => {
    return this.props.loadingUpdate ? (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin style={{ display: 'flex', alignSelf: 'center' }} />
      </div>
    ) : (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={styles.editContainer}>
          <div style={styles.editLabel}>ESP</div>
          <div style={styles.editFieldContainer}>
            <Select
              value={
                this.props.currentEmailChannel &&
                this.props.currentEmailChannel.vendor
                  ? this.props.currentEmailChannel.vendor
                  : null
              }
              style={styles.editField}
              size="large"
              disabled={!this?.permissions?.createOrUpdate}
              onChange={(vendor) =>
                this.props.emailChannelChangeCurrent({
                  ...this.props.currentEmailChannel,
                  vendor,
                })
              }
            >
              {Object.keys(EmailProviders).map((item) => (
                <Select.Option value={item} key={item}>
                  {EmailProviders[item]}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <div style={styles.editContainer}>
          <div style={styles.editLabel}>Name</div>
          <div style={styles.editFieldContainer}>
            <Input
              disabled={!this?.permissions?.createOrUpdate}
              onChange={(e) =>
                this.props.emailChannelChangeCurrent({
                  ...this.props.currentEmailChannel,
                  name: e.target.value,
                })
              }
              value={
                this.props.currentEmailChannel &&
                this.props.currentEmailChannel.name
                  ? this.props.currentEmailChannel.name
                  : ''
              }
              size="large"
              style={styles.editField}
            />
          </div>
        </div>

        {this.providerOneOf(['PRIVATE']) && (
          <>
            <div style={styles.editContainer}>
              <div style={styles.editLabel}>URL</div>
              <div style={styles.editFieldContainer}>
                <Input
                  disabled={!this?.permissions?.createOrUpdate}
                  onChange={(e) =>
                    this.props.emailChannelChangeCurrent({
                      ...this.props.currentEmailChannel,
                      customSendUrl: e.target.value,
                    })
                  }
                  value={
                    this.props.currentEmailChannel &&
                    this.props.currentEmailChannel.customSendUrl
                      ? this.props.currentEmailChannel.customSendUrl
                      : ''
                  }
                  size="large"
                  style={styles.editField}
                />
              </div>
            </div>
            <div style={styles.editContainer}>
              <div style={styles.editLabel}>Header (s)</div>
              <div style={styles.editFieldContainer}>
                {this.props.currentEmailChannel.customSendHeaders.map(
                  (item, keyValueIndex) => {
                    const keyCyData = 'push-key' + (keyValueIndex + 1);
                    const valueCyData = 'push-value' + (keyValueIndex + 1);
                    const removeKeyValueCyData =
                      'remove-key-value' + (keyValueIndex + 1);
                    return (
                      <div key={keyValueIndex} id="keyValue">
                        <Form.Item>
                          <Input
                            placeholder="Key"
                            data-cy={keyCyData}
                            value={item && item.key ? item.key : ''}
                            onChange={(e) =>
                              this.handleChangeKey(
                                keyValueIndex,
                                e.target.value
                              )
                            }
                          />
                        </Form.Item>
                        <span style={{ margin: '15px 10px' }}>=</span>
                        <Form.Item>
                          <Input
                            placeholder="Value"
                            data-cy={valueCyData}
                            value={item && item.value ? item.value : ''}
                            onChange={(e) =>
                              this.handleChangeValue(
                                keyValueIndex,
                                e.target.value
                              )
                            }
                          />
                        </Form.Item>

                        <i
                          data-cy={removeKeyValueCyData}
                          onClick={() => {
                            this.handleRemoveKeyValue(keyValueIndex);
                          }}
                          className="fl-dark fl-delete pointer"
                          style={{
                            fontSize: 24,
                            color: 'rgb(187, 188, 193)',
                            margin: '15px 10px',
                          }}
                        />
                      </div>
                    );
                  }
                )}
                <Button
                  style={{ width: 350 }}
                  block
                  onClick={() => this.addKeyValue()}
                >
                  Add Key-Value
                </Button>
              </div>
            </div>
            <div style={styles.editContainer}>
              <div style={styles.editLabel}>Delivery ESP</div>
              <div style={styles.editFieldContainer}>
                <Select
                  value={
                    this.props.currentEmailChannel &&
                    this.props.currentEmailChannel.deliveryByVendor
                      ? this.props.currentEmailChannel.deliveryByVendor
                      : null
                  }
                  style={styles.editField}
                  size="large"
                  disabled={!this?.permissions?.createOrUpdate}
                  placeholder={'No Delivery'}
                  onChange={(deliveryByVendor) => {
                    let value;
                    if (deliveryByVendor === 'NO_DELIVERY') {
                      value = null;
                    } else {
                      value = deliveryByVendor;
                    }
                    this.props.emailChannelChangeCurrent({
                      ...this.props.currentEmailChannel,
                      deliveryByVendor: value,
                      apiKey: null,
                    });
                  }}
                >
                  {Object.keys(InheritableEmailProviders).map((item) => (
                    <Select.Option value={item} key={item}>
                      {InheritableEmailProviders[item]}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </>
        )}

        {this.props.currentEmailChannel &&
          this.providerOneOf(['SENDMOST', 'MANDRILL']) && (
            <div style={styles.editContainer}>
              <div style={styles.editLabel}>API Key</div>
              <div style={styles.editFieldContainer}>
                <Input.Password
                  autoComplete="new-password"
                  disabled={!this?.permissions?.createOrUpdate}
                  onChange={(e) =>
                    this.props.emailChannelChangeCurrent({
                      ...this.props.currentEmailChannel,
                      apiKey: e.target.value,
                    })
                  }
                  value={
                    this?.permissions?.createOrUpdate
                      ? this.props?.currentEmailChannel?.apiKey ||
                        (this.props?.currentEmailChannel?.createdDate && '***')
                      : '***'
                  }
                  size="large"
                  style={styles.editField}
                />
              </div>
            </div>
          )}
        {this.props.currentEmailChannel && this.providerOneOf(['SMTP']) && (
          <div style={styles.editContainer}>
            <div style={styles.editLabel}>Host</div>
            <div style={styles.editFieldContainer}>
              <Input
                disabled={!this?.permissions?.createOrUpdate}
                onChange={(e) =>
                  this.props.emailChannelChangeCurrent({
                    ...this.props.currentEmailChannel,
                    host: e.target.value,
                  })
                }
                value={
                  this.props.currentEmailChannel &&
                  this.props.currentEmailChannel.host
                    ? this.props.currentEmailChannel.host
                    : ''
                }
                size="large"
                style={styles.editField}
              />
            </div>
          </div>
        )}
        {this.props.currentEmailChannel && this.providerOneOf(['SMTP']) && (
          <div style={styles.editContainer}>
            <div style={styles.editLabel}>Port</div>
            <div style={styles.editFieldContainer}>
              <Input
                disabled={!this?.permissions?.createOrUpdate}
                onChange={(e) =>
                  this.props.emailChannelChangeCurrent({
                    ...this.props.currentEmailChannel,
                    port: e.target.value,
                  })
                }
                value={
                  this.props.currentEmailChannel &&
                  this.props.currentEmailChannel.port
                    ? this.props.currentEmailChannel.port
                    : ''
                }
                size="large"
                style={styles.editField}
              />
            </div>
          </div>
        )}
        {this.props.currentEmailChannel && this.providerOneOf(['SMTP']) && (
          <div style={styles.editContainer}>
            <div style={styles.editLabel}>Security</div>
            <div style={styles.editFieldContainer}>
              <Select
                style={styles.editField}
                value={
                  this?.permissions?.createOrUpdate
                    ? this.props?.currentEmailChannel?.security ||
                      (this.props?.currentEmailChannel?.createdDate && '***')
                    : '***'
                }
                size="large"
                disabled={!this?.permissions?.createOrUpdate}
                onChange={(security) =>
                  this.props.emailChannelChangeCurrent({
                    ...this.props.currentEmailChannel,
                    security,
                  })
                }
              >
                <Select.Option value={'RAW'}>Raw</Select.Option>
                <Select.Option value={'TLS'}>TLS</Select.Option>
                <Select.Option value={'SSL'}>SSL</Select.Option>
              </Select>
              {/* <Input.Password
                  autoComplete="new-password"
                  disabled={!this?.permissions?.createOrUpdate}
                  onChange={(e) =>
                    this.props.emailChannelChangeCurrent({
                      ...this.props.currentEmailChannel,
                      apiKey: e.target.value,
                    })
                  }
                  value={
                    this?.permissions?.createOrUpdate
                      ? this.props?.currentEmailChannel?.apiKey ||
                        (this.props?.currentEmailChannel?.createdDate && '***')
                      : '***'
                  }
                  size="large"
                  style={styles.editField}
                /> */}
            </div>
          </div>
        )}
        {this.props.currentEmailChannel && this.providerOneOf(['SMTP']) && (
          <div style={styles.editContainer}>
            <div style={styles.editLabel}>Username</div>
            <div style={styles.editFieldContainer}>
              <Input
                disabled={!this?.permissions?.createOrUpdate}
                onChange={(e) =>
                  this.props.emailChannelChangeCurrent({
                    ...this.props.currentEmailChannel,
                    username: e.target.value,
                  })
                }
                value={
                  this.props.currentEmailChannel &&
                  this.props.currentEmailChannel.username
                    ? this.props.currentEmailChannel.username
                    : ''
                }
                size="large"
                style={styles.editField}
              />
            </div>
          </div>
        )}
        {this.props.currentEmailChannel && this.providerOneOf(['SMTP']) && (
          <div style={styles.editContainer}>
            <div style={styles.editLabel}>Password</div>
            <div style={styles.editFieldContainer}>
              <Input.Password
                autoComplete="new-password"
                disabled={!this?.permissions?.createOrUpdate}
                onChange={(e) =>
                  this.props.emailChannelChangeCurrent({
                    ...this.props.currentEmailChannel,
                    password: e.target.value,
                  })
                }
                value={
                  this?.permissions?.createOrUpdate
                    ? this.props?.currentEmailChannel?.password ||
                      (this.props?.currentEmailChannel?.createdDate && '***')
                    : '***'
                }
                size="large"
                style={styles.editField}
              />
            </div>
          </div>
        )}
        {this.props.currentEmailChannel && this.providerOneOf(['SENDMOST']) && (
          <div style={styles.editContainer}>
            <div style={styles.editLabel}>Batch Size</div>
            <div style={styles.editFieldContainer}>
              <InputNumber
                disabled={!this?.permissions?.createOrUpdate}
                onChange={(e) =>
                  this.props.emailChannelChangeCurrent({
                    ...this.props.currentEmailChannel,
                    batchSize: e,
                  })
                }
                value={
                  this.props.currentEmailChannel &&
                  this.props.currentEmailChannel.batchSize
                    ? this.props.currentEmailChannel.batchSize
                    : ''
                }
                style={styles.editField}
                min={2}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  renderModalTitle = () => {
    if (this.state.modalId === true) {
      return 'Add New ESP';
    }
    return 'Edit ESP';
  };

  renderModal = () => {
    return (
      <Modal
        width={650}
        title={this.renderModalTitle()}
        centered
        visible={!isNil(this.state.modalId)}
        onOk={() => this.setState({ modalId: null })}
        onCancel={() => this.setState({ modalId: null })}
        footer={[
          <Button
            key="cancel"
            shape="round"
            onClick={() => this.setState({ modalId: null })}
          >
            CANCEL
          </Button>,
          <Button
            loading={this.props.loadingUpdateSubmit || this.props.loadingCreate}
            key="submit"
            shape="round"
            type="primary"
            disabled={!this?.permissions?.createOrUpdate}
            onClick={() => this.onOkModal()}
          >
            Save
          </Button>,
          // eslint-disable-next-line react/jsx-key
          <RenderDocsLink show={this.providerOneOf(['PRIVATE'])} />,
        ]}
      >
        <div>{this.renderContent()}</div>
      </Modal>
    );
  };

  render() {
    const columnList = [
      {
        sorter: false,
        title: 'ESP',
        dataIndex: 'vendor',
        key: 'vendor',
        width: 300,
        render: (x) => EmailProviders[x],
        // ...getColumnTextSearchProps('title'),
      },
      {
        sorter: false,
        title: 'configuration name',
        dataIndex: 'name',
        key: 'name',
        width: 300,
        // ...getColumnTextSearchProps('name'),
      },
      {
        title: 'action',
        dataIndex: '',
        key: '-action-',
        width: '5%',
        render: (x) =>
          defaultActions(
            x,
            {
              action: () => this.openModal(x),
              title: this?.permissions?.createOrUpdate ? 'Edit' : 'View',
              enabled: this?.permissions?.view,
              disablePopConfirm: true,
            },
            // { link: 'copy/' + x.id, title: 'Duplicate', enabled: hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CUSTOM_EVENT_CREATE) },
            {
              action: (id) =>
                this.props.emailChannelDeleteFetch(id, this.props.pageRequest),
              title: 'Delete',
              enabled: this?.permissions?.delete,
            }
          ),
      },
    ];

    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;

    return (
      <div>
        <Card title="Your Email Service Providers (ESP)">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '12px',
            }}
          >
            <Input
              loading={true}
              allowClear={this.props.loadingSearchList ? false : true}
              placeholder={'Search by configuration name'}
              onPressEnter={() =>
                this.props.emailChannelSearchListFetch({
                  ...this.props.pageRequest,
                  page: 0,
                  name: this.state.name,
                })
              }
              onChange={(e) => this.handleChangeSearch(e.target.value)}
              value={this.state.name}
              style={{ width: 300, borderRadius: 5 }}
              suffix={
                isEmpty(this.state.name) ? (
                  <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                ) : (
                  this.props.loadingSearchList && (
                    <div style={styles.inputLoadingSpinStyle}>
                      <Spin indicator={inputLoadingSpin} />
                    </div>
                  )
                )
              }
            />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Select
                onChange={(vendor) => this.handleChangeProvider(vendor)}
                value={this.state.vendor}
                defaultValue="all"
                style={{ width: 120 }}
                className={'chart-selector'}
                bordered={false}
              >
                <Select.Option value={null}>All Providers</Select.Option>
                {Object.keys(EmailProviders).map((item) => (
                  <Select.Option value={item} key={item}>
                    {EmailProviders[item]}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          {this.props.loadingList ? (
            <div style={styles.loadingSpinStyle}>
              <Spin indicator={loadingSpin} />
            </div>
          ) : (
            <ListBase
              onChange={this.props.emailChannelListFetch}
              page={this.props.page}
              pageRequest={this.props.pageRequest}
              columns={columnList}
              // onClick={(row) => this.viewItem(row.id)}
              createLink={null}
            />
          )}
        </Card>
        <Card
          styles={{ marginTop: 20 }}
          title="Available Email Service Providers (ESP)"
        >
          <div style={styles.dataRowContainer}>
            <p style={{ color: '#767577' }}>
              Select a ESP below to configure and add to your project
            </p>
            <div className={style.serviceProviderContainer}>
              <div
                onClick={() => {
                  this.addProvider('MANDRILL');
                }}
                className={style.serviceProviderBox}
              >
                <img src={mandrillLogo} />
                <span>Mandrill</span>
              </div>
              <div
                onClick={() => {
                  this.addProvider('SENDMOST');
                }}
                className={style.serviceProviderBox}
              >
                <img src={sendmostLogo} />
                <span>SendMost</span>
              </div>
              <div
                onClick={() => {
                  this.addProvider('SMTP');
                }}
                className={style.serviceProviderBox}
              >
                <img src={smtpLogo} />
                <span>SMTP</span>
              </div>
              <div
                onClick={() => {
                  this.addProvider('PRIVATE');
                }}
                className={style.serviceProviderBox}
              >
                <img src={privateEsp} />
                <span>Private ESP</span>
              </div>
            </div>
          </div>
        </Card>
        {this.renderModal()}
      </div>
    );
  }
}

export default EmailChannelComponent;
