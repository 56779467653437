import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Form,
  Button,
  Select,
  Badge,
  Tooltip,
  Switch,
  Col,
  Row,
  Input,
  Divider,
  Alert,
} from 'antd';
import { unset } from 'lodash';
import {
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { AppContext } from '../../app';
import ModalEventFilterSelector from '@Modules/analytics/funnel/component/ModalEventFilterSelector';
import vars from '@Theme/styles/vars';
import { useTrOccurrenceBusinessEventAggregation } from './useTrOccurrenceBusinessEventAggregation';

const { Option, OptGroup } = Select;

const selectFilterOption = (input, option) => {
  if (option.children) {
    return (
      option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  }
};

const OPERATORS = [
  {
    name: 'EQUAL_TO',
    label: 'Equal to',
    negateLabel: 'Does Not Equal to',
    dataTypes: ['BOOLEAN', 'NUMERIC', 'STRING'],
  },
  {
    name: 'STARTS_WITH',
    label: 'Starts with',
    negateLabel: 'Does Not starts with',
    dataTypes: ['STRING'],
  },
  {
    name: 'ENDS_WITH',
    label: 'Ends with',
    negateLabel: 'Does Not Ends with',
    dataTypes: ['STRING'],
  },
  {
    name: 'CONTAINS',
    label: 'Contains',
    negateLabel: 'Does Not Contains',
    dataTypes: ['STRING'],
  },
  {
    name: 'LESS_THAN',
    label: 'Less than or equal',
    negateLabel: 'Greater than',
    dataTypes: ['DATE', 'NUMERIC'],
  },
  {
    name: 'GREATER_THAN',
    label: 'Greater than or equal',
    negateLabel: 'Less than',
    dataTypes: ['DATE', 'NUMERIC'],
  },
  {
    name: 'DATE_EQUALS',
    label: 'Date equal to',
    negateLabel: 'Date not equal to',
    dataTypes: ['DATE'],
  },
];

const EventMapper = (props) => {
  const conjunctionFieldName = `${props.listName}Conjunction`;

  useEffect(() => {
    const formValues = props.form.getFieldsValue();
    if (!formValues[props.listName]?.length) {
      formValues[props.listName] = [
        {
          businessAttr: null,
          operator: null,
          sourceAttr: null,
          negate: false,
        },
      ];
      formValues[conjunctionFieldName] = 'AND';
      props.form.setFieldsValue(formValues);
    }
  }, []);

  const sourceAttributeOptions = useMemo(() => {
    const sourceAttributes = props.sourceAttributes;
    const options = [];
    options.push({
      groupLabel: 'SYSTEM',
      list: sourceAttributes?.filter((attr) => attr.system) || [],
    });
    options.push({
      groupLabel: 'CUSTOM',
      list: sourceAttributes?.filter((attr) => !attr.system) || [],
    });
    return options;
  }, [props.sourceAttributes]);

  const getSelectedSourceType = (idx) => {
    const formValues = props.form.getFieldsValue();

    if (
      props.sourceAttributes &&
      formValues?.[props.listName]?.length > idx &&
      formValues?.[props.listName][idx] &&
      formValues?.[props.listName][idx]?.sourceAttr
    ) {
      const selectedAttr = props.sourceAttributes.find(
        (item) => item.id === formValues[props.listName][idx].sourceAttr
      );
      return selectedAttr && selectedAttr?.type;
    }

    return null;
  };

  return (
    <>
      <Form.Item name={conjunctionFieldName} style={{ display: 'none' }}>
        <Input />
      </Form.Item>
      <Row gutter={16}>
        <Col span={6} className="relay-label">
          {props.sourceLabel}
        </Col>
        <Col span={3} className="relay-label">
          Data Type
        </Col>
        <Col span={4} className="relay-label">
          Operator
        </Col>
        <Col span={6} className="relay-label">
          Business Event Attribute
        </Col>
        <Col span={3} className="relay-label">
          Data Type
        </Col>
      </Row>
      <Form.List name={props.listName}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, idx) => (
              <>
                <Row key={field.key} gutter={16}>
                  <Col span="6">
                    <Form.Item
                      {...field}
                      name={[field.name, 'sourceAttr']}
                      rules={[{ required: true, message: 'select an event' }]}
                      style={{ width: '100%' }}
                    >
                      <Select
                        style={{ width: '100%' }}
                        showSearch
                        filterOption={selectFilterOption}
                        onChange={(v) => {
                          const formValues = props.form.getFieldsValue();
                          const resetedRow = {
                            businessAttr: null,
                            negate: false,
                            operator:
                              getSelectedSourceType(idx) === 'DATE'
                                ? 'DATE_EQUALS'
                                : 'EQUAL_TO',
                            sourceAttr: v,
                            type: formValues?.[props.listName][idx]?.type,
                          };
                          formValues[props.listName][idx] = resetedRow;
                          props.form.setFieldsValue(formValues);
                        }}
                      >
                        {sourceAttributeOptions?.map((group) => (
                          <OptGroup
                            key={group.groupLabel}
                            label={group.groupLabel}
                          >
                            {group.list.map((attr) => (
                              <Option key={attr.id} value={attr.id}>
                                {attr.title}
                              </Option>
                            ))}
                          </OptGroup>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span="3">
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) => {
                        return (
                          curValues?.[props.listName]?.length > idx &&
                          curValues?.[props.listName][idx] &&
                          curValues?.[props.listName][idx]?.sourceAttr
                        );
                      }}
                      noStyle
                    >
                      {() => {
                        const attrType = getSelectedSourceType(idx);
                        return <Input value={attrType} disabled />;
                      }}
                    </Form.Item>
                  </Col>
                  <Col span="4">
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) => {
                        return (
                          curValues?.[props.listName]?.length > idx &&
                          curValues?.[props.listName][idx] &&
                          prevValues?.[props.listName]?.length > idx &&
                          prevValues?.[props.listName][idx] &&
                          curValues?.[props.listName][idx]?.negate !==
                            prevValues?.[props.listName][idx]?.negate
                        );
                      }}
                    >
                      {() => {
                        const formValues = props.form.getFieldsValue();
                        const attrType = getSelectedSourceType(idx);
                        const checked =
                          formValues[props.listName] &&
                          formValues[props.listName].length &&
                          formValues[props.listName][idx]?.negate;

                        return (
                          <Form.Item
                            {...field}
                            name={[field.name, 'operator']}
                            rules={[
                              { required: true, message: 'select operator' },
                            ]}
                            style={{ width: '100%' }}
                          >
                            <Select
                              style={{ width: '100%' }}
                              dropdownRender={(menu) => (
                                <div>
                                  {menu}
                                  <Divider style={{ margin: '4px 0' }} />
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginRight: 10,
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: '#1c1c1c',
                                      }}
                                    >
                                      Negate:
                                    </div>
                                    <div>
                                      <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onChange={(value) => {
                                          if (
                                            formValues[props.listName] &&
                                            formValues[props.listName]?.[idx]
                                          ) {
                                            formValues[props.listName][
                                              idx
                                            ].negate = value;
                                          } else if (
                                            formValues[props.listName]
                                          ) {
                                            formValues[props.listName][idx] = {
                                              negate: value,
                                            };
                                          }
                                          props.form.setFieldsValue(formValues);
                                        }}
                                        checked={checked}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            >
                              {OPERATORS.filter((op) => {
                                return op.dataTypes.includes(attrType);
                              }).map((op) => (
                                <Option value={op.name} key={op.name}>
                                  {checked ? op.negateLabel : op.label}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                  <Col span="6">
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) => {
                        return (
                          curValues?.[props.listName]?.length > idx &&
                          curValues?.[props.listName][idx] &&
                          curValues?.[props.listName][idx]?.sourceAttr
                        );
                      }}
                    >
                      {() => {
                        const sourceType = getSelectedSourceType(idx);

                        return (
                          <Form.Item
                            {...field}
                            noStyle
                            label="business event Attribute"
                            name={[field.name, 'businessAttr']}
                            rules={[{ required: true, message: 'reqired' }]}
                            style={{ width: '100%' }}
                          >
                            <Select style={{ width: '100%' }}>
                              {props?.businessAttributes
                                ?.filter(
                                  (attr) =>
                                    sourceType && attr.type === sourceType
                                )
                                ?.map((attr) => (
                                  <Option key={attr.id} value={attr.id}>
                                    {attr.name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                  <Col span="3">
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) => {
                        return (
                          curValues?.[props.listName]?.length > idx &&
                          curValues?.[props.listName][idx] &&
                          curValues?.[props.listName][idx]?.sourceAttr
                        );
                      }}
                      noStyle
                    >
                      {() => {
                        const attrType = getSelectedSourceType(idx);
                        return <Input value={attrType} disabled />;
                      }}
                    </Form.Item>
                  </Col>
                  <Col span="2">
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Col>
                </Row>
                {idx < fields.length - 1 ? (
                  <Row>
                    <Col span="24">
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) => {
                          return (
                            prevValues[conjunctionFieldName] !==
                            curValues[conjunctionFieldName]
                          );
                        }}
                      >
                        {() => {
                          const formValues = props.form.getFieldsValue();
                          const conjunction = formValues[conjunctionFieldName];
                          return (
                            <div
                              style={{ position: 'relative', width: '100%' }}
                            >
                              <div
                                style={{
                                  border: '1px dashed #E9E9E9',
                                  position: 'absolute',
                                  width: '100%',
                                  top: '50%',
                                }}
                              />
                              <Button
                                onClick={() => {
                                  formValues[conjunctionFieldName] =
                                    conjunction === 'AND' ? 'OR' : 'AND';

                                  props.form.setFieldsValue(formValues);
                                }}
                                style={{
                                  color: '#FFF',
                                  backgroundColor:
                                    conjunction === 'AND'
                                      ? vars.btnOrOpration
                                      : vars.btnAndOpration,
                                  opacity: 1,
                                }}
                              >
                                {conjunction}
                              </Button>
                            </div>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </Row>
                ) : null}
              </>
            ))}

            <Row>
              <Col span="24">
                <Form.Item
                  shouldUpdate={(prevValues, curValues) => {
                    return (
                      prevValues[conjunctionFieldName] !==
                      curValues[conjunctionFieldName]
                    );
                  }}
                >
                  {() => {
                    const formValues = props.form.getFieldsValue();
                    const conjunction = formValues[conjunctionFieldName];

                    return (
                      <Form.Item>
                        <div
                          style={{
                            border: '1px dashed #E9E9E9',
                            position: 'absolute',
                            width: '100%',
                            top: '50%',
                          }}
                        />
                        <Button
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                          style={{
                            border: 'solid 1px',
                            borderColor:
                              conjunction === 'AND'
                                ? vars.btnOrOpration
                                : vars.btnAndOpration,
                            color:
                              conjunction === 'AND'
                                ? vars.btnOrOpration
                                : vars.btnAndOpration,
                            backgroundColor: '#FFFFFF',
                            opacity: 1,
                          }}
                        >
                          Add Mapping
                        </Button>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    </>
  );
};

export default function TrOccurrenceBusinessEvent(props) {
  const [form] = Form.useForm();
  const { journey, syncCache } = useContext(AppContext);
  const [showUserAttributes, setShowUserAttributes] = useState(false);
  const [showEventsAttributes, setShowEventsAttributes] = useState(false);
  const [showEventFilter, setShowEventFilter] = useState(null);
  const [mappingConjunction, setMappingConjunction] = useState('AND');
  const [businessEventTerms, setBusinessEventTerms] = useState({
    event: null,
    predicates: [],
    type: 'BEHAVIOUR',
    conjunction: 'AND',
  });
  const [userEventTerms, setUserEventTerms] = useState({
    event: null,
    predicates: [],
    type: 'BEHAVIOUR',
    conjunction: 'AND',
  });

  const {
    renderAggregationSelector,
    getAggregationFormProps,
    handleUpdateAggregationFields,
    ...properties
  } = useTrOccurrenceBusinessEventAggregation(props, userEventTerms);

  useEffect(() => {
    const currentStep = journey.steps.find(
      (step) => step.id + '' === props.node.id + ''
    );

    let userAttrs =
      currentStep?.mappingUserTerm?.predicates?.map((predicate) => ({
        negate: !!predicate.negate,
        operator: predicate.operator,
        businessAttr: predicate.businessEventAttribute,
        sourceAttr: predicate.attribute,
      })) || [];

    let eventAttrs =
      currentStep?.mappingEventTerm?.predicates?.map((predicate) => ({
        negate: !!predicate.negate,
        operator: predicate.operator,
        businessAttr: predicate.businessEventAttribute,
        sourceAttr: predicate.attribute,
      })) || [];

    let userEvent = currentStep?.mappingEventTerm?.event;

    let userAttrsConjunction =
      currentStep?.mappingEventTerm?.mappingUserTerm?.conjunction || 'AND';

    let eventAttrsConjunction =
      currentStep?.mappingEventTerm?.conjunction || 'AND';

    const values = {
      businessEvent: currentStep.businessEvent,
      segment: currentStep.segment || 0,
      userEvent,
      userAttrs,
      eventAttrs,
      eventAttrsConjunction,
      userAttrsConjunction,
    };
    setShowUserAttributes(!!userAttrs.length);
    setShowEventsAttributes(!!userEvent);
    setMappingConjunction(currentStep?.mappingConjunction || 'AND');

    setTimeout(() => {
      if (currentStep.mappingEventTerm?.eventTerm) {
        handleUpdateAggregationFields(currentStep?.mappingEventTerm?.eventTerm);
      }
      //workaround for some unpredicted behaviors of ant form
      form.setFieldsValue(values);
    }, 500);

    setBusinessEventTerms({
      event: currentStep?.businessEvent,
      predicates: currentStep?.businessEventTerm?.predicates || [],
      conjunction: currentStep?.businessEventTerm?.conjunction || 'AND',
      type: 'BEHAVIOUR',
    });
    setUserEventTerms({
      event: userEvent,
      predicates: currentStep?.mappingEventTerm?.eventTerm?.predicates || [],
      conjunction:
        currentStep?.mappingEventTerm?.eventTerm?.conjunction || 'AND',
      type: 'BEHAVIOUR',
    });
  }, [journey.steps]);

  const onFinish = (values) => {
    let mappingEventTerm = {
      conjunction: values.eventAttrsConjunction || 'AND',
      event: null,
      eventTerm: {
        conjunction: 'AND',
        predicates: [],
        ...properties,
      },
      predicates: [],
    };

    let mappingUserTerm = {
      conjunction: values.userAttrsConjunction || 'AND',
      predicates: [],
    };

    if (showUserAttributes) {
      mappingUserTerm.predicates = values.userAttrs.map((item) => ({
        negate: !!item.negate,
        operator: item.operator,
        attribute: item.sourceAttr,
        businessEventAttribute: item.businessAttr,
      }));
    } else {
      mappingUserTerm = null;
    }

    if (showEventsAttributes) {
      mappingEventTerm.event = values.userEvent;
      mappingEventTerm.eventTerm = userEventTerms?.predicates?.length
        ? {
            ...mappingEventTerm.eventTerm,
            conjunction: userEventTerms.conjunction,
            predicates: userEventTerms.predicates,
          }
        : mappingEventTerm.eventTerm;

      mappingEventTerm.predicates = values.eventAttrs.map((item) => ({
        negate: !!item.negate,
        operator: item.operator,
        attribute: item.sourceAttr,
        businessEventAttribute: item.businessAttr,
      }));
    } else {
      mappingEventTerm = null;
    }

    let businessEventTerm = {
      conjunction: 'AND',
      predicates: [],
    };

    if (businessEventTerms?.predicates?.length) {
      businessEventTerm = {
        conjunction: businessEventTerms?.conjunction || 'AND',
        predicates: businessEventTerms?.predicates || [],
      };
    } else {
      businessEventTerm = null;
    }

    const updates = {
      businessEvent: values.businessEvent,
      segment: values.segment || null,
      businessEventTerm,
      mappingConjunction,
      mappingEventTerm,
      mappingUserTerm,
    };

    const currentStep = journey.steps.find(
      (step) => step.id + '' === props.node.id + ''
    );

    const currentStepUpdated = Object.assign({}, currentStep, updates);

    unset(currentStepUpdated, 'ui.error');
    syncCache('update-step', currentStepUpdated, false);
    setTimeout(() => props.closeModal(false, true), 100);
  };

  const userEventOptions = useMemo(() => {
    const list = props?.data?.events;
    const options = [];
    options.push({
      groupLabel: 'SYSTEM',
      list: list.filter((e) => e.system),
    });
    options.push({
      groupLabel: 'CUSTOM',
      list: list.filter((e) => !e.system),
    });
    return options;
  }, [props?.data?.events]);

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={(errorInfo) => {
          console.log('Failed:', errorInfo);
        }}
        name="relay-form"
        labelWrap={true}
        className={`modal_wrap ${props.readOnly ? ' read-only' : ''}`}
        initialValues={{
          userAttrsConjunction: 'AND',
          eventAttrsConjunction: 'AND',
          eventAttrs: [],
          userAttrs: [],
        }}
      >
        <div className={'modal-body'}>
          <Row>
            <Col span={18}>
              <Form.Item
                label="BUSINESS EVENT"
                name="businessEvent"
                rules={[
                  { required: true, message: 'business event is required' },
                ]}
              >
                <Select
                  onChange={(eventId) => {
                    const formValues = form.getFieldsValue();
                    formValues.eventAttrs = [
                      {
                        businessAttr: null,
                        operator: null,
                        sourceAttr: null,
                        negate: false,
                      },
                    ];
                    formValues.userAttrs = [
                      {
                        businessAttr: null,
                        operator: null,
                        sourceAttr: null,
                        negate: false,
                      },
                    ];
                    formValues.eventAttrsConjunction = 'AND';
                    formValues.userAttrsConjunction = 'AND';
                    form.setFieldsValue(formValues);

                    setBusinessEventTerms({
                      event: eventId,
                      predicates: [],
                      type: 'BEHAVIOUR',
                      conjunction: 'AND',
                    });
                  }}
                  placeholder="Select Business Event"
                  showSearch
                  filterOption={selectFilterOption}
                >
                  {props?.data?.businessEvents.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                shouldUpdate={(prevValues, curValues) => {
                  return prevValues?.businessEvent !== curValues?.businessEvent;
                }}
              >
                {() => {
                  return (
                    <Badge
                      count={businessEventTerms?.predicates?.length || 0}
                      showZero={false}
                    >
                      <div
                        onClick={() => {
                          if (businessEventTerms?.event) {
                            setShowEventFilter('BUSINESS');
                          }
                        }}
                        className="fl-filter fl-dark"
                        title={
                          businessEventTerms?.event
                            ? 'please select an event first'
                            : 'filter selected event by its attribute'
                        }
                        style={{
                          fontSize: 28,
                          cursor: businessEventTerms?.event
                            ? 'pointer'
                            : 'not-allowed',
                        }}
                      ></div>
                    </Badge>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={18}>
              <Form.Item
                label="SEGMENT"
                name="segment"
                rules={[{ required: true, message: 'segment is required' }]}
              >
                <Select
                  defaultValue={0}
                  placeholder="Select a Segment"
                  showSearch
                  filterOption={selectFilterOption}
                >
                  <OptGroup label="ALL USERS">
                    <Option value={0}>All Users</Option>
                  </OptGroup>
                  <OptGroup label="LIVE SEGMENT">
                    {props?.data?.segments
                      ?.filter(
                        (segment) =>
                          // segment.type === 'DYNAMIC' &&
                          !segment.test && segment.status === 'ENABLED'
                      )
                      ?.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                  </OptGroup>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label="MAPPING OF BUSINESS EVENT TO SEGMENT"
                name="map2Users"
                valuePropName="map2Users"
                // rules={[
                //   {
                //     required: !(showUserAttributes || showEventsAttributes),
                //     message: 'Mapping is required',
                //   },
                // ]}
              >
                <Switch
                  checked={showUserAttributes}
                  onChange={(val) => {
                    setShowUserAttributes(val);
                  }}
                />{' '}
                Map business event attribute to user profile attribute
                <Tooltip
                  placement="top"
                  title={
                    'Example: If you want to ensure that whenever a new "Comedy" series is added, then the relay should get triggered for only those users whose "Interest" is "Comedy" then you need to compare the value of the attribute "Genre" of business event "New Series Added" with the value of the user profile attribute "Interest"'
                  }
                >
                  <InfoCircleOutlined
                    style={{
                      fontSize: '14px',
                      color: '#716D6D',
                      marginLeft: '8px',
                    }}
                  />
                </Tooltip>
              </Form.Item>
            </Col>
          </Row>
          {showUserAttributes ? (
            <Row>
              <Col span="24">
                <div className="relay-mapper-area">
                  <Form.Item
                    shouldUpdate={(prevValues, curValues) => {
                      return (
                        prevValues?.businessEvent !== curValues?.businessEvent
                      );
                    }}
                  >
                    {() => {
                      const businessEventId =
                        form.getFieldValue('businessEvent');
                      const selectedBusinessEvent =
                        props?.data?.businessEvents.find(
                          (e) => e.id === businessEventId
                        );
                      return (
                        <EventMapper
                          sourceAttributes={props?.data?.attributes}
                          sourceLabel="User Profile Attribute"
                          businessAttributes={selectedBusinessEvent?.attributes}
                          listName="userAttrs"
                          form={form}
                        />
                      );
                    }}
                  </Form.Item>
                </div>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col span={20}>
              <Form.Item
                valuePropName="map2Events"
                label=" "
                validateStatus="error"
                // help="You must atleast select one type on mapping"
                rules={[
                  {
                    required: true,
                    message: 'Please select one type of mapping',
                  },
                ]}
              >
                <Switch
                  checked={showEventsAttributes}
                  onChange={(val) => {
                    setShowEventsAttributes(val);
                  }}
                />{' '}
                Map business event attribute to attribute of event done by user
                <Tooltip
                  placement="top"
                  title={
                    'Example: If you want to ensure that whenever a new "Comedy" series is added, then the relay should get triggered for only those users who last watched a "Comedy" series then you need to compare the value of the attribute "Genre" of business event "New Series Added" with the value of the attribute "Genre Type" of event done by user called "Last Episode Watched"'
                  }
                >
                  <InfoCircleOutlined
                    style={{
                      fontSize: '14px',
                      color: '#716D6D',
                      marginLeft: '8px',
                    }}
                  />
                </Tooltip>
              </Form.Item>
            </Col>
            <Col
              span={4}
              style={{
                display:
                  showEventsAttributes && showUserAttributes ? 'block' : 'none',
              }}
            >
              <div
                style={{
                  height: 'calc(100% + 16px)',
                  position: 'absolute',
                  top: '-16px',
                  borderLeft: '1px dashed #ccc',
                  paddingTop: '16px',
                  right: '45px',
                }}
              ></div>
              <Button
                onClick={() => {
                  setMappingConjunction(
                    mappingConjunction === 'AND' ? 'OR' : 'AND'
                  );
                }}
                style={{
                  color: '#FFF',
                  float: 'right',
                  marginRight: '16px',
                  zIndex: 1,
                  backgroundColor:
                    mappingConjunction === 'AND'
                      ? vars.btnOrOpration
                      : vars.btnAndOpration,
                  opacity: 1,
                }}
              >
                {mappingConjunction}
              </Button>
            </Col>
          </Row>
          {showEventsAttributes ? (
            <Row>
              <Col span="24">
                <div className="relay-mapper-area">
                  <Row>
                    <p>
                      Map business event attribute to attribute of event done by
                      user where event done by user is:
                    </p>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <Form.Item
                        label=""
                        name="userEvent"
                        rules={[
                          {
                            required: true,
                            message: 'please select an event',
                          },
                        ]}
                      >
                        <Select
                          onChange={(eventId) => {
                            const formValues = form.getFieldsValue();
                            formValues.eventAttrs = [
                              {
                                businessAttr: null,
                                operator: null,
                                sourceAttr: null,
                                negate: false,
                              },
                            ];
                            formValues.eventAttrsConjunction = 'AND';
                            form.setFieldsValue(formValues);
                            setUserEventTerms({
                              event: eventId,
                              predicates: [],
                              type: 'BEHAVIOUR',
                              conjunction: 'AND',
                            });
                          }}
                          placeholder="Select An Event"
                          showSearch
                          filterOption={selectFilterOption}
                        >
                          {userEventOptions?.map((group) => (
                            <OptGroup
                              key={group.groupLabel}
                              label={group.groupLabel}
                            >
                              {group.list.map((e) => (
                                <Option key={e.id} value={e.id}>
                                  {e.title}
                                </Option>
                              ))}
                            </OptGroup>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) => {
                          return !prevValues?.userEvent && curValues?.userEvent;
                        }}
                      >
                        {() => {
                          return (
                            <Badge
                              count={userEventTerms?.predicates?.length || 0}
                              showZero={false}
                            >
                              <div
                                onClick={() => {
                                  if (userEventTerms?.event) {
                                    setShowEventFilter('USER');
                                  }
                                }}
                                className="fl-filter fl-dark"
                                title={
                                  userEventTerms?.event
                                    ? 'please select an event first'
                                    : 'filter selected event by its attribute'
                                }
                                style={{
                                  fontSize: 28,
                                  cursor: userEventTerms?.event
                                    ? 'pointer'
                                    : 'not-allowed',
                                }}
                              ></div>
                            </Badge>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item {...getAggregationFormProps()}>
                        {renderAggregationSelector()}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    shouldUpdate={(prev, cur) => {
                      return prev?.userEvent !== cur?.userEvent;
                    }}
                  >
                    {() => {
                      const eventId = form.getFieldValue('userEvent');
                      const selectedUserEvent = props?.data?.events.find(
                        (e) => e.id === eventId
                      );
                      return (
                        <Row>
                          <Alert
                            message={`For every business event, our system would consider the "${selectedUserEvent?.name}" events for a maximum of last 90 days to find out the applicable users.`}
                            type="info"
                            showIcon
                            style={{ marginBottom: '16px' }}
                          />
                        </Row>
                      );
                    }}
                  </Form.Item>
                  <Row>
                    <p>
                      Mapping between business event attribute and user event
                      attribute is as follows:
                    </p>
                  </Row>
                  <Row gutter={16}>
                    <Col span={6}>
                      <p className="relay-label">User Event</p>
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) => {
                          return prevValues?.userEvent !== curValues?.userEvent;
                        }}
                      >
                        {() => {
                          const eventId = form.getFieldValue('userEvent');
                          const selectedUserEvent = props?.data?.events.find(
                            (e) => e.id === eventId
                          );

                          return (
                            <Input value={selectedUserEvent?.name} disabled />
                          );
                        }}
                      </Form.Item>
                    </Col>
                    <Col span={7}></Col>
                    <Col span={6}>
                      <p className="relay-label">Business Event</p>
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) => {
                          return (
                            prevValues?.businessEvent !==
                            curValues?.businessEvent
                          );
                        }}
                      >
                        {() => {
                          const businessEventId =
                            form.getFieldValue('businessEvent');
                          const selectedBusinessEvent =
                            props?.data?.businessEvents.find(
                              (e) => e.id === businessEventId
                            );
                          return (
                            <Input
                              value={selectedBusinessEvent?.name}
                              disabled
                            />
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    shouldUpdate={(prevValues, curValues) => {
                      return (
                        prevValues?.businessEvent !==
                          curValues?.businessEvent ||
                        prevValues?.userEvent !== curValues?.userEvent
                      );
                    }}
                  >
                    {() => {
                      const businessEventId =
                        form.getFieldValue('businessEvent');
                      const userEventId = form.getFieldValue('userEvent');
                      const selectedBusinessEvent =
                        props?.data?.businessEvents.find(
                          (e) => e.id === businessEventId
                        );
                      const selecteUserEvent = props?.data?.events.find(
                        (e) => e.id === userEventId
                      );

                      return (
                        <EventMapper
                          sourceAttributes={selecteUserEvent?.attributes}
                          sourceLabel="User Event Attribute"
                          businessAttributes={selectedBusinessEvent?.attributes}
                          listName="eventAttrs"
                          form={form}
                        />
                      );
                    }}
                  </Form.Item>
                </div>
              </Col>
            </Row>
          ) : null}
        </div>

        <div className={'modal-footer'}>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              shape="round"
              disabled={props.readOnly}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>

      <ModalEventFilterSelector
        currentPredicate={
          showEventFilter === 'BUSINESS' ? businessEventTerms : userEventTerms
        }
        isModalVisible={showEventFilter}
        events={
          showEventFilter === 'BUSINESS'
            ? props?.data?.businessEvents
            : props?.data?.events
        }
        onCancelHandler={() => {
          setShowEventFilter(null);
        }}
        onOkHandler={(changedPredicate) => {
          showEventFilter === 'BUSINESS'
            ? setBusinessEventTerms(changedPredicate)
            : setUserEventTerms(changedPredicate);
          setShowEventFilter(null);
        }}
        handleChangePredicate={() => {}}
        fetchRecommendEventAttributes={() => {}}
        recommendEventAttributes={null}
      />
    </>
  );
}
